import React, { memo, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { IProject } from '../../../../services/project.service';
import { setCompareStep } from '../../../../store/modules/Common';
import { ECompareStep } from '../../../../types/ICommon';
import Icon from '../../../ui/Icon';
import Select from '../../../ui/Inputs/Select';
import SelectCustom from '../Select';
import { IOption } from '../Select/List';
import './style.scss';
import { IVersion } from '../../../../types/IProject';

export const modeOptions = [
  {
    label: 'Slider compare',
    value: 'slider'
  },
  {
    label: 'Side by side compare',
    value: 'sideBySide'
  }
]

export interface IControl {
  get?: IOption;
  set: React.Dispatch<React.SetStateAction<IOption | undefined>>;
}
export interface IComparisonModelProps {
  project: IProject;
  leftVersion: IControl;
  rightVersion: IControl;
  mode: {
    get?: string;
    set: React.Dispatch<React.SetStateAction<string | undefined>>;
  }
}

const ComparisonModal = ({ leftVersion, rightVersion, mode, project }: IComparisonModelProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if(leftVersion.get && rightVersion.get && mode.get) {
      dispatch(setCompareStep(ECompareStep.openCompareTool));
    }
  }, [dispatch, leftVersion, rightVersion, mode])

  return (
    <div className='comparison-model-wrapper'>
      <div className='comparison-model__title'>
        <Icon name='ic-slider' color='blue' size={3} />
        <p>comparing mission versions</p>
      </div>

      <div className="comparison-model__body">
        <div className='comparison-model__project-name'>
          {project.name}
        </div>

        <div className='comparison-model__choose-version'>
          <p className='comparison-model__choose-version-title'>
            Select versions to compare
          </p>
          <div className='comparison-model__choose-version-wrapper'>
            <SelectCustom
              versions={project?.versions}
              title='Left side'
              value={leftVersion.get}
              setValue={leftVersion.set}
            />
            <div className='comparison-model__choose-version-icon'>
              <Icon name='switch' color='grey' size={3} />
            </div>
            <SelectCustom
              versions={project?.versions}
              title='Right side'
              value={rightVersion.get}
              setValue={rightVersion.set}
            />
          </div>
        </div>

        <div className='comparison-model__choose-version'>
          <p className='comparison-model__choose-version-title'>
            Select compare mode
          </p>
          <div className='comparison-model__choose-mode-wrapper'>
            <Select
              menuPlacement='top'
              options={modeOptions}
              value={mode.get}
              onChange={(v) => mode.set(v)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(ComparisonModal);
