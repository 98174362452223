// @ts-nocheck

import { Mesh, SphereGeometry, MeshBasicMaterial, LinearFilter } from 'three';

export default class Environmet {
  private material: MeshBasicMaterial;

  public mesh: Mesh;

  constructor() {
    this.init();
  }

  private init() {
    this.material = new MeshBasicMaterial({ color: '#888888', side: 1 });
    this.mesh = new Mesh(new SphereGeometry(3000, 16, 16), this.material);
  }

  public setNewMap(map) {
    map.minFilter = LinearFilter;
    this.material.map = map;
    this.material.needsUpdate = true;
  }
}
