import React from 'react';
import './style.scss';
import ModalWrapper from '../../ModalWrapper';

const StationModal = ({ setShowStationModal, stationInfo, satellites }: any) => {
  return (
    <ModalWrapper
      title={stationInfo?.name}
      className="satelliteInfoModal"
      close={() => setShowStationModal(false)}
      small
    >
      <p className="p">info</p>
      <p className="p">upcoming satellite contacts:</p>
      {satellites
        .filter((satellite: any) => satellite.telescope === stationInfo.name)
        .map((satellite: any) => (
          <div key={satellite._id} className="telescope-satellite">
            <div className="telescope-satellite-name">{satellite.name}</div>
            <div className="telescope-satellite-info">
              <b>Contact In: </b>
              <span>45 min (ETA)</span>
            </div>
          </div>
        ))}

      <div className="signal">radio signals</div>
    </ModalWrapper>
  );
};

export default StationModal;
