import { createAsyncThunk } from '@reduxjs/toolkit';
import UserService from '../../../services/user.service';

export const initialization = createAsyncThunk('user/Init', async (_, thunkAPI) => {
  try {
    return await UserService.initialization();
  } catch (error: any) {
    if (error.statusCode === 400) {
      return thunkAPI.rejectWithValue('Incorrect data!');
    }

    return thunkAPI.rejectWithValue(error.message || 'Server Error!');
  }
});

export const login = createAsyncThunk(
  'user/Login',
  async (params: { email: string; password: string }, thunkAPI) => {
    try {
      return await UserService.login(params);
    } catch (error: any) {
      if (error.statusCode === 400) {
        return thunkAPI.rejectWithValue('Incorrect data!');
      }

      return thunkAPI.rejectWithValue(error.message || 'Server Error!');
    }
  },
);
