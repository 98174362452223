import React, { FC, useEffect, useState } from 'react';
import { Outlet } from 'react-router';
import { Navigate } from 'react-router-dom';
import { Loader } from '../../components/Loading';
import { useAppDispatch, useAuth } from '../../hooks/redux';
import { initialization } from '../../store/modules/User';

const SingOutGuard: FC = () => {
  const [isLoading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const { profile } = useAuth();

  useEffect(() => {
    dispatch(initialization())
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [dispatch]);

  if (isLoading) return <Loader />;

  if (profile) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export default SingOutGuard;
