import React, { useEffect, useState } from 'react';
import * as turf from '@turf/turf';
import cn from 'classnames';
import GoogleMap from 'google-map-react';
import { useAppDispatch, useCommon, useProject } from '../../../../hooks/redux';
import { setDrawingMode, updateActiveModal } from '../../../../store/modules/Common';
import { changeProjectShape, deleteProjectShape, setBoundary, setCurrent } from '../../../../store/modules/Project';
import config from '../../../../../config';
// import ProjectMarker from './ProjectMarker';
import MarkerIcon from "../../../../../assets/images/pin-icon.svg";
import './style.scss';

let drawingManager: any;
let Map: any;

const DrawingManagerOptions = {
  markerOptions: {
    editable: false,
  },
  circleOptions: {
    editable: true,
    fillColor: '#5b5858',
    fillOpacity: 0.2,
    strokeColor: '#000',
    strokeWeight: 2,
    clickable: true,
  },
  polygonOptions: {
    editable: true,
    fillColor: '#5b5858',
    fillOpacity: 0.2,
    strokeColor: '#000',
    strokeWeight: 2,
    clickable: true,
  },
  polylineOptions: {
    editable: true,
    fillColor: '#5b5858',
    fillOpacity: 0.2,
    strokeColor: '#000',
    strokeWeight: 2,
    clickable: true,
  },
  drawingControl: false,
};

const genID = (prefix = 'i'): string => `${prefix}${(+new Date()).toString(16)}`;

const getShapeCenter = (overlay: any, maps: any) => {
  const features = turf.points(
    overlay
      .getPath()
      .getArray()
      .map((point: any) => [point.lng(), point.lat()]),
  );

  const { geometry } = turf.centroid(features);
  const center = {
    lat: geometry?.coordinates[1] || 0,
    lng: geometry?.coordinates[0] || 1,
  };

  const Geocoder = new maps.Geocoder();

  return new Promise((resolve) => {
    Geocoder.geocode(
      {
        location: center,
      },
      (results: any) => {
        const addresses = results?.[(results?.length || 2) - 2];
        const address = addresses?.formatted_address?.split(',')[0];
        resolve({ center, address });
      },
    );
  });
};

const MapView = ({style}: any) => {
  const [markers, setMarkers] = useState([])
  const { viewType, activeModal, drawingMode } = useCommon();
  const { projectsList, current, boundary } = useProject();
  const dispatch = useAppDispatch();
  const isVisible = viewType === 'map';

  useEffect(() => {
    dispatch(setDrawingMode(null));
    dispatch(deleteProjectShape());
  }, [activeModal, dispatch]);

  useEffect(() => {
    drawingManager?.setOptions({
      drawingMode,
    });
  }, [drawingMode]);

  useEffect(() => {
    if (!current) return;
    const center: any = projectsList.find((item) => item._id === current.project)?.center
    Map?.panTo({
      lat: center.lat,
      lng: center.lng,
    });
  }, [current, projectsList]);

  const addMarkers = () => {
    const infowindow = new (window as any).google.maps.InfoWindow();
    markers.forEach((item: any) => {
      item.setMap(null);
    });
    const temp: any = [];
    for (let index = 0; index < projectsList.length; index++) {
      const project = projectsList[index];

      const beachMarker = new (window as any).google.maps.Marker({
        position: { lat: project.center?.lat, lng: project.center?.lng },
        Map,
        icon: MarkerIcon,
      });
      beachMarker.setMap(Map);
      temp.push(beachMarker);
      // eslint-disable-next-line no-loop-func
      (window as any).google.maps.event.addListener(beachMarker, 'mouseover', function() {
        infowindow.setContent(project.name);
        // @ts-ignore
        infowindow.open(Map, this);
      });

      (window as any).google.maps.event.addListener(beachMarker, 'mouseout', function() {
        infowindow.close();
      });

      (window as any).google.maps.event.addListener(beachMarker, 'click', () => {
        dispatch(setCurrent(project.versions?.[0]._id));
        dispatch(updateActiveModal('project'));
      });
      
    }
    setMarkers(temp);
  }

  useEffect(() => {

    if(!Map) return;
    addMarkers();
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsList]);

  const overlayComplete = ({ type, overlay }: any) => {
    const {maps} = (window as any).google;
    overlay.id = genID();
    overlay.type = type;
    const overlayPoints = overlay
      .getPath()
      .getArray()
      .map((point: any) => ({
        lat: point.lat(),
        lng: point.lng(),
      }));

    if (overlayPoints.length < 3) {
      overlay.setMap(null);
      return;
    }
    overlay.getDimensions = () => {
      const area = maps.geometry.spherical.computeArea(overlay.getPath());
      const acres = area * 0.00024711;

      let perimeter = 0;
      const points = overlay
        .getPath()
        .getArray()
        .map((point: any) => ({
          lat: point.lat(),
          lng: point.lng(),
        }));

      for (let i = 0; i < points.length; i++) {
        const point1 = points[i];
        const nextPoint = points[i + 1];
        const point2 = nextPoint || points[0];

        const distance = maps.geometry.spherical.computeDistanceBetween(point1, point2);
        perimeter += distance;
      }

      return { area, acres, perimeter };
    };

    overlay.getCenter = () => {
      return getShapeCenter(overlay, maps);
    };

    overlay.getPath().addListener('insert_at', () => {
      dispatch(changeProjectShape(overlay));
    });
    overlay.getPath().addListener('set_at', () => {
      dispatch(changeProjectShape(overlay));
    });
    dispatch(setDrawingMode(null));
    dispatch(changeProjectShape(overlay));


    const bounds = new maps.LatLngBounds();
    const paths = overlay.getPaths();
    let path;
    for (let i = 0; i < paths.getLength(); i++) {
      path = paths.getAt(i);
      for (let ii = 0; ii < path.getLength(); ii++) {
         bounds.extend(path.getAt(ii));
      }
    }

    Map.fitBounds(bounds);

    (async() => {
      const {center}: any = await getShapeCenter(overlay, maps);
      Map?.panTo(center);
    })();

  }

  useEffect(() => {
    if(!boundary) return;

     const polygon = new (window as any).google.maps.Polygon({
      paths: boundary,
      ...DrawingManagerOptions.polygonOptions
    });


    // const bounds = new (window as any).google.maps.LatLngBounds();
    // boundary?.forEach(({ lat, lng }: any) => bounds.extend({ lat, lng }));
    // Map.fitBounds(bounds)
    // Map.panToBounds(bounds);

    polygon.setMap(Map);
    overlayComplete({ type: 'polygon', overlay: polygon });
    dispatch(setBoundary(null));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[boundary])

  const addOverlayListeners = (maps: any): void => {
    maps.event.addListener(drawingManager, 'overlaycomplete', overlayComplete);
  };

  const handleGoogleAPiLoaded = ({ map, maps }: any) => {
    drawingManager = new maps.drawing.DrawingManager({
      ...DrawingManagerOptions,
      map,
    });
    addOverlayListeners(maps);
    Map = map;
    // this.RoadsAPi = new RoadsApiService(map);
    // google.maps.event.addDomListener(this.Map, 'zoom_changed', this.handleZoomChanged);
    // google.maps.event.addListener(map,'dragend', this.handleDragend);
    Map?.panTo({
      lat: 40.91369740284686,
      lng: -100.5937326463805,
    });
    addMarkers();
  };

  return (
    <div className={cn('map', { hidden: !isVisible })}>
      <div className="gMapWrapper">
        <GoogleMap
          yesIWantToUseGoogleMapApiInternals
          bootstrapURLKeys={{
            ...{
              libraries: ['drawing', 'places', 'geometry'],
              language: 'en',
              region: 'US',
            },
            key: config.googleApiKey,
          }}
          defaultCenter={{
            lat: 40.512277,
            lng: -100.199089,
          }}
          defaultZoom={5}
          options={{
            ...{
              disableDefaultUI: true,
              panControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
              zoomControl: false,
              mapTypeId: style,
              tilt: 0,
            },
            mapTypeId: style,
          }}
          onGoogleApiLoaded={handleGoogleAPiLoaded}
        >

          {/* {projectsList?.map((project) => (
            <ProjectMarker
              key={project._id}
              lat={project.center?.lat}
              lng={project.center?.lng}
              title={project.name}
              onSelect={() => {
                dispatch(setCurrent(project.versions?.[0]._id));
                dispatch(updateActiveModal('project'));
              }}
            />
          ))} */}
        </GoogleMap>
      </div>
    </div>
  );
};

export default MapView;
