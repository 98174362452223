import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { IProject } from '../../../services/project.service';
import { createProject, initProject, addVersion, remove, setCurrent } from './actions';
import { IVersion } from '../../../types/IProject';
import { EVehicleSuperCategory, DefaultActiveVehicles } from '../../../types/IVehicle';

const activeShapes = {
  ...DefaultActiveVehicles,
  undamaged: true,
  damaged: true,
  destroyed: true,
  others: true,
  'No After Imagery to Process': true,
  'No After Footprint Found': true,
};
export interface IProjectStore {
  shape: any;
  boundary: any;
  current: any;
  currentVer: null | IVersion;
  activeShapes: any;
  projectsList: IProject[];
  isLoading: boolean;
  error: string;
}

const initialState: IProjectStore = {
  shape: null,
  boundary: null,
  current: null,
  currentVer: null,
  activeShapes,
  projectsList: [],
  isLoading: false,
  error: '',
};

export const projectModule = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setBoundary: (state, action: PayloadAction<any>) => {
      state.boundary = action.payload;
    },
    setCurrentVer: (state, action: PayloadAction<any>) => {
      state.currentVer = action.payload;
    },
    changeProjectShape: (state, action: PayloadAction<any>) => {
      if (state.shape === action.payload) {
        state.shape = null;
        state.shape = action.payload;
        return;
      }
      state.shape?.setMap(null);
      state.shape = null;
      state.shape = action.payload;
    },
    deleteProjectShape: (state) => {
      if (!state.shape) return;
      state.shape?.setMap(null);
      state.shape = null;
    },
    toggleShapesBulk: (state, action: PayloadAction<any>) => {
      const stateCandidate = { ...state.activeShapes };

      action.payload.items.forEach((item: any) => {
        stateCandidate[item] = !!action.payload?.active;
      });
      state.activeShapes = stateCandidate;
    },
    toggleShapes: (state, action: PayloadAction<string>) => {
      state.activeShapes = {
        ...state.activeShapes,
        [action.payload]: !state.activeShapes[action.payload],
      };
    },
    resetShapes: (state) => {
      state.activeShapes = activeShapes;
    },
    updateProject: (state, action: PayloadAction<IProject>) => {
      if (state.projectsList.find((proj) => proj._id === action.payload._id)) {
        state.projectsList = state.projectsList.map((proj) => {
          if (proj._id === action.payload._id) {
            return action.payload;
          }
          return proj;
        });
      } else {
        state.projectsList = [action.payload, ...state.projectsList];
      }
    },
    removeProject: (state, action: PayloadAction<IProject>) => {
      if (action.payload._id === state.current?.project) {
        state.current = null;
      }
      state.projectsList = state.projectsList.filter((item) => item._id !== action.payload._id);
    },
    removeCurrent: (state) => {
      state.current = null;
    },
    updateStatus: (state, action: PayloadAction<any>) => {
      const { _id, project } = action.payload;
      state.projectsList = state.projectsList.map((proj) => {
        if (project === proj._id) {
          return {
            ...proj,
            versions: proj.versions.map((version: any) => {
              if (version._id === _id) {
                return action.payload;
              }
              return version;
            }),
          };
        }
        return proj;
      });
    },
  },
  extraReducers: {
    // init
    [initProject.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.projectsList = action.payload;
      state.error = '';
      state.isLoading = false;
    },
    [initProject.pending.type]: (state) => {
      state.isLoading = true;
    },
    [initProject.rejected.type]: (state) => {
      state.isLoading = false;
    },
    // create
    [createProject.fulfilled.type]: (state) => {
      state.error = '';
      state.isLoading = false;
    },
    [createProject.pending.type]: (state) => {
      state.isLoading = true;
    },
    [createProject.rejected.type]: (state) => {
      state.isLoading = false;
    },
    // addVersion
    [addVersion.fulfilled.type]: (state) => {
      state.error = '';
      state.isLoading = false;
    },
    [addVersion.pending.type]: (state) => {
      state.isLoading = true;
    },
    [addVersion.rejected.type]: (state) => {
      state.isLoading = false;
    },
    // remove
    [remove.fulfilled.type]: (state) => {
      state.error = '';
      state.isLoading = false;
    },
    [remove.pending.type]: (state) => {
      state.isLoading = true;
    },
    [remove.rejected.type]: (state) => {
      state.isLoading = false;
    },
    //  getML
    [setCurrent.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
      state.error = '';
      state.isLoading = false;
    },
    [setCurrent.pending.type]: (state) => {
      state.isLoading = true;
    },
    [setCurrent.rejected.type]: (state) => {
      state.isLoading = false;
    },
  },
});

const {
  setBoundary,
  changeProjectShape,
  deleteProjectShape,
  updateProject,
  removeProject,
  removeCurrent,
  toggleShapes,
  toggleShapesBulk,
  resetShapes,
  updateStatus,
  setCurrentVer,
} = projectModule.actions;

export {
  setBoundary,
  changeProjectShape,
  deleteProjectShape,
  updateProject,
  createProject,
  initProject,
  addVersion,
  remove,
  removeProject,
  removeCurrent,
  toggleShapes,
  toggleShapesBulk,
  resetShapes,
  updateStatus,
  setCurrent,
  setCurrentVer,
};

export default projectModule.reducer;
