import React, { Suspense, lazy, useEffect } from 'react';
import { Route, Routes } from 'react-router';
import { useLocation } from 'react-router-dom';
import AuthGuard from './Guards/AuthGuard';
import SingOutGuard from './Guards/SingOutGuard';
import { Loader } from '../components/Loading';
import MainLayout from '../components/Layout';
import { login } from './constants';
import ProjectLayout from '../components/ProjectLayout';

const titles = {
  '/': 'Home',
  [login]: 'Authorization',
};

const Home = lazy(() => import('../views/Home'));
const Overlay = lazy(() => import('../views/Overlay'));
const SignUp = lazy(() => import('../views/Login'));
const ErrorPage = lazy(() => import('../views/ErrorPage'));

const Router = () => {
  const location = useLocation();
  useEffect(() => {
    document.title = (titles as any)[location.pathname]
      ? `Infralytiks - ${(titles as any)[location.pathname]}`
      : 'Infralytiks';
  }, [location]);

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="*" element={<ErrorPage />} />

        <Route element={<AuthGuard />}>
          <Route element={<MainLayout />}>
            <Route path="/" element={<Home />} />
          </Route>
          <Route element={<ProjectLayout />}>
            <Route path="/data/:versionId" element={<Overlay />} />
          </Route>
        </Route>

        <Route element={<SingOutGuard />}>
          <Route path={login} element={<SignUp />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default Router;
