import flattenObject from "../utils/flattenObject";

export enum EVehicleSuperCategory {
  Aircraft = 'Aircraft',
  Ship = 'Ship',
  Ground = 'Ground Vehicle',
}

export enum EAirCraftCategory {
  Fixed_Wing = 'Fixed Wing',
  Rotary_Wing = 'Rotary Wing',
}

export enum EShipCategory {
  Combatant_Ship = 'Combatant Ship',
  Combatant_Craft = 'Combatant Craft',
  Non_Military_Ship = 'Non Military Ship',
}

export enum EGroundCategory {
  Civilian_Vehicles = 'Civilian Vehicles',
  Military_Vehicles = 'Military Vehicles',
  Railcar = 'Railcar',
}

export enum EAirCraftSubCategory {
  Fighter = 'Fighter',
  Bomber = 'Bomber',
  Medium_Lift = 'Medium-Lift Transport',
  Heavy_Lift = 'Heavy-Lift Transport',
  Civilian = 'Civilian',
  Rotary_Wing = 'Rotary Wing',
  null = '<null>',
}

export enum EShipSubCategory {
  Destroyer = 'Destroyer',
  Frigates_Corvettes = 'Frigates Corvettes',
  Aircraft_Carrier = 'Aircraft Carrier',
  Patrol_Ships = 'Patrol Ships',
  Patrol_Ships_Medium = 'Patrol Ships',
  Amphibious_Warfare_Craft = 'Amphibious Warfare Craft',
  Bulk_Cargo_Ships = 'Bulk Cargo Ships',
  Container_Ships = 'Container Ships',
  POL_Tanker = 'POL Tanker',
  Other_Civilian = 'Other',
  Other_Military = 'Other Military',
  null = '<null>',
}

export enum EGroundSubCategory {
  Trailer_Or_Artillery = 'Trailer / Artillery',
  Tank_Self_Propelled_Artillery = 'Tank Self Propelled Artillery',
  Truck = 'Truck',
  Military_Truck = 'Military Truck',
  Flat_Railcar = 'Flat Railcar',
  General_Railcar = 'General Railcar',
  null = '<null>',
}

export const vehicleTree = {
  [EVehicleSuperCategory.Aircraft]: {
    [EAirCraftCategory.Fixed_Wing]: {
      [EAirCraftSubCategory.Fighter]: EAirCraftSubCategory.Fighter,
      [EAirCraftSubCategory.Bomber]: EAirCraftSubCategory.Bomber,
      [EAirCraftSubCategory.Medium_Lift]: EAirCraftSubCategory.Medium_Lift,
      [EAirCraftSubCategory.Heavy_Lift]: EAirCraftSubCategory.Heavy_Lift,
      [EAirCraftSubCategory.Civilian]: EAirCraftSubCategory.Civilian,
      [EAirCraftSubCategory.null]: EAirCraftSubCategory.null,
    },
    [EAirCraftCategory.Rotary_Wing]: {
      [EAirCraftSubCategory.Rotary_Wing]: EAirCraftSubCategory.Rotary_Wing,
    },
  },
  [EVehicleSuperCategory.Ship]: {
    [EShipCategory.Combatant_Ship]: {
      [EShipSubCategory.Destroyer]: EShipSubCategory.Destroyer,
      [EShipSubCategory.Frigates_Corvettes]: EShipSubCategory.Frigates_Corvettes,
      [EShipSubCategory.Aircraft_Carrier]: EShipSubCategory.Aircraft_Carrier,
      [EShipSubCategory.Amphibious_Warfare_Craft]: EShipSubCategory.Amphibious_Warfare_Craft,
      [EShipSubCategory.Patrol_Ships]: EShipSubCategory.Patrol_Ships,
      [EShipSubCategory.Other_Military]: EShipSubCategory.Other_Military,
    },
    [EShipCategory.Non_Military_Ship]: {
      [EShipSubCategory.Bulk_Cargo_Ships]: EShipSubCategory.Bulk_Cargo_Ships,
      [EShipSubCategory.Container_Ships]: EShipSubCategory.Container_Ships,
      [EShipSubCategory.POL_Tanker]: EShipSubCategory.POL_Tanker,
      [EShipSubCategory.Other_Civilian]: EShipSubCategory.Other_Civilian,
    },
    [EShipCategory.Combatant_Craft]: {
      [EShipSubCategory.Patrol_Ships]: EShipSubCategory.Patrol_Ships,
      [EShipSubCategory.null]: EShipSubCategory.null,
    }
  },
  [EVehicleSuperCategory.Ground]: {
    [EGroundCategory.Military_Vehicles]: {
      [EGroundSubCategory.Tank_Self_Propelled_Artillery]: EGroundSubCategory.Tank_Self_Propelled_Artillery,
      [EGroundSubCategory.Military_Truck]: EGroundSubCategory.Military_Truck,
      [EGroundSubCategory.Trailer_Or_Artillery]: EGroundSubCategory.Trailer_Or_Artillery,
    },
    [EGroundCategory.Civilian_Vehicles]: {
      [EGroundSubCategory.Truck]: EGroundSubCategory.Truck,
      [EGroundSubCategory.null]: EGroundSubCategory.null,
    },
    [EGroundCategory.Railcar]: {
      [EGroundSubCategory.Flat_Railcar]: EGroundSubCategory.Flat_Railcar,
      [EGroundSubCategory.General_Railcar]: EGroundSubCategory.General_Railcar,
    }
  }
}


export const VehicleCategory = {
  ...EShipCategory,
  ...EAirCraftCategory,
  ...EGroundCategory,
}

export const VehicleSubCategory = {
  ...EShipSubCategory,
  ...EAirCraftSubCategory,
  ...EGroundSubCategory,
}

export type VehicleCategory = EShipCategory | EAirCraftCategory | EGroundCategory;
export type VehicleSubCategory = EShipSubCategory | EAirCraftSubCategory | EGroundSubCategory;
export enum VehicleClassification {
  UNCLASSIFIED = 'UNCLASSIFIED',
  CLASSIFIED = 'CLASSIFIED',
}

export interface IVehicleProperties {
  ogc_fid: string;
  information_year: number;
  information_version: number;
  information_description: string;
  information_contributor: string;
  information_date_created: string;
  information_classification: string;
  information_owner_producer: string;
  information_id: number;
  information_url: string | null;
  information_name: string;
  information_decription: string;

  categories_id: number;
  categories_supercategory: EVehicleSuperCategory;
  categories_category: VehicleCategory;
  categories_subcategory: VehicleSubCategory;
  categories_name: string;

  images_id: number;
  images_width: number;
  images_height: number;
  images_extent: string;
  images_filename: string;
  images_classification: VehicleClassification;
  images_date_captured: string;
  images_platform: string;
  images_image_type: string;
  images_projection: string;
  images_image_mean_gsd: number[];
  images_sun_azimuth_angle: number;
  images_sun_elevation_angle: number;
  images_percentage_cloud_cover: number;

  aoi_id: number;
  aoi_location_id: string;
  aoi_country_code: string;
  aoi_order_id: number;
  aoi_aoi_point: number[];
  aoi_aoi_poly: number[][];

  annotator_id: number;
  annotator_algorithm_type: EAlgorithmType;
  annotator_name: string;
  annotator_version: string;
  annotator_notes: null | string;

  annotations_id: number;
  annotations_image_id: number;
  annotations_aoi_id: number;
  annotations_category_id: number;
  annotations_license_id: number;
  annotations_algorithm_id: number;
  annotations_iscrowd: number;
  annotations_segmentation: string;
  annotations_bbox: number[][];
  annotations_object_center: number[];
  annotations_segmentation_geo: string;
  annotations_bbox_geo: string;
  annotations_object_center_geo: number[];
  annotations_len_geo: number;
  annotations_width_geo: number;
  annotations_area_geo: number;
  annotations_confidence: number;
  annotations_global_id: number;
  annotations_classification: VehicleClassification;
  wkb_geometry: string;
  type_polygon: string | null;
}

export enum EAlgorithmType {
  Human_Annotator = 'human_annotator',
  Algorithmic_Annotator = 'algorithmic_annotator',
}

export const DefaultActiveVehicles = flattenObject({
  obj: vehicleTree,
  defaultValue: true,
});