import React, { FC } from 'react';
import './style.scss';
import ApiSelect, { MenuPlacement, OnChangeValue, StylesConfig } from 'react-select';
import cn from 'classnames';

interface OptionType {
  value: string;
  label: string;
}

interface IProps {
  isDisabled?: boolean;
  value?: string;
  onChange: (v?: string) => void;
  options: OptionType[];
  label?: string;
  error?: string;
  menuPlacement?: MenuPlacement;
}

const Select: FC<IProps> = ({ menuPlacement = 'auto', value, onChange, options = [], label, error, isDisabled }) => {
  const handleChange = (selected: OnChangeValue<OptionType, false>) => {
    onChange(selected?.value);
  };

  return (
    <div className={cn('app-select__wrapper', { error })}>
      {label ? <div className="app-select__label">{label}</div> : null}
      <ApiSelect
        menuPlacement={menuPlacement}
        isSearchable={false}
        className="app-select"
        classNamePrefix="app-select"
        value={options.find((item: OptionType) => item.value === value)}
        options={options}
        isMulti={false}
        onChange={handleChange}
        isDisabled={isDisabled}
      />
      {error && <div className="app-select__error">{error}</div>}
    </div>
  );
};

export default Select;
