import axios from 'axios';
import config from '../../config';
import { ProjectType } from '../types/IProject';

export enum EBuildingStatus {
  DESTROYED = 'destroyed',
  DAMAGED = 'damaged',
  UNDAMAGED = 'undamaged',
  OTHERS = 'others',
}

export interface ITileProperties {
  after_date: string;
  assessment_source: string;
  before_date: string;
  building_id: number;
  building_status_after: EBuildingStatus;
  building_status_before: EBuildingStatus;
  footprint_area: number;
  post_image_id: string;
  pre_image_id: string;
  ogc_fid: string;
  wkb_geometry: string;
  type_polygon: string;
}

const BASE_URL = `${config.tileServer}/v1`;

const TilesService = {
  async getTilesFromArea(geojson_table: string, polygonAsTxt: string): Promise<ITileProperties[]> {
    const response = await axios.get(
      `${BASE_URL}/contain_feature/${geojson_table}?area=${polygonAsTxt}`,
    );

    return response.data;
  },

  async multipleChangeStatus(data: object) {
    const response = await axios.put(`${BASE_URL}/multiple_update_status`, data);

    return response.data;
  },

  async multipleDeletePolygon(data: any) {
    const response = await axios.post(
      `${BASE_URL}/multiple_delete_row/${data.geojson_table}`,
      data,
    );

    return response.data;
  },

  async findBuildingByImageId(table: string, pre_image_id?: string, type?: string) {
    const res = await axios.get(
      `${BASE_URL}/find_with_pre_id/${table}?${pre_image_id ? `pre_image_id=${pre_image_id}` : ''}&${type ? `type=${type}` : ''}`,
    );

    return res.data;
  },

  async insertRow(table: string, data: { columns: (string | undefined)[]; values: any; type?: ProjectType }) {
    const res = await axios.post(`${BASE_URL}/insert_row/${table}`, data);

    return res.data;
  },

  async updateRow(data: { table: string; query: string; ogc_fid: string }) {
    const res = await axios.put(`${BASE_URL}/update_table`, data);

    return res.data;
  },

  async deleteRow(table: string, ogc_fid: string) {
    const res = await axios.delete(
      `${BASE_URL}/delete_row/${table}?ogc_fid=${ogc_fid}`,
    );
    return res.data;
  },
};

export default TilesService;