import 'leaflet.sync';
import './style.scss';
import vectorTileLayer from 'leaflet-vector-tile-layer';

import React, { useEffect, useCallback, memo, useState } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import { useProject } from '../../../hooks/redux';
import axiosInstance from '../../../utils/axios';
import { ProjectType } from '../../../types/IProject';
import { buildingOptions, vehicleOptions } from '../../ProjectDataView/components/MLObjectLayer/options';

L.DomEvent.fakeStop = () => {
  return true;
};

export const getShapeColor = (type) => {
  switch (type) {
    case 'destroyed':
      return '#ff5252';
    case 'damaged':
      return '#e69138';
    case 'undamaged':
      return '#6aa84f';
    case 'others':
      return '#3d85c6';
    case 'No After Footprint Found':
      return '#c603fc';
    case 'No After Imagery to Process':
      return '#343aeb';
    default:
      return '#3d85c6';
  }
};

const token =
  'pk.eyJ1IjoicXVhbmhvYW5nYmh0ZWNoIiwiYSI6ImNrNTYzenlpazBkbDMza3M3cWhjMHd4bmsifQ.4lOUzhqkzYcm8B2zrRjQZA';

const CompareSideBySide = ({
  styleUrl,
  leftMap,
  rightMap,
  setLeftTile,
  setRightTile,
  setLeftPos,
  setRightPos,
  leftLayer,
  rightLayer,
}) => {
  const map = useMap();
  const { activeShapes, currentVer, current, projectsList } = useProject();
  const [zoomedToCenter, setZoomedToCenter] = useState(null);
  const [leftTileLayer, setLeftTileLayer] = useState(null);
  const [rightTileLayer, setRightTileLayer] = useState(null);

  const currentProj = current && projectsList.find((item) => item._id === current.project);

  const handleOnclickLeftVectorTile = useCallback((e) => {
    setLeftTile(e.layer);
    setLeftPos(e.latlng);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnclickRightVectorTile = useCallback((e) => {
    setRightTile(e.layer);
    setRightPos(e.latlng);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOptions = useCallback((type, layerName) => {
    switch(type) {
      case ProjectType.building:
        return buildingOptions({
          layerName,
          currentShape: null,
          activeShapes,
          selectedPolys: [],
          tile: null,
          canInteractive: true,
        });
      case ProjectType.vehicle:
        return vehicleOptions({
          layerName,
          currentShape: null,
          activeShapes,
          selectedPolys: null,
          tile: null,
          canInteractive: true,
        })
      default:
        return buildingOptions({
          layerName,
          currentShape: null,
          activeShapes,
          selectedPolys: null,
          tile: null,
          canInteractive: true,
        });
    }
  }, [activeShapes]);

  useEffect(() => {
    if (!map) return;

    const handleAddLayer = async () => {
      try {
        map.eachLayer((e) => {
          if (e.options.isVectorGrid) {
            map.removeLayer(e);
          }
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        // const mapLeft = L.map('mapLeft', { zoom: 20, center });

        L.tileLayerWithHeader(leftLayer?.url_tif, {
          tms: true,
          maxZoom: 24,
          noRedraw: false,
        }).addTo(leftMap.current);

        const left = vectorTileLayer(leftLayer?.url, getOptions(currentProj.type, leftLayer?.name));
        left.addTo(leftMap.current);
        left.on('click', handleOnclickLeftVectorTile);
        setLeftTileLayer(left);

        L.tileLayerWithHeader(rightLayer?.url_tif, {
          tms: true,
          maxZoom: 24,
          noRedraw: false,
        }).addTo(rightMap.current);
        const right = vectorTileLayer(rightLayer?.url, getOptions(currentProj.type, rightLayer?.name));
        right.addTo(rightMap.current);
        right.on('click', handleOnclickRightVectorTile);
        setRightTileLayer(right);


        leftMap.current.sync(rightMap.current);
        rightMap.current.sync(leftMap.current);
        if (currentVer?.geojson_table && zoomedToCenter !== currentVer._id) {
          // eslint-disable-next-line no-undef
          const tableBBox = await axiosInstance.get(rightLayer.bbox);
          const bboxCoordinates = tableBBox.data[0].bbox.match(/\d+\.\d+/g).map(parseFloat);
          const bounds = [
            [bboxCoordinates[1], bboxCoordinates[0]],
            [bboxCoordinates[3], bboxCoordinates[2]],
          ];

          const llBounds = L.latLngBounds(bounds);

          setZoomedToCenter(currentVer._id);

          rightMap.current.setView(llBounds.getCenter(), 16);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    };

    const vectorPane = map.getPanes()[`vector-compare-pane`];

    vectorPane.style.zIndex = '400';

    handleAddLayer();

    return () => {
      map.eachLayer((e) => {
        if (e.isVectorGrid) {
          map.removeLayer(e);
        }
      });
      if (leftTileLayer) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        leftTileLayer.removeFrom(leftMap.current);
      }

      if (rightTileLayer) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        rightTileLayer.removeFrom(rightMap.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, activeShapes, styleUrl]);

  const handleMinZoom = useCallback(() => {
    const zoom = leftMap.current.getZoom();
    if (leftTileLayer) {
      if (zoom < 16) {
        leftMap.current.removeLayer(leftTileLayer);
      } else {
        leftTileLayer.addTo(leftMap.current);
      }
    }

    if (rightTileLayer) {
      if (zoom < 16) {
        rightMap.current.removeLayer(rightTileLayer);
      } else {
        rightTileLayer.addTo(rightMap.current);
      }
    }
  }, [leftMap, leftTileLayer, rightMap, rightTileLayer]);

  useEffect(() => {
    handleMinZoom();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Limit zoom to prevent crash bcs of large vector tiles
  useEffect(() => {
    leftMap.current.on('zoom', handleMinZoom);
    rightMap.current.on('zoom', handleMinZoom);

    return () => {
      if (leftMap.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        leftMap.current.off('zoom', handleMinZoom);
      }
      if (rightMap.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        rightMap.current.off('zoom', handleMinZoom);
      }
    };
  }, [handleMinZoom, leftMap, leftTileLayer, map, rightMap, rightTileLayer]);

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div />
  );
};

export default memo(CompareSideBySide);
