import React, { useState } from 'react'
import './style.scss'

export interface IDropDownSelectProps {
  value?: string;
  setValue: (event: any) => void;
  options: { label: string, value: string }[];
}

const DropDownSelect = ({ value, setValue, options }: IDropDownSelectProps) => {
  const [open, setOpen] = useState(false);

  return (
    <div className='drop-down-select'>
      <div className='drop-down-value' onClick={() => setOpen(prev => !prev)}>{value || 'No value'}</div>
      {
        open && <ul className='drop-down-list'>
          {
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            options.map(option => <li
              className='drop-down-list__value'
              key={option.value}
              onClick={() => setValue(option.value)}
            >
              {option.label}
            </li>)
          }
        </ul>
      }
    </div>
  )
}

export default DropDownSelect