import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSatellite, initSatellite } from './actions';
import { ISatellite } from '../../../services/satellite.service';

const initialState: {
  satellites: ISatellite[];
  isLoading: boolean;
  error: string;
} = {
  satellites: [],

  isLoading: false,
  error: '',
};

export const satelliteModule = createSlice({
  name: 'satellite',
  initialState,
  reducers: {
    updateSatellite: (state, action) => {
      if (state.satellites) {
        state.satellites = action.payload;
      }
    },
  },
  extraReducers: {
    // init
    [initSatellite.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.satellites = action.payload;
      state.error = '';
      state.isLoading = false;
    },
    [initSatellite.pending.type]: (state) => {
      state.isLoading = true;
    },
    [initSatellite.rejected.type]: (state) => {
      state.isLoading = false;
    },
    // create
    [createSatellite.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.satellites = action.payload;
      state.error = '';
      state.isLoading = false;
    },
    [createSatellite.pending.type]: (state) => {
      state.isLoading = true;
    },
    [createSatellite.rejected.type]: (state) => {
      state.isLoading = false;
    },
  },
});

const { updateSatellite } = satelliteModule.actions;

export { createSatellite, initSatellite, updateSatellite };

export default satelliteModule.reducer;
