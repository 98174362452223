import { combineReducers, configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import config from '../../config';
import userModule from './modules/User';
import satelliteModule from './modules/Satellite';
import telescopeModule from './modules/Telescope';
import commonModule from './modules/Common';
import projectModule from './modules/Project';
import notificationModule from './modules/Notification';

const rootReducer = combineReducers({
  user: userModule,
  common: commonModule,
  satellite: satelliteModule,
  telescope: telescopeModule,
  project: projectModule,
  notification: notificationModule,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      const middleware = getDefaultMiddleware({
        serializableCheck: false,
      });

      if (config.debug) {
        console.log('logger');
        middleware.push(logger);
      }
      return middleware;
    },
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
