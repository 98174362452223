import { ProjectType } from '../types/IProject';
import axios from '../utils/axios';

export interface IProject {
  _id?: string;
  user?: {
    _id: string;
    name: string;
  };
  satellite: string;
  name: string;
  description: string;
  center: {
    lat: number;
    lng: number;
  };
  address: string;
  area: {
    id: string;
    type: 'polygon' | 'rectangle';
    points: {
      lat: number;
      lng: number;
    }[];
  };
  type: ProjectType;
  versions: any;
}

export enum RasterProcessingStatus {
  WAIT_FOR_DATA = 'waiting_for_data',
  PROCESSING = 'processing',
  DONE = 'done',
  ERROR = 'error',
}

const ProjectService = {
  async create(body: IProject) {
    const response = await axios.post('/api/v1/project', body);

    return response.data;
  },

  async initialization() {
    const response = await axios.get('/api/v1/project');
    return response.data;
  },

  async changeName(_id: string, name: string) {
    const response = await axios.patch(`/api/v1/project/${_id}?name=${name}`);
    return response.data;
  },

  async addVersion(body: { project_id: string }) {
    const response = await axios.post('/api/v1/project/version', body);

    return response.data;
  },

  async delete(_id: string) {
    const response = await axios.delete(`/api/v1/project/${_id}`);

    return response.data;
  },

  async getMl(_id: string) {
    const response = await axios.get(`/api/v1/project/version/${_id}`);

    return response.data;
  },

  async startMl(_id: string) {
    const response = await axios.post(`/api/v1/project/ml/start`, { version_id: _id });

    return response.data;
  },

  async pauseMl(_id: string) {
    const response = await axios.post(`/api/v1/project/ml/pause`, { version_id: _id });

    return response.data;
  },

  async parseFile(data: FormData, fileType: string) {
    const response = await axios.post(`/api/v1/project/file/${fileType}`, data);

    return response.data;
  },

  async changeTifFolder(data: object, versionId: string) {
    const response = await axios.patch(`/api/v1/project/version/update-folder/${versionId}`, data);
    return response.data;
  },

  async getTifFolder(versionId: string) {
    const response = await axios.get(`/api/v1/project/version/tif-folder/${versionId}`);
    return response.data;
  },

  async getOriginalTifFolder(versionId: string, image_type: string) {
    const response = await axios.get(`/api/v1/project/version/images-folder/${versionId}?type=${image_type}`);
    return response.data;
  },

  async uploadGeoJsonFile(data: any) {
    const response = await axios.post(`/api/v1/project/file/xml-to-postgis_table`, data);
    return response.data;
  },

  async updateRaster(version: string, data: any) {
    const res = await axios.post(`/api/v1/project/readRaster/${version}`, data);

    return res.data;
  },

  async findImageId(version: string, data: any) {
    const res = await axios.post(`/api/v1/project/findImageId/${version}`, data);

    return res.data;
  },

  async populateImageId(version: string, data: any) {
    const res = await axios.post(`/api/v1/project/populateImageId/${version}`, data);

    return res.data;
  },
};

export default ProjectService;
