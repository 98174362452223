import React, { useState } from 'react';
import './style.scss'

const MapStyle = ({style, setStyle}: any) => {
    const [show, setShow] = useState(false);

    return(
        <div className='map-style' style={!show ? {} : {paddingLeft: '8px'}}>
        {show && ['satellite', 'roadmap', 'hybrid',].map(item => (
                <div
                    key={item} className={style === item ? `map-style-button style-active ${item}` : `map-style-button ${item}`}
                    onClick={() => {
                        setShow(false);
                        setStyle(item)
                    }}
                />
            )) }
             <div
                className={`map-style-button map-style-button-toggle ${style}`}
                onClick={() => setShow(prev => !prev)}
            />
        </div>
    )
}
export default MapStyle;