import { createAsyncThunk } from '@reduxjs/toolkit';
import TelescopeService, { ITelescope } from '../../../services/telescope.service';

export const createTelescope = createAsyncThunk(
  'telescope/createTelescope',
  async (params: ITelescope, thunkAPI) => {
    try {
      return await TelescopeService.create(params);
    } catch (error: any) {
      if (error.statusCode === 400) {
        return thunkAPI.rejectWithValue('Incorrect data!');
      }

      return thunkAPI.rejectWithValue(error.message || 'Server Error!');
    }
  },
);

export const initTelescope = createAsyncThunk('telescope/initTelescope', async (_, thunkAPI) => {
  try {
    return await TelescopeService.initialization();
  } catch (error: any) {
    if (error.statusCode === 400) {
      return thunkAPI.rejectWithValue('Incorrect data!');
    }

    return thunkAPI.rejectWithValue(error.message || 'Server Error!');
  }
});
