// @ts-nocheck

import {
  PerspectiveCamera,
  AmbientLight,
  HemisphereLight,
  SpotLight,
  Scene,
  WebGLRenderer,
} from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

class Viewer {
  private container: HTMLDivElement | null;

  private containerWidth: number;

  private containerHeight: number;

  private renderer: WebGLRenderer;

  public scene: Scene;

  public camera: PerspectiveCamera;

  public controls: OrbitControls;

  private frameId: number;

  constructor(container: HTMLDivElement) {
    this.container = container;

    this.containerWidth = this.container.clientWidth;
    this.containerHeight = this.container.clientHeight;

    this.init();
  }

  private init() {
    this.scene = new Scene();
    this.camera = new PerspectiveCamera(50, this.containerWidth / this.containerHeight, 0.1, 10000);

    this.camera.position.set(10, 11, 0);

    const canvas = document.createElement('canvas');
    const gl = canvas.getContext('webgl2') || undefined; // попробуй webgl2 и webgl

    this.renderer = new WebGLRenderer({ antialias: true, canvas, context: gl });
    this.renderer.setClearColor('#000000');
    this.renderer.setSize(this.containerWidth, this.containerHeight);
    this.container.appendChild(this.renderer.domElement);

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.enabled = false;
    this.controls.enableRotate = true;
    this.controls.enablePan = false;
    this.controls.rotateSpeed = 1;
    this.controls.enableZoom = true;

    this.controls.target.set(0, 11, 0);

    this.controls.enableDamping = true;
    this.controls.dampingFactor = 0.05;

    this.controls.minDistance = 0;
    this.controls.maxDistance = 200;
    this.controls.zoomSpeed = 0.3;

    this.initLight();

    this.start();
    this.resizeEvent();
  }

  private initLight() {
    const ambient = new AmbientLight('#ffffff', 0.2);
    ambient.name = 'AmbientLight';
    this.scene.add(ambient);

    const hemilight = new HemisphereLight('#eeeeee', '#eeeeee', 0.8);
    hemilight.name = 'HemisphereLight';
    hemilight.position.set(100, 0, 0);
    this.scene.add(hemilight);

    const spotLight = new SpotLight('#eeeeee', 1.6, 1000, 100);
    spotLight.name = 'SpotLight';
    spotLight.position.set(100, 0, 0);
    this.scene.add(spotLight);
  }

  private start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate);
    }
  };

  public stop = (): void => {
    this.removeEvents();
    cancelAnimationFrame(this.frameId);
    this.container.removeChild(this.renderer.domElement);
    this.clearScene();
  };

  private animate = () => {
    this.controls.update();
    this.renderer.render(this.scene, this.camera);
    this.frameId = window.requestAnimationFrame(this.animate);
  };
  //

  private updateRender = () => {
    this.containerWidth = this.container.clientWidth;
    this.containerHeight = this.container.clientHeight;

    this.camera.aspect = this.containerWidth / this.containerHeight;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(this.containerWidth, this.containerHeight);
  };
  //

  private clearScene() {
    while (this.scene.children[0]) {
      this.scene.remove(this.scene.children[0]);
    }
  }

  private resizeEvent() {
    window.addEventListener('resize', this.updateRender);
  }

  private removeEvents() {
    window.removeEventListener('resize', this.updateRender);
  }
}

export default Viewer;
