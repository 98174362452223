import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICommonStore, ViewType, ActiveModal, ECompareStep, DrawingMode } from '../../../types/ICommon';

const initialState: ICommonStore = {
  viewType: 'planet',
  compareStep: ECompareStep.close,
  mlDrawingMode: null,
  typeDrawingMode: null,
  activeModal: null,
  showNotification: false,
  drawingMode: null,
  areaSelecting: false,
  polygonSelecting: false,
  isLoading: false,
  error: '',
};

export const commonModule = createSlice({
  name: 'common',
  initialState,
  reducers: {
    updateViewType: (state, action: PayloadAction<ViewType>) => {
      state.viewType = action.payload;
    },
    setMLDrawingMode: (state, action: PayloadAction<DrawingMode>) => {
      state.mlDrawingMode = action.payload;
    },
    setTypeDrawingMode: (state, action: PayloadAction<DrawingMode>) => {
      state.typeDrawingMode = action.payload;
    },
    updateActiveModal: (state, action: PayloadAction<ActiveModal>) => {
      state.activeModal = action.payload;
    },
    setShowNotification: (state, action: PayloadAction<boolean>) => {
      state.showNotification = action.payload;
    },
    setDrawingMode: (state, action: PayloadAction<null | 'polygon' | 'rectangle'>) => {
      state.drawingMode = action.payload;
    },
    setAreaSelecting: (state, action: PayloadAction<boolean>) => {
      state.areaSelecting = action.payload;
    },
    setPolygonSelecting: (state, action: PayloadAction<boolean>) => {
      state.polygonSelecting = action.payload;
    },
    setCompareStep: (state, action: PayloadAction<ECompareStep>) => {
      state.compareStep = action.payload;
    },
  },
  extraReducers: {},
});

const { updateViewType, updateActiveModal, setShowNotification, setDrawingMode, setCompareStep, setAreaSelecting, setMLDrawingMode, setPolygonSelecting, setTypeDrawingMode } =
  commonModule.actions;

export { updateViewType, updateActiveModal, setShowNotification, setDrawingMode, setCompareStep, setAreaSelecting, setMLDrawingMode, setPolygonSelecting, setTypeDrawingMode };

export default commonModule.reducer;
