import React, { FC, useEffect, useState } from 'react';
import { Outlet } from 'react-router';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loader } from '../../components/Loading';
import { login } from '../constants';
import { initialization } from '../../store/modules/User';
import { initSatellite } from '../../store/modules/Satellite';
import { initTelescope } from '../../store/modules/Telescope';
import {
  initProject,
  updateProject,
  removeProject,
  updateStatus,
} from '../../store/modules/Project';
import { useAppDispatch, useAuth, useNotification } from '../../hooks/redux';
import socket from '../../../socket';
import { initNotification, updateNotifications } from '../../store/modules/Notification';

const AuthGuard: FC = () => {
  const [isLoading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const { profile } = useAuth();

  useEffect(() => {
    dispatch(initialization())
      .then(() => setLoading(false))
      .catch(() => setLoading(false));

    dispatch(initSatellite());
    dispatch(initTelescope());
    dispatch(initProject());
    dispatch(initNotification());
  }, [dispatch]);

  useEffect(() => {
    socket.connect();

    function onDisconnect(reason: string) {
      if (reason === 'io server disconnect') {
        socket.connect();
      }
    }

    socket.on('disconnect', onDisconnect);

    return () => {
      socket.off('disconnect', onDisconnect);

      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    function onUpdProject(reason: any) {
      dispatch(updateProject(reason));
    }

    function onRemoveProject(reason: any) {
      dispatch(removeProject(reason));
    }

    function onUpdNotification(reason: any) {
      dispatch(updateNotifications(reason));
    }

    function onUpdStatus(reason: any) {
      dispatch(updateStatus(reason));
    }

    function onChangeTifFolder(reason: any) {
      toast.success(`Raster pipeline is successful. ${reason.name}'s TIF Folder is changed successfully !`);
    }

    socket.on('update-project', onUpdProject);
    socket.on('remove-project', onRemoveProject);
    socket.on('update-notification', onUpdNotification);
    socket.on('update-status', onUpdStatus);
    socket.on('change-tif-folder', onChangeTifFolder);

    return () => {
      socket.off('update-project', onUpdProject);
      socket.off('remove-project', onRemoveProject);
      socket.off('update-notification', onUpdNotification);
      socket.off('update-status', onUpdStatus);
      socket.off('change-tif-folder', onChangeTifFolder);
    };
  }, [dispatch]);

  if (isLoading) return <Loader />;

  if (!profile) {
    return <Navigate to={login} replace />;
  }

  return <Outlet />;
};

export default AuthGuard;
