import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { login, initialization } from './actions';
import { IUser, UserState } from '../../../types/IUser';

const initialState: UserState = {
  profile: null,

  isLoading: false,
  error: '',
};

export const userModule = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: (state, action) => {
      if (state.profile) {
        state.profile = action.payload;
      }
    },
  },
  extraReducers: {
    // init
    [initialization.fulfilled.type]: (state, action: PayloadAction<IUser>) => {
      state.profile = action.payload;
      state.isLoading = false;
    },
    [initialization.pending.type]: (state) => {
      state.isLoading = true;
    },
    [initialization.rejected.type]: (state) => {
      state.isLoading = false;
    },
    // signIn
    [login.fulfilled.type]: (state) => {
      state.error = '';
      state.isLoading = false;
    },
    [login.pending.type]: (state) => {
      state.isLoading = true;
    },
    [login.rejected.type]: (state, action: PayloadAction<string>) => {
      toast.error(action.payload);
      state.isLoading = false;
    },
  },
});

const { updateUser } = userModule.actions;

export { initialization, login, updateUser };

export default userModule.reducer;
