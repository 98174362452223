import React from 'react';
import './style.scss';
import ReactDOM from 'react-dom';

export const InnerLoading = ({
  shown,
  children,
}: {
  shown: boolean;
  children: React.ReactNode;
}) => {
  return (
    <>
      <div className="loader-parent">
        <div className={`${shown ? 'active' : ''} loader-wrapper-small`}>
          <div className="loader">
            <div id="logo-animation">
              <div className="loader-dots">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
            </div>
            <span />
          </div>
        </div>
        {children}
      </div>
    </>
  );
};

export const Loading = ({ shown, showBG = true }: { shown: boolean, showBG?: boolean }) => {
  return (
    <>
      <div className={`${shown ? 'active' : ''} loader-wrapper ${showBG ? '' : 'no-bg'}`}>
        <div className="loader">
          <div id="logo-animation">
            <div className="loader-dots">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
          </div>
          <span />
        </div>
      </div>
    </>
  );
};

export const Loader = () => {
  return ReactDOM.createPortal(
    <>
      <div className="active loader-wrapper">
        <div className="loader">
          <div id="logo-animation">
            <div className="loader-dots">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
          </div>
          <span />
        </div>
      </div>
    </>,
    document.body,
  );
};
