import { Units } from "../views/Home/components/ProjectInfo/constants";

export const sqkmConversion = {
  [Units.METERS]: 1000000,
  [Units.FEET]: 10763910.41671,
  [Units.KILOMETERS]: 1,
  [Units.MILES]: 0.386102,
}

const getArea = (distance: number, unit: string) => {
  //km to other unit
  switch (unit) {
    case Units.METERS:
      return distance * sqkmConversion[Units.METERS];
    case Units.KILOMETERS:
      return distance * sqkmConversion[Units.KILOMETERS];
    case Units.FEET:
      return distance * sqkmConversion[Units.FEET];
    case Units.MILES:
      return distance * sqkmConversion[Units.MILES];
    default:
      return distance;
  }
}

export default getArea;