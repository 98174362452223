import React, { useState } from 'react'
import Icon from '../../../ui/Icon'
import './style.scss'
import UnitSelect from './UnitSelect';
import { useCommon } from '../../../../hooks/redux';

interface IToolPropperty {
  active: boolean;
  toggle: any;
  disable?: boolean;
}

export interface IMapToolsProps {
  compare: IToolPropperty;
  ruler: IToolPropperty;
  area: IToolPropperty;
  drawPolygon: IToolPropperty;
  drawRectangle: IToolPropperty;
  areaSelect: IToolPropperty;
  polygonSelect: IToolPropperty;
  grid: IToolPropperty;
  raster: IToolPropperty;
  unit: {
    active: string;
    toggle: any;
  };
  drawing: boolean;
  zoomToDefault: () => void;
}

const MapTools = ({
  raster,
  grid,
  compare,
  drawPolygon,
  drawRectangle,
  ruler,
  area,
  unit,
  drawing,
  areaSelect,
  polygonSelect,
  zoomToDefault,
}: IMapToolsProps) => {
  const [openSelectUnit, setOpenSelectUnit] = useState(false);
  const { mlDrawingMode } = useCommon();
  const toggleRuler = () => {
    if (drawing) return;

    if (area.active) {
      area.toggle(false);
      document.getElementById('leaflet-area')?.click();
    }

    ruler.toggle((prev: any) => !prev);
    document.getElementById('leaflet-ruler')?.click();
  };

  const toggleArea = () => {
    if (drawing) return;

    if (ruler.active) {
      ruler.toggle(false);
      document.getElementById('leaflet-ruler')?.click();
    }

    area.toggle((prev: any) => !prev);
    document.getElementById('leaflet-area')?.click();
  };

  const toggleChangeStatus = () => {
    if (polygonSelect.active) {
      polygonSelect.toggle(false);
    }
    if (drawing) return;

    if (areaSelect.active) {
      areaSelect.toggle(false);
    } else {
      areaSelect.toggle(true);
    }
  };

  const toggleSelectPolygon = () => {
    if (areaSelect.active) {
      areaSelect.toggle(false);
    }
    if (drawing) return;

    if (polygonSelect.active) {
      polygonSelect.toggle(false);
    } else {
      polygonSelect.toggle(true);
    }
  };

  const toggleDraw = (type: string) => {
    if (type === 'polygon') {
      if (mlDrawingMode === 'polygon') {
        drawPolygon.toggle(null);
      } else {
        drawPolygon.toggle(type);
      }
    } else if (type === 'rectangle') {
      if (mlDrawingMode === 'rectangle') {
        drawRectangle.toggle(null);
      } else {
        drawRectangle.toggle(type);
      }
    }
  };

  const toggleShowGrid = () => {
    grid.toggle((prev: boolean) => !prev);
  }

  const toggleShowRaster = () => {
    raster.toggle((prev: boolean) => !prev);
  }

  return (
    <div className="map-tools">
      <div className="map-tools__btn" onClick={zoomToDefault}>
        <Icon name="pin" color="blue" size={4} />
      </div>
      <div
        className={`map-tools__btn ${compare.active ? 'active' : ''}${compare.disable ? 'disabled' : ''
          }`}
        onClick={compare.toggle}
      >
        <Icon name="slider" color={`${compare.active ? 'black' : 'blue'}`} size={4} />
      </div>
      <div
        className={`map-tools__btn ${drawPolygon.active ? 'active' : ''}${drawPolygon.disable ? 'disabled' : ''
          }`}
        onClick={() => toggleDraw('polygon')}
      >
        <Icon name="polygon" color={`${drawPolygon.active ? 'black' : 'blue'}`} size={4} />
      </div>
      <div
        className={`map-tools__btn ${drawRectangle.active ? 'active' : ''}${drawRectangle.disable ? 'disabled' : ''
          }`}
        onClick={() => toggleDraw('rectangle')}
      >
        <Icon name="box" color={`${drawRectangle.active ? 'black' : 'blue'}`} size={4} />
      </div>
      <div
        className={`map-tools__btn ${areaSelect.active ? 'active' : ''}${areaSelect.disable ? 'disabled' : ''
          }`}
        onClick={toggleChangeStatus}
      >
        <Icon name="select-area" color={`${areaSelect.active ? 'black' : 'blue'}`} size={4} />
      </div>
      <div
        className={`map-tools__btn ${polygonSelect.active ? 'active' : ''}${polygonSelect.disable ? 'disabled' : ''
          }`}
        onClick={toggleSelectPolygon}
      >
        <Icon name="cursor-selection" color={`${polygonSelect.active ? 'black' : 'blue'}`} size={4} />
      </div>
      <div
        className={`map-tools__btn ${grid.active ? 'active' : ''} ${grid.disable ? 'disabled' : ''}`}
        onClick={toggleShowGrid}
      >
        <Icon name="grid" color={`${grid.active ? 'black' : 'blue'}`} size={4} />
      </div>
      <div
        className={`map-tools__btn ${raster.active ? 'active' : ''} ${raster.disable ? 'disabled' : ''}`}
        onClick={toggleShowRaster}
      >
        <Icon name="tif-folder" color={`${raster.active ? 'black' : 'blue'}`} size={4} />
      </div>
      <div
        className={`map-tools__btn ${ruler.active ? 'active' : ''}${ruler.disable ? 'disabled' : ''
          }`}
        onClick={toggleRuler}
      >
        <Icon name="ruler" color={`${ruler.active ? 'black' : 'blue'}`} size={4} />
      </div>

      <div
        className={`map-tools__btn ${area.active ? 'active' : ''} ${area.disable ? 'disabled' : ''}`}
        onClick={toggleArea}
      >
        <Icon name="area" color={`${area.active ? 'black' : 'blue'}`} size={4} />
      </div>

      <div className="map-tools__btn unit" onClick={() => setOpenSelectUnit((prev) => !prev)}>
        {unit.active}
        {openSelectUnit && <UnitSelect setUnit={unit.toggle} />}
      </div>
    </div>
  );
};

export default MapTools