import React, { memo, useEffect } from 'react'
import { MapContainer, Popup, TileLayer, useMap } from 'react-leaflet'
import { useParams } from 'react-router-dom';
import { useProject } from '../../../../hooks/redux';
import config from '../../../../../config';
import TileInfo from '../ModelInfo/TileInfo'
import trimObjectKey from '../../../../helpers/trimObjectKey'
import './style.scss';
import { IVersion } from '../../../../types/IProject';

export interface ISideBySideMapProps {
  styleUrl: string;
  leftMap: React.MutableRefObject<null>;
  rightMap: React.MutableRefObject<null>;
  defaultProp: {
    center: L.LatLng,
    zoom: number
  };
  leftPos?: L.LatLng;
  rightPos?: L.LatLng;
  leftTile: any;
  rightTile: any;
  leftVersion: IVersion;
  rightVersion: IVersion;
}

const MapHandler = () => {
  const map = useMap();
  const { versionId } = useParams();
  const { projectsList, current } = useProject();

  useEffect(() => {
    if (!versionId) return;
    if (!(map as any).restoreView(versionId)) {
      if (current) {
        const center = projectsList.find((item) => item._id === current.project)?.center;
        if (center) {
          map?.setView(center, 16);
        }
      } else {
        map.setView([37.17624705522682, 36.437428248184304], 16);
      }
    }
  }, [current, map, projectsList, versionId])

  return null;
}

const SideBySideMap = ({
  styleUrl,
  defaultProp,
  leftPos,
  rightPos,
  leftTile,
  rightTile,
  rightMap,
  leftMap,
  leftVersion,
  rightVersion,
}: ISideBySideMapProps) => {
  const { projectsList, current } = useProject();
  const currentProj = current && projectsList.find((item) => item._id === current.project);
  const defaultCenter = currentProj ? currentProj.center : [37.17624705522682, 36.437428248184304];

  const project: any = projectsList.find((item) => item._id === current?.project);

  return (
    <div className="side-by-side-map">
      <MapContainer
        ref={leftMap}
        id="mapLeft"
        center={defaultCenter}
        zoom={defaultProp.zoom}
        style={{ width: '50%', height: '100%' }}
      >
        <TileLayer
          attribution='© <a href="https://apps.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          url={`${styleUrl}`}
          maxZoom={24}
          subdomains={['mt0','mt1','mt2','mt3']}
        />
        {leftPos && (
          <Popup position={leftPos}>
            {leftTile && leftTile.properties && (
              <TileInfo
                properties={trimObjectKey(leftTile.properties)}
              />
            )}
          </Popup>
        )}

        <MapHandler />
      </MapContainer>
      <MapContainer
        id="mapRight"
        ref={rightMap}
        center={defaultCenter}
        zoom={defaultProp.zoom}
        style={{ width: '50%', height: '100%' }}
      >
        <TileLayer
          attribution='© <a href="https://apps.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          url={`${styleUrl}`}
          maxZoom={24} 
          subdomains={['mt0','mt1','mt2','mt3']}
        />
        {rightPos && (
          <Popup position={rightPos}>
            {rightTile && rightTile.properties && (
              <TileInfo
                properties={trimObjectKey(rightTile.properties)}
              />
            )}
          </Popup>
        )}
        <MapHandler />
      </MapContainer>
    </div>
  );
};

export default memo(SideBySideMap)