import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { useDispatch } from 'react-redux';
import Button from '../../../ui/Button';

import ChangeStatusForm from './ChangeStatusForm';
import TilesService, { ITileProperties } from '../../../../services/tiles.service';
import { useCommon, useProject } from '../../../../hooks/redux';
import { setAreaSelecting } from '../../../../store/modules/Common';
import ConfirmationForm from './ConfirmationForm';
import './style.scss';

interface IProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  selectedPolys: ITileProperties[];
  setSelectedPolys: React.Dispatch<React.SetStateAction<ITileProperties[] | null>>;
  editableFGRef: React.MutableRefObject<any>;
}

const MultipleControlPanel = ({
  setLoading,
  selectedPolys,
  setSelectedPolys, 
  editableFGRef,
}: IProps) => {
  const { currentVer } = useProject();
  const { areaSelecting } = useCommon();
  const map = useMap();
  const dispatch = useDispatch();

  const [changingStatus, setChangingStatus] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);

  const handleEnableMapInteraction = React.useCallback(() => {
    map.dragging.enable();
    map.touchZoom.enable();
    map.doubleClickZoom.enable();
    map.scrollWheelZoom.enable();
    map.boxZoom.enable();
    map.keyboard.enable();
    if (map.tap) map.tap.enable();
  }, [map]);

  const handleDisableMapInteraction = React.useCallback(() => {
    map.dragging.disable();
    map.touchZoom.disable();
    map.doubleClickZoom.disable();
    map.scrollWheelZoom.disable();
    map.boxZoom.disable();
    map.keyboard.disable();
    if (map.tap) map.tap.disable();
  }, [map]);

  useEffect(() => {
    if (!areaSelecting) {
      setTimeout(() => {
        setSelectedPolys(null);
        handleEnableMapInteraction();
      }, 100);
    }
  }, [areaSelecting, handleEnableMapInteraction, setSelectedPolys]);

  const handleOpenStatusForm = () => {
    if(confirmDelete) return;
    handleDisableMapInteraction();
    setChangingStatus(true);
  };

  const handleCloseStatusForm = () => {
    handleEnableMapInteraction();
    setChangingStatus(false);
  };

  const handleOpenConfirmation = () => {
    if(changingStatus) return;
    setConfirmDelete(true);
  }
  const handleCloseConfirmation = () => setConfirmDelete(false);

  const handleSubmitChangeStatus = async (values: object) => {
    try {
      setLoading(true);
      const filteredObj = Object.fromEntries(
        Object.entries(values).filter(([key, value]) => value !== undefined)
      );

      const newValues = {
        ...filteredObj,
        geojson_table: currentVer?.geojson_table,
        ogc_fids: selectedPolys ? selectedPolys?.map((el) => el.ogc_fid) : [],
      };
      await TilesService.multipleChangeStatus(newValues);

      setSelectedPolys(null);

      setTimeout(() => {
        handleEnableMapInteraction();
        dispatch(setAreaSelecting(false));
        setChangingStatus(false);
      }, 100);

    } catch (error) {
      throw new Error(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };

  const handleDeletePoly = async () => {
    try {
      setLoading(true);
      const data: any = {};
      data.geojson_table = currentVer?.geojson_table;
      data.ogc_fids = selectedPolys ? selectedPolys.map((el) => el.ogc_fid) : [];

      await TilesService.multipleDeletePolygon(data);

      setSelectedPolys(null);

      setTimeout(() => {
        handleEnableMapInteraction();
        dispatch(setAreaSelecting(false));
        setConfirmDelete(false);
      }, 100);

    } catch (error) {
      throw new Error(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };

  const handleClear = () => {
    if(changingStatus || confirmDelete) return;
    const drawnItems = (editableFGRef as any)?.current?.getLayers() || [];
    drawnItems.forEach((item: L.Layer) => {
      if ((item as any)?.editing) {
        map.removeLayer(item);
      }
    });

    const element = document.querySelector(`.leaflet-draw-draw-polygon`);

    if (element && areaSelecting) {
      (element as HTMLElement).click();
    }

    setTimeout(() => {
      setSelectedPolys(null);
    }, 100);
  };

  return (
    <div className="multiple-control">
      <div className="multiple-control-btn-group">
        <Button className="btn btn-clear clean-main-btn" onClick={handleClear} disabled={confirmDelete || changingStatus}>
          Clear Draw
        </Button>
        <>
            <Button className="btn main-btn" onClick={handleOpenStatusForm} disabled={confirmDelete || selectedPolys.length === 0}>
              Change Status
            </Button>
            <Button className="btn btn-delete main-btn" onClick={handleOpenConfirmation} disabled={changingStatus || selectedPolys.length === 0}>
              Delete
            </Button>
          </>
      </div>

      {changingStatus && (
        <ChangeStatusForm onSubmit={handleSubmitChangeStatus} onClose={handleCloseStatusForm} />
      )}

      {confirmDelete && (
        <ConfirmationForm onClose={handleCloseConfirmation} onDelete={handleDeletePoly} />
      )}
    </div>
  );
};

export default MultipleControlPanel;
