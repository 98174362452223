import React, { FC, ReactNode } from 'react';
import './style.scss';
import RSC from 'react-scrollbars-custom';
import cn from 'classnames';

interface IProps {
  height: number | string;
  children: ReactNode;
  isBg?: boolean;
}

const ScrollWrapper: FC<IProps> = ({ height = 200, children, isBg = true }) => {
  return (
    <RSC className={cn('scroll-wrapper', { 'no-bg': !isBg })} style={{ height }} noScrollX>
      {children}
    </RSC>
  );
};

export default ScrollWrapper;
