// @ts-nocheck
/* eslint-disable */

import {
  Mesh,
  MeshBasicMaterial,
  Vector3,
  BufferGeometry,
  Line,
  LineBasicMaterial,
  Group,
  SphereGeometry,
} from 'three';
import Label from '../Label/Label';

import * as Kepler from 'kepler.js';

import { ISatellite } from '../../../types/ISatellite';
import Viewer from '../Viewer/Viewer';

export default class Satellite {
  public mainContainer: Group;

  public meshContainer: Group;

  private mesh: Mesh;

  private line: Line;

  private highlightMesh: Mesh;

  private label: Label;

  private satrecK: any; // kepler.js record

  public hovered: boolean;

  public selected: boolean;

  public currentTime = 0;

  private timeDeviation: number;

  private speedIncrease = 1;

  private orbitScaleFactor = 0.002;

  constructor(public props: ISatellite, private model: any, private viewer: Viewer) {
    this.timeDeviation = props.timeDeviation || 0;
    this.init();
  }

  init() {
    this.mainContainer = new Group();
    this.mainContainer.name = 'Satellite';

    this.meshContainer = new Group();
    this.mainContainer.add(this.meshContainer);

    this.model && this.setModel(this.model);

    this.satrecK = Kepler.Orbit.fromParams({
      semimajorAxis: this.props.a, // km
      eccentricity: this.props.e,
      inclination: (this.props.i / Math.PI) * 180, // deg
      rightAscension: (this.props.RA / Math.PI) * 180, // deg
      argumentOfPeriapsis: (this.props.omega / Math.PI) * 180, // deg
    });

    this.setLabel(this.props.name);
  }

  setModel(model) {
    if (!this.mesh) {
      this.mesh = model.clone();
      this.mesh.position.y = -0.5;

      this.mesh.traverse((item) => {
        item.userData = {
          parent: this,
        };
      });

      this.meshContainer.add(this.mesh);

      //
      this.highlightMesh = new Mesh(
        new SphereGeometry(1, 24, 24),
        new MeshBasicMaterial({ side: 1, color: '#ccccff', transparent: true, opacity: 0.5 }),
      );
      this.highlightMesh.raycast = () => {};
      this.highlightMesh.position.y = 0.3;
      this.meshContainer.add(this.highlightMesh);
    }
  }

  updateParams(newParams) {
    if (newParams.timeDeviation !== this.timeDeviation) {
      this.timeDeviation = newParams.timeDeviation;
    }

    if (newParams.speedIncrease !== this.speedIncrease) {
      this.speedIncrease = newParams.speedIncrease;
    }

    if (this.props.custom) {
      this.props = newParams;

      this.satrecK = Kepler.Orbit.fromParams({
        semimajorAxis: this.props.a, // km
        eccentricity: this.props.e,
        inclination: (this.props.i / Math.PI) * 180, // deg
        rightAscension: (this.props.RA / Math.PI) * 180, // deg
        argumentOfPeriapsis: (this.props.omega / Math.PI) * 180, // deg
      });
    }
  }

  getDistance() {
    return 'this.orbitFunction(1).length()';
  }

  hover() {
    if (!this.selected) {
      this.label.mainContainer.visible = true;
      this.hovered = true;
    }
  }

  reset() {
    if (!this.selected) {
      this.label.mainContainer.visible = false;
    }
    this.hovered = false;
  }

  select() {
    this.label.mainContainer.visible = true;
    this.selected = true;
  }

  deselect() {
    this.label.mainContainer.visible = false;
    this.selected = false;
  }

  // orbit
  updatePosition(timeInfo) {
    this.currentTime += timeInfo.delta * this.speedIncrease;
    const actualTime = this.currentTime + timeInfo.offset + this.timeDeviation * 60;
    this.meshContainer.position.copy(this.orbitFunction(actualTime));

    // highlight
    this.updateHightlight(this.meshContainer.position);
    //

    this.meshContainer.lookAt(new Vector3());

    if (this.hovered || this.selected) {
      this.drawOrbit(this.calculateOrbit(actualTime));
    } else {
      this.line && this.mainContainer.remove(this.line);
    }
  }

  updateHightlight(pos) {
    const min = 120;
    if (this.highlightMesh) {
      const distance = this.viewer.camera.position.distanceTo(pos);
      if (distance < min) {
        // @ts-ignore
        this.highlightMesh.material.opacity = 0;
      } else if (distance > min * 2) {
        // @ts-ignore
        this.highlightMesh.material.opacity = 0.9;
      } else {
        // @ts-ignore
        this.highlightMesh.material.opacity = (distance - min) / (min * 1.1);
      }
    }
  }

  calculateOrbit(startTime) {
    const positions: Vector3[] = [];
    const delta = 1.5 * 60;

    let step = 1;

    const start = this.orbitFunction(startTime);
    positions.push(start);

    const second = this.orbitFunction(startTime + step * delta);
    positions.push(second);
    step++;

    const distance = start.distanceTo(second) * 1.3;

    let curDist = distance * 2;

    while (curDist > distance) {
      const nextPos = this.orbitFunction(startTime + step * delta);
      positions.push(nextPos);

      curDist = nextPos.distanceTo(start);
      step++;
    }

    // last step
    const nextPos = this.orbitFunction(startTime + step * delta);
    positions.push(nextPos);

    return positions;
  }

  drawOrbit(points) {
    this.line && this.mainContainer.remove(this.line);

    const geometrySpline = new BufferGeometry().setFromPoints(points);

    this.line = new Line(geometrySpline, new LineBasicMaterial({ color: 0xffffff }));
    this.line.computeLineDistances();
    this.line.raycast = () => {};

    this.mainContainer.add(this.line);
  }

  orbitFunction(t) {
    const pos = this.satrecK.update(t).r.elements;

    return new Vector3(pos[0], pos[2], pos[1]).multiplyScalar(this.orbitScaleFactor);
  }

  setLabel(text) {
    this.label = new Label(text);
    this.meshContainer.add(this.label.mainContainer);
    this.label.mainContainer.visible = false;
  }
}
