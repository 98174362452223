import React from 'react';
import { FieldError } from 'react-hook-form';

import './style.scss';

interface IProps {
  isDisabled?: boolean;
  label: string;
  type?: string;
  placeholder?: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.ChangeEventHandler<HTMLInputElement>;
  error?: FieldError;
}

const TextField: React.FC<IProps> = ({
  isDisabled,
  label,
  type = 'text',
  placeholder,
  value,
  onChange,
  onBlur,
  error
}) => {
  return (
    <div className='textField-wrapper'>
      <label className='textField-label'>{label}</label>
      <span className='textField-input'>
        <input
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={isDisabled}
        />
      </span>
      {error && <span className='textField-error'>{error.message}</span>}
    </div>
  );
};

export default TextField;
