import axios from 'axios';
import config from '../../config';

const axiosInstance = axios.create({
  baseURL: config.apiUrl,
});

export const axiosBaseQuery =
  () =>
  async ({ url, method, data, params, headers }: any) => {
    try {
      const result = await axiosInstance({
        url,
        method,
        data,
        params,
        headers,
      });
      return { data: result?.data };
    } catch (axiosError: any) {
      return {
        error: {
          status: axiosError.response?.status,
          data: axiosError.response?.data || axiosError.message,
        },
      };
    }
  };

axiosInstance.interceptors.response.use(
  (response) => response,
  ({ response }) => {
    if (response.data) {
      if (Array.isArray(response.data.message)) {
        response.data.message = response.data.message.join(', ');
      }

      return Promise.reject(response.data);
    }

    return Promise.reject(response.data);
  },
);

export default axiosInstance;
