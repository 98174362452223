import React from 'react'
import Icon from '../../../../ui/Icon';
import './style.scss';

export interface IValueProps {
  icon: string;
  value: string;
}

const Value = ({ icon, value }: IValueProps) => {
  return (
    <div className='select-value'>
      <Icon name={icon} color='blue' size={3} />
      <span>{value}</span>
    </div>
  )
}

export default Value