import React from 'react';
import { Tooltip } from 'react-tooltip';
import styles from './SatelliteModal.module.scss';
import ModalWrapper from '../../ModalWrapper';
import Button from '../../ui/Button';
import Icon from '../../ui/Icon';
import ScrollWrapper from '../../ScrollWrapper';

const SatelliteModal = ({ satelliteInfo, setShowSatelliteModal }: any) => {
  return (
    <ModalWrapper
      title={satelliteInfo?.name}
      className="satelliteInfoModal"
      close={setShowSatelliteModal}
      small
    >
      <>
        <div className={styles.parameters}>
          <p className={styles.sectionTitle}>Orbital Parameters</p>
          <ul className={styles.parametersList}>
            <li>
              <label>
                a =
                <input type="number" value={satelliteInfo?.a} readOnly name="a" />
                <span
                  className={styles.parametersHelper}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Semimajor Axis/km"
                >
                  ?
                </span>
              </label>
            </li>
            <li>
              <label>
                e =
                <input type="number" value={satelliteInfo?.e} readOnly name="e" />
                <span
                  className={styles.parametersHelper}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Eccentricity"
                >
                  ?
                </span>
              </label>
            </li>
            <li>
              <label>
                i =
                <input type="number" value={satelliteInfo?.i} readOnly name="i" />
                <span
                  className={styles.parametersHelper}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Inclination/rad"
                >
                  ?
                </span>
              </label>
            </li>
            <li>
              <label>
                ω =
                <input type="number" value={satelliteInfo?.omega} readOnly name="omega" />
                <span
                  className={styles.parametersHelper}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Argument of Perigee/rad"
                >
                  ?
                </span>
              </label>
            </li>
            <li>
              <label>
                Ω =
                <input type="number" value={satelliteInfo?.RA} readOnly name="RA" />
                <span
                  className={styles.parametersHelper}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Right Angle of Ascension/rad"
                >
                  ?
                </span>
              </label>
            </li>
            <li>
              <label>
                v =
                <input type="number" value={satelliteInfo?.Me} readOnly name="Me" />
                <span
                  className={styles.parametersHelper}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Mean Anomaly/rad"
                >
                  ?
                </span>
              </label>
            </li>
          </ul>
        </div>
        <div className={styles.geolocations}>
          <p className={styles.sectionTitle}>ground station contact</p>
          <ScrollWrapper height="167px">
            <div className={styles.geoWrap}>
              <div className={styles.geoInformation}>
                <div>
                  <div className={styles.rowItem}>
                    <span className={styles.span}>gs-421</span>
                    <div className={styles.iconWrap}>
                      <Icon name="pin" color="white" />
                    </div>
                    Virginia, USA
                  </div>
                  <div className={styles.rowItem}>
                    <span className={styles.span}>Contact In:</span>
                    <div className={styles.iconWrap}>
                      <Icon name="pin" color="white" />
                    </div>
                    0 min (ETA)
                  </div>
                  <div className={styles.rowItem}>12:41:35 pm (CST)</div>
                </div>
                <Button className="button">
                  <Icon name="download" color="black" size={3} />
                </Button>
              </div>
              <div className={styles.geoInformation}>
                <div>
                  <div className={styles.rowItem}>
                    <span className={styles.span}>gs-421</span>
                    <div className={styles.iconWrap}>
                      <Icon name="pin" color="white" />
                    </div>
                    Virginia, USA
                  </div>
                  <div className={styles.rowItem}>
                    <span className={styles.span}>Contact In:</span>
                    <div className={styles.iconWrap}>
                      <Icon name="pin" color="white" />
                    </div>
                    0 min (ETA)
                  </div>
                  <div className={styles.rowItem}>12:41:35 pm (CST)</div>
                </div>
                <Button className="button">
                  <Icon name="link" color="black" size={3} />
                </Button>
              </div>
              <div className={styles.geoInformation}>
                <div>
                  <div className={styles.rowItem}>
                    <span className={styles.span}>gs-421</span>
                    <div className={styles.iconWrap}>
                      <Icon name="pin" color="white" />
                    </div>
                    Virginia, USA
                  </div>
                  <div className={styles.rowItem}>
                    <span className={styles.span}>Contact In:</span>
                    <div className={styles.iconWrap}>
                      <Icon name="pin" color="white" />
                    </div>
                    0 min (ETA)
                  </div>
                  <div className={styles.rowItem}>12:41:35 pm (CST)</div>
                </div>
                <Button className="button">
                  <Icon name="download" color="black" size={3} />
                </Button>
              </div>
            </div>
          </ScrollWrapper>
        </div>
        <Tooltip id="my-tooltip" className="tooltip" />
      </>
    </ModalWrapper>
  );
};
export default SatelliteModal;
