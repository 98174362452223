export interface ICoords {
  lat: number;
  lng: number;
}

export interface ICircle {
  //id: string;
  type: 'circle';
  radius: number;
  center: ICoords;
}

export interface IPolygon {
  //id: string;
  type: 'polygon' | 'rectangle';
  points: ICoords[];
}

export type IArea = ICircle | IPolygon;

export interface IUser {
  _id: string;
  name: string;
  createdAt: string;
}

export interface IAddress {
  _id: string;
  address: string;
  createdAt: string;
}

export interface IProject {
  _id: string;
  name: string;
  user: IUser;
  description: string;
  areas: any[];
  address: IAddress;
  center: ICoords;
  createdAt: string;
  type: ProjectType;
  versions?: IVersion[];
}

export interface IRoadPart {
  _id?: string;
  quality: number;
  points: string[];
  center: ICoords;
  visible?: boolean;
}

export interface IShapeDetails {
  name: string;
  placeId: string;
}

export interface IShape {
  _id?: string;
  createdAt?: string;
  project?: string;
  type: string;
  points: string[];
  exterior_coords: string[];
  interior_coords: string[];
  versionId?: string;
  state?: number;
  confidence?: number;
  roadCropAutosave?: boolean;
  parts?: IRoadPart[];
  originType?: string;
  details?: IShapeDetails;
  length?: number;
  coords?: ICoords[];
}

export interface IProcessing {
  _id: string;
  instanceId: string;
  versionId: string;
  targetVersionId: string;
  projectId: string;
  status: string;
  mlTypes: string[];
  progress: number;
  createdAt: string;
}

export interface IVersion {
  _id: string;
  project: string;
  number: number;
  createdBy: string;
  processType?: string;
  instanceId?: string;
  user: string;
  createdAt: string;
  processing: IProcessing | null;
  url_tif?: string;
  geojson_table?: string;
}

export interface IProjectData {
  project: IProject;
  ml: IShape[];
  annotations: any[];
  versions: IVersion[];
  versionId: string;
}

export interface IAddShapePayload {
  payload: IShape;
}

export interface IAddRoadPartPayloadData {
  _id: string;
  parts: IRoadPart[];
  removeID?: string;
  originShapePoints?: string[];
}

export interface IAddRoadPartPayload {
  payload: IAddRoadPartPayloadData;
}

export interface IUpdateProcessingData {
  _id: string;
  status: string;
}

export interface IUpdateProcessingPayload {
  payload: IUpdateProcessingData;
}

export interface IUpdateByPollingProjectData {
  ml?: IShape[];
  versions: IVersion[];
}

export interface IUpdateByPollingProjectPayload {
  payload: IUpdateByPollingProjectData;
}

export interface IAddShapeDetailsData {
  shapeId: string;
  details: IShapeDetails;
}

export interface IAddShapeDetailsPayload {
  payload: IAddShapeDetailsData;
}

export enum ProjectType {
  building = 'building',
  vehicle = 'vehicle',
}
