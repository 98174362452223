/* eslint-disable object-shorthand */

import getArea, { sqkmConversion } from "../../../../../../helpers/getArea";
import getDistance, { kmConversion } from "../../../../../../helpers/getDistance";
import getUnitText from "../../../../../../helpers/getUnitText";
import { Units } from "../../../../../../views/Home/components/ProjectInfo/constants";

/* eslint-disable func-names */
const leafletArea = (L, app) => {
  L.Control.Area = L.Control.extend({
    options: {
      position: 'bottomright',
      circleMarker: {
        icon: L.divIcon({
          className: 'marker-box',
          iconSize: [16, 16],
          iconAnchor: [8, 8],
        }),
      },
      lineStyle: {
        color: 'var(--blueColor)',
        dashArray: '6, 6'
      },
      polyStyle: {
        type: 'polygon',
        allowIntersection: false,
        drawError: {
          color: '#e1e100',
          message: "<strong>Oh snap!<strong> you can't draw that!",
        },
        shapeOptions: {
          color: 'var(--blueColor)',
        },
        stroke: false,
        color: 'var(--blueColor)',
      },
      lengthUnit: {
        display: app.unit,
        decimal: 2,
        factor: null,
        label: 'Distance:',
      },
      angleUnit: {
        display: '&deg;',
        decimal: 2,
        factor: null,
        label: 'Heading:',
      },
    },
    // eslint-disable-next-line object-shorthand
    onAdd: function (map) {
      this.map = map;
      this.clickOne = true;
      this._container = L.DomUtil.create('div', 'leaflet-bar');
      this._container.classList.add('leaflet-area');
      this._container.id = 'leaflet-area';
      this._container.innerHTML = '<span class="icon-area size-icon-3"></span>';
      L.DomEvent.disableClickPropagation(this._container);
      L.DomEvent.on(this._container, 'click', this._toggleMeasure, this);
      this._choice = false;
      this._defaultCursor = this.map._container.style.cursor;
      this._allLayers = L.layerGroup();
      return this._container;
    },
    // eslint-disable-next-line object-shorthand
    onRemove: function () {
      L.DomEvent.off(this._container, 'click', this._toggleMeasure, this);
    },
    _toggleMeasure: function () {
      this._choice = !this._choice;
      this._clickedLatLong = null;
      this._clickedPoints = [];
      this._totalLength = 0;
      if (this._choice) {
        this.map.doubleClickZoom.disable();
        L.DomEvent.on(this.map._container, 'keydown', this._escape, this);
        L.DomEvent.on(this.map._container, 'dblclick', this._closePath, this);
        this._container.classList.add('leaflet-ruler-clicked');
        this._clickCount = 0;
        this._tempLine = L.featureGroup().addTo(this._allLayers);
        this._tempPoint = L.featureGroup().addTo(this._allLayers);
        this._pointLayer = L.featureGroup().addTo(this._allLayers);
        this._polylineLayer = L.featureGroup().addTo(this._allLayers);
        this._allLayers.addTo(this.map);
        this.map._container.style.cursor = 'crosshair';
        this.map.on('click', this._clicked, this);
        this.map.on('mousemove', this._moving, this);
        const rule = document.getElementById('leaflet-ruler');
        if (rule?.classList.contains('leaflet-ruler-clicked')) {
          setTimeout(() => {
            rule.click();
          }, 5);
        }
      } else {
        app.layers = [];
        this.map.doubleClickZoom.enable();
        L.DomEvent.off(this.map._container, 'keydown', this._escape, this);
        L.DomEvent.off(this.map._container, 'dblclick', this._closePath, this);
        this._container.classList.remove('leaflet-ruler-clicked');
        this.map.removeLayer(this._allLayers);
        this.map.removeLayer(this._tempLine);
        this.map.removeLayer(this._tempPoint);
        this._allLayers = L.layerGroup();
        this.map._container.style.cursor = this._defaultCursor;
        this.map.off('click', this._clicked, this);
        this.map.off('mousemove', this._moving, this);
      }
    },
    _closeMasure: function () {
      console.log(this);
    },
    _clicked: function (e) {
      if (this.clickOne) {
        this._clickedLatLong = e.latlng;
        this._clickedPoints.push(this._clickedLatLong);
        const circle = L.marker(
          this._clickedLatLong,
          this.options.circleMarker,
        ).addTo(this._pointLayer);

        circle.on('click', event => {
          this._closePath();
        });
        if (
          this._clickCount > 0 &&
          !e.latlng.equals(this._clickedPoints[this._clickedPoints.length - 2])
        ) {
          if (this._movingLatLong) {
            // eslint-disable-next-line no-restricted-syntax
            for (const key in this._polylineLayer._layers) {
              if (
                // eslint-disable-next-line no-prototype-builtins
                this._polylineLayer._layers.hasOwnProperty(key) &&
                this._polylineLayer._layers[key].options.type === 'polygon'
              ) {
                this._polylineLayer.removeLayer(this._polylineLayer._layers[key]);
              }
            }
            L.polygon(this._clickedPoints, this.options.polyStyle).addTo(
              this._polylineLayer,
            );
            L.polyline(
              [this._clickedPoints[this._clickCount - 1], this._movingLatLong],
              this.options.lineStyle,
            ).addTo(this._polylineLayer);
          }
        }
        this._clickCount++;
      }
    },
    _moving: function (e) {
      if (this._clickedLatLong) {
        // L.DomEvent.off(this._container, 'click', this._toggleMeasure, this);
        this._movingLatLong = e.latlng;
        if (this._tempLine) {
          this.map.removeLayer(this._tempLine);
          this.map.removeLayer(this._tempPoint);
        }
        this.clickOne = true;
        this._tempLine = L.featureGroup();
        this._tempPoint = L.featureGroup();
        this._tempLine.addTo(this.map);
        this._tempPoint.addTo(this.map);
        const line = L.polyline(
          [this._clickedLatLong, this._movingLatLong],
          this.options.lineStyle,
        )

        line.addTo(this._tempLine);
        line._path.classList.add('pointer-events-none')
      }
    },
    _escape: function (e) {
      if (e.keyCode === 27) {
        if (this._clickCount > 0) {
          this._closePath();
        } else {
          this._choice = true;
          this._toggleMeasure();
        }
      }
    },
    _closePath: function () {
      if (this._clickCount <= 2) return;
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this._polylineLayer._layers) {
        if (
          // eslint-disable-next-line no-prototype-builtins
          this._polylineLayer._layers.hasOwnProperty(key) &&
          this._polylineLayer._layers[key].options.type === 'polygon'
        ) {
          this._polylineLayer.removeLayer(this._polylineLayer._layers[key]);
        }
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const key in this._pointLayer._layers) {
        // eslint-disable-next-line no-prototype-builtins
        if (this._pointLayer._layers.hasOwnProperty(key)) {
          this._pointLayer.removeLayer(this._pointLayer._layers[key]);
        }
      }

      const layer = L.polygon(
        [...this._clickedPoints, this._clickedPoints[0]],
        this.options.polyStyle,
      );
      let area = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]);

      const array = [...this._clickedPoints, this._clickedPoints[0]];
      let distance = 0;
      for (let index = 0; index < array.length - 1; index += 1) {
        distance += array[index].distanceTo(array[index + 1]);
      }

      //Convert to km
      area /= sqkmConversion[Units.METERS];
      distance /= kmConversion[Units.METERS];

      layer
        .bindTooltip(
          `
        <div><b>Area : </b>${getArea(area, app.unit).toFixed(2)} ${getUnitText(app.unit)}<sup>2</sup></div>
        <div><b>Perimeter : </b>${getDistance(distance, app.unit).toFixed(2)} ${getUnitText(app.unit)}</div>
        `,
          { permanent: true, className: 'area-tooltip' },
        )
        .addTo(this._polylineLayer)
        .openTooltip();

      L.polyline(
        [this._clickedPoints[this._clickCount - 1], this._clickedPoints[0]],
        this.options.lineStyle,
      ).addTo(this._polylineLayer);

      layer.userData = {
        area,
        distance,
        type: 'area',
      };

      app.setLayers(prev => [layer, ...prev]);

      this.map.removeLayer(this._tempLine);
      this.map.removeLayer(this._tempPoint);
      if (this._clickCount <= 1) this.map.removeLayer(this._pointLayer);
      this._choice = false;
      L.DomEvent.on(this._container, 'click', this._toggleMeasure, this);
      this._toggleMeasure();
    },
  });
  L.control.area = function (options) {
    return new L.Control.Area(options);
  };
};

export default leafletArea;
