import React, { memo, useCallback } from 'react';

export interface IOption {
  value: string;
  label: string;
  versionIdx: string;
}

export interface IListProps {
  options: IOption[];
  setValue: React.Dispatch<React.SetStateAction<undefined | IOption>>;
}

const List = ({ options, setValue }: IListProps) => {
  const handleClick = useCallback(
    (value: IOption) => {
      setValue(value);
    },
    [setValue],
  );

  return (
    <ul className="select-version__option-list">
      {options.map((el) => {
        return (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <li
            onClick={() => handleClick(el)}
            key={el.value}
            className="select-version__option-value"
          >
            V {el.versionIdx} {'  '}{el.label}
          </li>
        );
      })}
    </ul>
  );
};

export default memo(List);
