interface IProps {
  obj: object;
  prefix?: string;
  result?: Record<string, boolean>;
  defaultValue: boolean;
}

const flattenObjectWithDefaultValue = ({
  obj,
  prefix = '',
  result = {},
  defaultValue,
}: IProps): Record<string, boolean> => {
  Object.entries(obj).forEach(([key, value]) => {
    const newPrefix = prefix ? `${prefix}-${key}` : key;
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      flattenObjectWithDefaultValue({
        obj: value,
        prefix: newPrefix,
        result,
        defaultValue,
      });
    } else {
      result[newPrefix as keyof typeof result] = true as boolean; // Change this line
    }
  });
  return result;
};

export default flattenObjectWithDefaultValue;
