import { createAsyncThunk } from '@reduxjs/toolkit';
import ProjectService, { IProject } from '../../../services/project.service';

export const createProject = createAsyncThunk(
  'project/createProject',
  async (params: IProject, thunkAPI) => {
    try {
      return await ProjectService.create(params);
    } catch (error: any) {
      if (error.statusCode === 400) {
        return thunkAPI.rejectWithValue('Incorrect data!');
      }

      return thunkAPI.rejectWithValue(error.message || 'Server Error!');
    }
  },
);

export const initProject = createAsyncThunk('project/initProject', async (_, thunkAPI) => {
  try {
    return await ProjectService.initialization();
  } catch (error: any) {
    if (error.statusCode === 400) {
      return thunkAPI.rejectWithValue('Incorrect data!');
    }

    return thunkAPI.rejectWithValue(error.message || 'Server Error!');
  }
});

export const addVersion = createAsyncThunk(
  'project/addVersion',
  async (params: { project_id: string }, thunkAPI) => {
    try {
      return await ProjectService.addVersion(params);
    } catch (error: any) {
      if (error.statusCode === 400) {
        return thunkAPI.rejectWithValue('Incorrect data!');
      }

      return thunkAPI.rejectWithValue(error.message || 'Server Error!');
    }
  },
);

export const remove = createAsyncThunk('project/remove', async (params: string, thunkAPI) => {
  try {
    return await ProjectService.delete(params);
  } catch (error: any) {
    if (error.statusCode === 400) {
      return thunkAPI.rejectWithValue('Incorrect data!');
    }

    return thunkAPI.rejectWithValue(error.message || 'Server Error!');
  }
});

export const setCurrent = createAsyncThunk(
  'project/setCurrent',
  async (params: string, thunkAPI) => {
    try {
      return await ProjectService.getMl(params);
    } catch (error: any) {
      if (error.statusCode === 400) {
        return thunkAPI.rejectWithValue('Incorrect data!');
      }

      return thunkAPI.rejectWithValue(error.message || 'Server Error!');
    }
  },
);

export const changeTifFolder = createAsyncThunk(
  'project/change-tif-folder',
  async (params: { data: object, versionId: string}, thunkAPI) => {
    try {
      return await ProjectService.changeTifFolder(params.data, params.versionId);
    } catch (error: any) {
      if (error.statusCode !== 201) {
        return thunkAPI.rejectWithValue('Incorrect data!');
      }

      return thunkAPI.rejectWithValue(error.message || 'Server Error!');
    }
  },
);

export const uploadGeoJsonFile = createAsyncThunk(
  'project/upload-geojson-file',
  async (params: { data: any }, thunkAPI) => {
    try {
      return await ProjectService.uploadGeoJsonFile(params.data);
    } catch (error: any) {
      if (error.statusCode === 400) {
        return thunkAPI.rejectWithValue('Incorrect data!');
      }

      return thunkAPI.rejectWithValue(error.message || 'Server Error!');
    }
  },
);

