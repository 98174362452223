import axios from '../utils/axios';

const NotificationService = {
  async delete(_id: string) {
    const response = await axios.delete(`/api/v1/notification/${_id}`);
    return response.data;
  },
  async initialization() {
    const response = await axios.get('/api/v1/notification');
    return response.data;
  },

  async changeStatus(_id: string, status: string) {
    const response = await axios.put(`/api/v1/notification/${_id}`, { status });
    return response.data;
  },
};

export default NotificationService;
