import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { initNotification, removeNotification, changeStatus } from './actions';

const initialState: {
  notifications: any;
  isLoading: boolean;
  error: string;
} = {
  notifications: [],

  isLoading: false,
  error: '',
};

export const notificationModule = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    updateNotifications: (state, action: PayloadAction<any>) => {
      if (
        action.payload[0] &&
        !state.notifications.find((el: any) => el._id === action.payload[0]?._id)
      ) {
        toast.info(action.payload[0].message);
      }

      state.notifications = action.payload;
    },
  },
  extraReducers: {
    // init
    [initNotification.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.notifications = action.payload;
      state.error = '';
      state.isLoading = false;
    },
    [initNotification.pending.type]: (state) => {
      state.isLoading = true;
    },
    [initNotification.rejected.type]: (state) => {
      state.isLoading = false;
    },
    // delete
    [removeNotification.fulfilled.type]: (state) => {
      state.error = '';
      state.isLoading = false;
    },
    [removeNotification.pending.type]: (state) => {
      state.isLoading = true;
    },
    [removeNotification.rejected.type]: (state) => {
      state.isLoading = false;
    },
    // status
    [changeStatus.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.notifications = action.payload;
      state.error = '';
      state.isLoading = false;
    },
    [changeStatus.pending.type]: (state) => {
      state.isLoading = true;
    },
    [changeStatus.rejected.type]: (state) => {
      state.isLoading = false;
    },
  },
});

const { updateNotifications } = notificationModule.actions;

export { initNotification, changeStatus, removeNotification, updateNotifications };

export default notificationModule.reducer;
