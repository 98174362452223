// @ts-nocheck
/* eslint-disable */

import { Group, Clock, Raycaster, Vector2, Vector3, TextureLoader, Scene } from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import Tween from '@tweenjs/tween.js';
import EarthModel from '../EarthModel/EarthModel';
import Environmet from '../Environment/Environment';
import Viewer from '../Viewer/Viewer';
import Satellite from '../Satellite/Satellite';
import Telescope from '../Telescope/Telescope';

import { ISatellite } from '../../../types/ISatellite';
import { ITelescope } from '../../../types/ITelescope';

import { isReady } from '../../../utils/valueState';

const maps = {
  earth: './assets/earth.jpg',
  normal: './assets/normal.jpg',
  env: './assets/space.jpg',
  clouds: './assets/clouds.jpg',
};

const models = {
  satellite: './assets/satellite.fbx',
  telescope: './assets/telescope.fbx',
};

interface MSProps {
  container: HTMLDivElement | null;
  readyCallback: () => void;
  selectSatelliteCallback: (ISatellite) => void;
  selectTelescopeCallback: (ITelescope) => void;
  auth: boolean;
  readonly telescopes: ITelescope[];
  readonly satellites: ISatellite[];
}

class MainScene {
  private container: HTMLDivElement | null;

  private readyCallback: () => void;

  private selectSatelliteCallback: (ISatellite) => void;

  private selectTelescopeCallback: (ITelescope) => void;

  private models: { satellite: any; telescope: any } = { satellite: null, telescope: null };

  private active = false;

  private telescopes: ITelescope[];

  private satellites: ISatellite[];

  private satellitesList: Satellite[] = [];

  private telescopesList: Telescope[] = [];

  private currentTime = 0;

  private currentDelta = 0;

  private offsetTime = 0;

  private speedIncrease = 1;

  private baseClock: Clock = new Clock();

  private clock: Clock = new Clock();

  private raycaster: Raycaster = new Raycaster();

  private mouse: Vector2 = new Vector2();

  private viewer: Viewer;

  private scene: Scene;

  private mainContainer: Group;

  private satContainer: Group;

  private telescopeContainer: Group;

  private earth: EarthModel;

  private env: Environmet;

  constructor(private props: any) {
    this.container = props.container;

    this.telescopes = props.telescopes;
    this.satellites = props.satellites;

    this.readyCallback = props.readyCallback;
    this.selectSatelliteCallback = props.selectSatelliteCallback;
    this.selectTelescopeCallback = props.selectTelescopeCallback;

    this.baseClock.start();
    this.clock.start();

    this.init();
  }

  private init() {
    this.viewer = new Viewer(this.container);

    this.scene = this.viewer.scene;

    this.mainContainer = new Group();
    this.mainContainer.name = 'Main Container';
    this.scene.add(this.mainContainer);

    this.setEvents();
    this.prepareScene();
    this.prepareSatellites();
    this.prepareTelescopes();
    this.update();
    this.setMode();
  }

  private prepareScene() {
    this.earth = new EarthModel();
    // this.earth.mesh.visible = false;
    this.mainContainer.add(this.earth.mainContainer);

    this.env = new Environmet();
    this.mainContainer.add(this.env.mesh);

    this.loadResources();
  }

  public stop() {
    this.viewer && this.viewer.stop();
  }

  private setMode() {
    if (this.props.auth) {
      this.viewer.camera.position.set(50, 0, 0);
      this.viewer.controls.target.set(0, 0, 0);
      this.sceneInit();

      this.showSatellites();
      this.showTelescopes();
    } else {
      this.viewer.camera.position.set(10, 11, 0);
      this.viewer.controls.target.set(0, 11, 0);
      this.viewer.controls.enabled = false;
      this.active = false;
    }
  }

  private loadResources() {
    new TextureLoader().load(maps.env, (map) => {
      this.env.setNewMap(map);

      new TextureLoader().load(maps.earth, (mapE) => {
        this.earth.setNewMap(mapE);

        this.readyCallback && this.readyCallback();
      });

      new TextureLoader().load(maps.normal, (mapN) => {
        this.earth.setNormal(mapN);
      });

      new TextureLoader().load(maps.clouds, (mapC) => {
        this.earth.setCloudMap(mapC);
      });
    });

    new FBXLoader().load(models.satellite, (object) => {
      object.scale.set(0.04, 0.04, 0.04);
      this.models.satellite = object;

      this.satellitesList.forEach((sat) => sat.setModel(object));
    });

    new FBXLoader().load(models.telescope, (object) => {
      // object.scale.set(0.003, 0.003, 0.003);
      object.scale.set(0.015, 0.015, 0.015);
      object.rotation.set(Math.PI / 2, 0, 0);
      this.models.telescope = object;

      this.telescopesList.forEach((tel) => tel.setModel(object));
    });
  }

  private prepareSatellites() {
    this.satContainer = new Group();
    this.satContainer.name = 'Sat Container';
    this.mainContainer.add(this.satContainer);
  }

  private prepareTelescopes() {
    this.telescopeContainer = new Group();
    this.telescopeContainer.name = 'Telescopes Container';
    this.earth.mainContainer.add(this.telescopeContainer);
  }

  public showSatellites(satellites: ISatellite[] = []) {
    this.satellitesList = [];

    while (this.satContainer.children[0]) {
      this.satContainer.remove(this.satContainer.children[0]);
    }

    let maxDistance = 200;
    ((isReady(this.satellites) && this.satellites) || satellites).forEach((props) => {
      const sat = new Satellite(props, this.models.satellite, this.viewer);
      this.satContainer.add(sat.mainContainer);
      this.satellitesList.push(sat);

      maxDistance < sat.getDistance() && (maxDistance = sat.getDistance());
    });

    this.viewer.controls.maxDistance = maxDistance + 50;
  }

  public updateSatellites(satellites: ISatellite[] = []) {
    satellites.forEach((newSat) => {
      const sat = this.satellitesList.find((s) => s.props._id === newSat._id);

      if (sat) {
        sat.updateParams(newSat);
      } else if (this.active) {
        const ns = new Satellite(newSat, this.models.satellite, this.viewer);
        this.satContainer.add(ns.mainContainer);
        this.satellitesList.push(ns);

        this.viewer.controls.maxDistance < ns.getDistance() &&
          (this.viewer.controls.maxDistance = ns.getDistance() + 50);
      }
    });

    this.satellitesList.forEach((oldSat) => {
      const sat = satellites.find((s) => s._id === oldSat.props._id);

      if (!sat) {
        this.satContainer.remove(oldSat.mainContainer);

        for (let i = this.satellitesList.length; i >= 0; i--) {
          if (oldSat === this.satellitesList[i]) {
            this.satellitesList.splice(i, 1);
          }
        }
      }
    });
  }

  public showTelescopes(telescopes: ITelescope[] = []) {
    this.telescopesList = [];
    ((isReady(this.telescopes) && this.telescopes) || telescopes).forEach((telescope) => {
      const tel = new Telescope(telescope, this.models.telescope);

      this.telescopeContainer.add(tel.mainContainer);
      this.telescopesList.push(tel);
    });
  }

  private removeObjects() {
    for (let i = this.satellitesList.length - 1; i >= 0; i--) {
      const sat = this.satellitesList[i];
      this.satContainer.remove(sat.mainContainer);
      this.satellitesList.splice(i, 1);
    }

    for (let i = this.telescopesList.length - 1; i >= 0; i--) {
      const tel = this.telescopesList[i];
      this.telescopeContainer.remove(tel.mainContainer);
      this.telescopesList.splice(i, 1);
    }
  }

  private update = () => {
    this.currentDelta = this.clock.getDelta() * this.speedIncrease;
    this.currentTime += this.currentDelta;

    this.moveSatellites();
    this.moveEarth();

    Tween.update();

    window.requestAnimationFrame(this.update);
  };

  private moveSatellites() {
    this.satellitesList.forEach((sat) => {
      sat.updatePosition({ delta: this.currentDelta, offset: this.offsetTime });
    });
  }

  private moveEarth() {
    this.earth.rotate(this.currentTime + this.offsetTime);
  }

  public selectSatellite(satelite) {
    this.satellitesList.forEach((sat) => {
      sat.props._id === satelite ? sat.select() : sat.deselect();
    });
  }

  public deselectSatellite() {
    this.satellitesList.forEach((sat) => {
      sat.deselect();
    });
  }

  public selectTelescope(telescope) {
    this.telescopesList.forEach((tel) => {
      tel.telescope._id === telescope._id ? tel.select() : tel.deselect();
    });
  }

  public deselectTelescope() {
    this.telescopesList.forEach((tel) => {
      tel.deselect();
    });
  }

  public setTimeOffset(offset: number) {
    this.offsetTime = offset * 60;
  }

  public setSpeedIncrease(speed: number) {
    this.speedIncrease = speed;
  }

  public pause() {
    this.baseClock.stop();
    this.clock.stop();
  }

  public resume() {
    this.baseClock.start();
    this.clock.start();
  }

  public resetClock() {
    this.currentTime = this.baseClock.getElapsedTime();
    this.satellitesList.forEach((sat) => {
      sat.currentTime = this.currentTime;
    });
  }
  //

  public startAnimation = () => {
    const coords = { alpha: 0 };
    const startPosTarget = new Vector3(0, 11, 0);
    const newPosTarget = new Vector3(0, 0, 0);
    const startPos = new Vector3(10, 11, 0);
    const newPos = new Vector3(50, 0, 0);

    this.showSatellites();
    this.showTelescopes();

    const tween = new Tween.Tween(coords)
      .to({ alpha: 1 }, 3000)
      .easing(Tween.Easing.Quadratic.Out)
      .onUpdate((delta) => {
        this.viewer.camera.position.copy(startPos.clone().lerp(newPos, delta.alpha));
        this.viewer.controls.target.copy(startPosTarget.clone().lerp(newPosTarget, delta.alpha));
      })
      .onComplete(() => {
        this.sceneInit();
        tween.stop();
      })
      .start();
  };

  public resetAnimation = () => {
    const coords = { alpha: 0 };
    const startPosTarget = new Vector3(0, 0, 0);
    const newPosTarget = new Vector3(0, 11, 0);
    const startPos = this.viewer.camera.position.clone();
    const newPos = new Vector3(10, 11, 0);

    this.viewer.controls.enabled = false;
    this.active = false;
    this.viewer.controls.minDistance = 0;

    this.removeObjects();

    const tween = new Tween.Tween(coords)
      .to({ alpha: 1 }, 3000)
      .easing(Tween.Easing.Quadratic.Out)
      .onUpdate((delta) => {
        this.viewer.camera.position.copy(startPos.clone().lerp(newPos, delta.alpha));
        this.viewer.controls.target.copy(startPosTarget.clone().lerp(newPosTarget, delta.alpha));
      })
      .onComplete(() => {
        tween.stop();
      })
      .start();
  };

  private sceneInit() {
    this.viewer.controls.enabled = true;
    this.active = true;
    this.viewer.controls.minDistance = 12;
  }

  //
  private setEvents() {
    this.container && this.container.addEventListener('mousemove', this.onMouseMove, false);
    this.container && this.container.addEventListener('mousedown', this.onMouseDown, false);
  }

  private onMouseMove = (event) => {
    this.satellitesList.forEach((sat) => {
      sat.reset();
    });

    this.telescopesList.forEach((tel) => {
      tel.reset();
    });

    if (this.container) {
      const canvasBounding = this.viewer.renderer.domElement.getBoundingClientRect();
      this.mouse.x = ( (event.clientX - canvasBounding.left) / canvasBounding.width ) * 2 - 1;
      this.mouse.y = -( (event.clientY - canvasBounding.top) / canvasBounding.height ) * 2 + 1;
    }

    this.raycaster.setFromCamera(this.mouse, this.viewer.camera);

    const intersects = this.raycaster.intersectObjects(
      [...this.satContainer.children, ...this.telescopeContainer.children],
      true,
    );

    intersects.forEach((item) => {
      item.object.userData.parent.hover();
    });
  };

  private onMouseDown = (event) => {
    if (this.container) {
      const canvasBounding = this.viewer.renderer.domElement.getBoundingClientRect();
      this.mouse.x = ( (event.clientX - canvasBounding.left) / canvasBounding.width ) * 2 - 1;
      this.mouse.y = -( (event.clientY - canvasBounding.top) / canvasBounding.height ) * 2 + 1;
    }

    this.raycaster.setFromCamera(this.mouse, this.viewer.camera);

    const intSats = this.raycaster.intersectObjects(this.satContainer.children, true);
    intSats.length && this.selectSatelliteCallback(intSats[0].object.userData.parent.props);

    const intTels = this.raycaster.intersectObjects(this.telescopeContainer.children, true);
    intTels.length && this.selectTelescopeCallback(intTels[0].object.userData.parent.telescope);
  };
}

export default MainScene;
