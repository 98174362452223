import React, { useState, useEffect, useRef } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import moment from 'moment';
import MainScene from './MainScene/MainScene';

import styles from './PlanetEarth.module.scss';
import { useAuth, useCommon } from '../../hooks/redux';
import Icon from '../ui/Icon';
import Slider from './Slider/Slider';
import { ISatellite } from '../../types/ISatellite';
import { IEarth } from '../../types/IEarth';
import SatelliteModal from './SatelliteModal/SatelliteModal';
import StationModal from './StationModal/StationModal';

let mainScene: MainScene | null = null;

const satellite = [
  {
    _id: '0',
    name: '26985',
    telescope: 'abq01',
    a: 42167.171164, //km
    e: 0.000201,
    i: 0.00001, //deg
    RA: 4.198136, // deg
    omega: 2.765076,
    rho0: -0.331347,
    period: 86173.209348,
    Me: 5.951969,
    E: 5.951903,
    date: [2015, 4.0, 23.0, 18.0],
    timeDeviation: 0,
    speedIncrease: 1,
    pause: false,
    prevSpeedIncrease: undefined,
  },
  {
    _id: '1',
    name: '28790',
    telescope: 'abq01',
    a: 42165.060621, //km
    e: 0.000249,
    i: 0.000372, //deg
    RA: 5.70585, //deg
    omega: 2.024041,
    rho0: -2.122892,
    period: 86166.739742,
    Me: 4.160718,
    E: 4.160506,
    date: [2015, 6.0, 12.0, 12.38443968],
    timeDeviation: 0,
    speedIncrease: 1,
    pause: false,
    prevSpeedIncrease: undefined,
  },
  {
    _id: '2',
    name: '41866',
    telescope: 'abq01',
    a: 42061.666115, //km
    e: 0.000133,
    i: 0.000312, //deg
    RA: 1.637344, //deg
    omega: 4.675835,
    rho0: -0.029993,
    period: 85849.995138,
    Me: 6.253201,
    E: 6.253197,
    date: [2017, 12.0, 10.0, 23.7285108],
    timeDeviation: 0,
    speedIncrease: 1,
    pause: false,
    prevSpeedIncrease: undefined,
  },
  {
    _id: '3',
    name: '35491',
    telescope: 'rme02',
    a: 42164.454805, //km
    e: 0.001056,
    i: 0.004857, //deg
    RA: 4.795535, //deg
    omega: 5.404143,
    rho0: 2.367207,
    period: 86164.88272,
    Me: 2.365729,
    E: 2.366468,
    date: [2017, 10.0, 23.0, 4.8301716],
    timeDeviation: 0,
    speedIncrease: 1,
    pause: false,
    prevSpeedIncrease: undefined,
  },
  {
    _id: '4',
    name: '37834',
    telescope: 'rme02',
    a: 42164.564424, //km
    e: 0.000191,
    i: 0.000021, //deg
    RA: 3.395144, //deg
    omega: 3.096031,
    rho0: 2.650805,
    period: 86165.218735,
    Me: 2.650625,
    E: 2.650715,
    date: [2015, 5.0, 1.0, 8.32316472],
    timeDeviation: 0,
    speedIncrease: 1,
    pause: false,
    prevSpeedIncrease: undefined,
  },
];
const telescope = [
  {
    name: 'abq01',
    lat: 34.9631,
    lng: -106.49722222,
    elevation: 1728,
    cameraType: 'Dioptrics',
    information: 'This is abq01 telescope and some info is provided here',
  },
  {
    name: 'rme02',
    lat: 20.74652222,
    lng: -156.43194444,
    elevation: 84,
    cameraType: 'Catoptrics',
    information: 'This is rme02 telescope and some info is provided here',
  },
];

const satelliteObj = {
  _id: undefined,
  a: undefined,
  e: undefined,
  i: undefined,
  omega: undefined,
  RA: undefined,
  Me: undefined,
  name: undefined,
  timeDeviation: undefined,
  speedIncrease: undefined,
  pause: undefined,
};

const PlanetEarth = () => {
  const { profile } = useAuth();
  const { viewType } = useCommon();
  const isVisiblePlanet = viewType === 'planet';
  const [bgDone, setBgDone] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [showSatelliteModal, setShowSatelliteModal] = useState(false);
  const [showStationModal, setShowStationModal] = useState(false);
  const [stationInfo, setStationInfo] = useState({});
  const [satelliteInfo, setSatelliteInfo] = useState(satelliteObj);
  const [newSatellite, setNewSatellite] = useState(satellite);
  const [earthTime, setEarthTime] = useState('');
  const [earthSpeed, setEarthSpeed] = useState(1);
  const [prevEarthSpeed, setPrevEarthSpeed] = useState(null);

  let iconName;
  let iconSize;

  useEffect(() => {
    const MSProps = {
      container: containerRef.current,
      auth: !!profile,
      telescopes: telescope,
      satellites: satellite,
      readyCallback: () => setBgDone(true),
      selectSatelliteCallback: (prop: any) => {
        setShowSatelliteModal(true);
        setSatelliteInfo(prop);
        mainScene?.selectSatellite(prop._id);
      },
      selectTelescopeCallback: (prop: any) => {
        setShowStationModal(true);
        setStationInfo(prop);
      },
    };
    mainScene = new MainScene(MSProps);

    return () => {
      if (mainScene) {
        mainScene.stop();
      }
    };
  }, [profile]);

  useEffect(() => {
    setShowStationModal(false);
  }, [viewType]);

  useEffect(() => {
    const MSProps = {
      selectSatelliteCallback: (prop: any) => {
        setSatelliteInfo(prop);
      },
    };
  }, []);

  const showModal = () => {
    setSatelliteInfo(satelliteObj);
    setShowSatelliteModal(false);
    mainScene?.deselectSatellite();
  };

  useEffect(() => {
    mainScene?.updateSatellites(newSatellite);
    mainScene?.setSpeedIncrease(earthSpeed);
  }, [newSatellite, earthSpeed]);

  const getEarthTime = () =>
    moment
      .utc(false || undefined)
      .add(1, 'minutes')
      .format('MMM DD YYYY h:mm:ss A');

  useEffect(() => {
    if (earthSpeed !== 0) {
      const intervalId = setInterval(() => {
        setEarthTime(getEarthTime());
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [earthSpeed]);

  const updateSpeedIncrease = (number: number) => {
    if (showSatelliteModal) {
      setNewSatellite((prevState) =>
        prevState.map((sat) =>
          sat._id === satelliteInfo._id ? { ...sat, speedIncrease: number } : sat,
        ),
      );
    } else {
      setEarthSpeed(number);
    }
  };

  const onSpeedDecrease = () => {
    setNewSatellite((prevState) =>
      prevState.map((sat) =>
        sat._id === satelliteInfo._id
          ? {
              ...sat,
              speedIncrease: sat.speedIncrease - 5 >= -200 ? sat.speedIncrease - 5 : -200,
            }
          : sat,
      ),
    );
  };

  const onSpeedIncrease = () => {
    setNewSatellite((prevState) =>
      prevState.map((sat) =>
        sat._id === satelliteInfo._id
          ? {
              ...sat,
              speedIncrease: sat.speedIncrease + 5 <= 200 ? sat.speedIncrease + 5 : 200,
            }
          : sat,
      ),
    );
  };

  const handleClick = () => {
    if (showSatelliteModal) {
      // @ts-ignore
      setNewSatellite((prevState) =>
        prevState.map((sat) =>
          sat._id === satelliteInfo._id
            ? {
                ...sat,
                pause: !sat.pause,
                prevSpeedIncrease: !sat.pause ? sat.speedIncrease : sat.prevSpeedIncrease,
                speedIncrease: !sat.pause ? 0 : sat.prevSpeedIncrease,
              }
            : sat,
        ),
      );
    } else if (earthSpeed === 0) {
      // @ts-ignore
      setEarthSpeed(prevEarthSpeed);
    } else {
      // @ts-ignore
      setPrevEarthSpeed(earthSpeed);
      setEarthSpeed(0);
    }
  };

  const onClickReset = () => {
    setNewSatellite((prevState) =>
      prevState.map((sat) => (sat._id === satelliteInfo._id ? { ...sat, speedIncrease: 1 } : sat)),
    );
  };

  const getSelectedSatellite = () => {
    return newSatellite.find((sat) => sat._id === satelliteInfo._id);
  };

  const getSpeedIncrease = () => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const selectedSatellite = newSatellite.find((satellite) => satellite._id === satelliteInfo._id);
    if (selectedSatellite) {
      return selectedSatellite.speedIncrease;
    }
    return earthSpeed;
  };

  const isSatelliteSelected = !!getSelectedSatellite();
  const isSatellitePaused = getSelectedSatellite()?.pause;
  const isEarthPaused = earthSpeed === 0;

  if (isSatelliteSelected) {
    iconName = isSatellitePaused ? 'play-icon' : 'pause-icon';
    iconSize = isSatellitePaused ? 5 : 6;
  } else {
    iconName = isEarthPaused ? 'play-icon' : 'pause-icon';
    iconSize = isEarthPaused ? 5 : 6;
  }
  // @ts-ignore
  return (
    <div className={styles.earthWrapper}>
      <div
        className={`${styles.backgroundScene} ${bgDone ? styles.done : ''} ${
          !isVisiblePlanet ? styles.hidden : ''
        }`}
      />
      <div
        className={`${styles.peSceneContainer} ${!isVisiblePlanet ? styles.hidden : ''}`}
        ref={containerRef}
      />
      {showSatelliteModal && viewType !== 'map' ? (
        <SatelliteModal satelliteInfo={satelliteInfo} setShowSatelliteModal={showModal} />
      ) : null}
      {showStationModal && viewType !== 'map' && (
        <StationModal
          satellites={satellite}
          stationInfo={stationInfo}
          setShowStationModal={setShowStationModal}
        />
      )}
      {!showStationModal && (
        <div
          className={
            viewType === 'map'
              ? `${styles.timeController} ${styles.topModal}`
              : styles.timeController
          }
        >
          <div className={styles.header}>
            {viewType !== 'map' && <p className={styles.headerSpeed}>x{getSpeedIncrease()}</p>}
            <p className={styles.headerDate}>{earthTime} UTC</p>
          </div>
          {viewType !== 'map' &&
            <>
              <div className={styles.timeSlider}>
                <div className={styles.timeSliderHeader}>
                  <p className={styles.timeSliderHeaderVal}>
                    -200
                    <span>x</span>
                  </p>
                  <p className={styles.timeSliderHeaderVal}>
                    200
                    <span>x</span>
                  </p>
                </div>
                <div className={styles.timeSliderWrap}>
                  <div className={styles.changeSpeedBtn} onClick={onSpeedDecrease}>
                    <Icon name="left-arrow" color="primary" />
                  </div>
                  <Slider setSpeed={updateSpeedIncrease} getSpeedIncrease={getSpeedIncrease()} />
                  <div className={styles.changeSpeedBtn} onClick={onSpeedIncrease}>
                    <Icon name="right-arrow" color="primary" />
                  </div>
                </div>
              </div>
              <div className={styles.btn} onClick={handleClick}>
                <div className={styles.icon}>
                  <Icon
                    name={iconName}
                    color="primary"
                    size={iconSize}/>
                </div>
              </div>
              {/* eslint-disable-next-line react/button-has-type */}
              <button
                className={`${styles.resetBtn} ${showSatelliteModal ? styles.satellite : ''}`}
                onClick={onClickReset}
              >
                Reset
              </button>
            </>
          }
        </div>
      )}
    </div>
  );
};

export default PlanetEarth;
