import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createTelescope, initTelescope } from './actions';
import { ITelescope } from '../../../services/telescope.service';

const initialState: {
  telescopes: ITelescope[];
  isLoading: boolean;
  error: string;
} = {
  telescopes: [],

  isLoading: false,
  error: '',
};

export const telescopeModule = createSlice({
  name: 'telescope',
  initialState,
  reducers: {
    updateTelescope: (state, action) => {
      if (state.telescopes) {
        state.telescopes = action.payload;
      }
    },
  },
  extraReducers: {
    // init
    [initTelescope.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.telescopes = action.payload;
      state.error = '';
      state.isLoading = false;
    },
    [initTelescope.pending.type]: (state) => {
      state.isLoading = true;
    },
    [initTelescope.rejected.type]: (state) => {
      state.isLoading = false;
    },
    // create
    [createTelescope.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.telescopes = action.payload;
      state.error = '';
      state.isLoading = false;
    },
    [createTelescope.pending.type]: (state) => {
      state.isLoading = true;
    },
    [createTelescope.rejected.type]: (state) => {
      state.isLoading = false;
    },
  },
});

const { updateTelescope } = telescopeModule.actions;

export { createTelescope, initTelescope, updateTelescope };

export default telescopeModule.reducer;
