import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAuth = () => useAppSelector((state) => state.user);
export const useCommon = () => useAppSelector((state) => state.common);
export const useSatellite = () => useAppSelector((state) => state.satellite);
export const useTelescope = () => useAppSelector((state) => state.telescope);
export const useProject = () => useAppSelector((state) => state.project);
export const useNotification = () => useAppSelector((state) => state.notification);
