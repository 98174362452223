import React, { FC } from 'react';
import cn from 'classnames';
import Icon from '../../ui/Icon';
import './style.scss';
import { useAppDispatch, useCommon } from '../../../hooks/redux';
import { updateActiveModal, updateViewType } from '../../../store/modules/Common';

const NavBarSwitch: FC = () => {
  const dispatch = useAppDispatch();
  const { viewType } = useCommon();

  const handleChange = () => {
    dispatch(updateViewType(viewType === 'planet' ? 'map' : 'planet'));
    dispatch(updateActiveModal(null));
  };

  return (
    <label className="nav-bar-switch">
      <input
        type="checkbox"
        hidden
        onChange={handleChange}
        defaultChecked={viewType === 'planet'}
      />
      <span>
        <b className={cn({ active: viewType === 'planet' })}>
          <Icon name="satellite" />
        </b>
        <b className={cn({ active: viewType === 'map' })}>
          <Icon name="telescope" />
        </b>
      </span>
    </label>
  );
};

export default NavBarSwitch;
