import { EBuildingStatus, ITileProperties } from '../../../../services/tiles.service';
import { EVehicleSuperCategory, IVehicleProperties } from '../../../../types/IVehicle';
import { getBuildingColor, vehicleColors } from '../../../../utils/shapeColors';

const validStatus = (status: string) => {
  if (status === 'No After Imagery to Process') {
    return true;
  }
  if (status === 'No After Footprint Found') {
    return true;
  }
  return Object.values(EBuildingStatus).includes(status as unknown as EBuildingStatus);
};

interface IOptionsProps {
  layerName: string;
  currentShape: any;
  activeShapes: any;
  selectedPolys: ITileProperties[] | null;
  tile: any;
  canInteractive: boolean;
}

export const buildingOptions = ({
  layerName,
  currentShape,
  activeShapes,
  selectedPolys,
  tile,
  canInteractive,
}: IOptionsProps) => {
  return {
    // Specify zoom range in which tiles are loaded. Tiles will be
    // rendered from the same data for Zoom levels outside the range.
    minDetailZoom: 14, // default undefined
    maxDetailZoom: 18, // default undefined
    isVectorGrid: true,
    minNativeZoom: 14,
    // Either a single style object for all features on all layers or a
    // function that receives the vector-tile feature, the layer name
    // and the zoom level and returns the appropriate style options.

    // This works like the same option for `Leaflet.VectorGrid`.
    // Ignored if style is specified.
    vectorTileLayerStyles: {
      [layerName || '']: (properties: any, _zoom: number) => {
        const formattedKey: any = {};
        Object.keys(properties).forEach((key) => {
          const newKey = key.replaceAll(' ', '_');
          formattedKey[newKey] = properties[key];
        });
        const buildingStatus = validStatus(formattedKey.building_status_after)
          ? formattedKey.building_status_after
          : formattedKey.building_status_before;
        if (
          activeShapes[buildingStatus] &&
          formattedKey.ogc_fid !== tile?.properties?.ogc_fid &&
          formattedKey.ogc_fid !== currentShape?.properties?.ogc_fid
        ) {
          if (
            selectedPolys &&
            selectedPolys?.findIndex((el) => el.ogc_fid === formattedKey.ogc_fid) !== -1
          ) {
            return {
              fillColor: 'rgb(0,0,0,0)',
              weight: 4,
              color: '#00CEFF',
              fillOpacity: 0.8,
              fill: true,
            };
          }
          return {
            fillColor: 'rgb(0,0,0,0)',
            weight: 4,
            color: getBuildingColor(buildingStatus),
            fillOpacity: 0.8,
            fill: true,
          };
        }
        return {
          weight: 0,
          fillColor: 'transparent',
          color: 'transparent',
          fillOpacity: 0.8,
          fill: false,
          pointerEvent: 'none',
        };
      },
    },
    getFeatureId: (f: any) => {
      return f.properties.ogc_fid;
    },
    interactive: canInteractive,
  };
};

export const vehicleOptions = ({
  layerName,
  currentShape,
  activeShapes,
  selectedPolys,
  tile,
  canInteractive,
}: IOptionsProps) => {
  return {
    // Specify zoom range in which tiles are loaded. Tiles will be
    // rendered from the same data for Zoom levels outside the range.
    minDetailZoom: 14, // default undefined
    maxDetailZoom: 18, // default undefined
    isVectorGrid: true,
    minNativeZoom: 14,
    // Either a single style object for all features on all layers or a
    // function that receives the vector-tile feature, the layer name
    // and the zoom level and returns the appropriate style options.

    // This works like the same option for `Leaflet.VectorGrid`.
    // Ignored if style is specified.
    vectorTileLayerStyles: {
      [layerName || '']: (properties: IVehicleProperties, _zoom: number) => {
        const vehicleSubCat = properties.categories_subcategory;
        const vehicleSupCat = properties.categories_supercategory;
        const vehicleCat = properties.categories_category;
        if (
          activeShapes[`${vehicleSupCat}-${vehicleCat}-${vehicleSubCat}`] &&
          properties.ogc_fid !== tile?.properties?.ogc_fid &&
          properties.ogc_fid !== currentShape?.properties?.ogc_fid
        ) {
          if (
            selectedPolys &&
            selectedPolys?.findIndex((el) => el.ogc_fid === properties.ogc_fid) !== -1
          ) {
            return {
              fillColor: 'rgb(0,0,0,0)',
              weight: 4,
              color: '#00CEFF',
              fillOpacity: 0.8,
              fill: true,
            };
          }

          return {
            // @ts-expect-error
            fillColor: vehicleColors[vehicleSupCat as keyof typeof EVehicleSuperCategory][vehicleCat][vehicleSubCat],
            weight: 4,
            // @ts-expect-error
            color: vehicleColors[vehicleSupCat as keyof typeof EVehicleSuperCategory][vehicleCat][vehicleSubCat],
            fillOpacity: 0.05,
            fill: true,
          };
        }
        return {
          weight: 0,
          fillColor: 'transparent',
          color: 'transparent',
          fillOpacity: 0.8,
          fill: false,
          pointerEvent: 'none',
        };
      },
    },
    getFeatureId: (f: any) => {
      return f.properties.ogc_fid;
    },
    interactive: canInteractive,
  };
};
