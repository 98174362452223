import React, { FC, useState } from 'react';
import { Outlet, useNavigate } from 'react-router';
import './style.scss';
import logo from '../../../assets/images/logo.svg';
import NavBar from './Navbar';
import PlanetEarth from '../PlanetEarth/PlanetEarth';
import { useAppDispatch, useCommon } from '../../hooks/redux';
import ProjectDataView from '../ProjectDataView';
import ConfirmationModal from '../ConfirmationModal';
import { ActiveModal, ECompareStep } from '../../types/ICommon';
import { setCompareStep, setMLDrawingMode, updateActiveModal } from '../../store/modules/Common';

const ProjectLayout: FC = () => {
  const { activeModal, viewType } = useCommon();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [confirm, setConfirm] = React.useState(false);
  const confirmationRef = React.useRef<HTMLDivElement>(null);

  const gotoMainPage = () => navigate('/');

  const handleSetModal = (name: ActiveModal) => () => {
    if (name === activeModal) {
      dispatch(updateActiveModal(null));
    } else {
      dispatch(updateActiveModal(name));
    }

    gotoMainPage();
  };

  const handleConfirm = () => {
    gotoMainPage();
    dispatch(setCompareStep(ECompareStep.close));
    dispatch(setMLDrawingMode(null));
    handleSetModal('list')
  }

  return (
    <div className="main-layout">
      <div className="main-layout__header">
        <img src={logo} alt="Infralytiks" />
      </div>
      <div className="main-layout__content">
        <NavBar setConfirm={setConfirm} />
        <div className="main-layout__content--wrapper">
          <div className="main-layout__content--modals">
            <Outlet />
          </div>
          <div className="main-layout__content--view">
            {viewType === 'planet' && <PlanetEarth />}
            <ProjectDataView />
            <div id="project-object-portal" />
          </div>
        </div>
        {confirm && (
          <ConfirmationModal
            ref={confirmationRef}
            title="Leave this project"
            text="Are you sure you want to leave this project?"
            onCancel={() => setConfirm(false)}
            onConfirm={handleConfirm}
          />
        )}
      </div>
    </div>
  );
};

export default ProjectLayout;
