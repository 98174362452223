import React, { FC, useState } from 'react';
import { Outlet } from 'react-router';
import './style.scss';
import logo from '../../../assets/images/logo.svg';
import NavBar from '../NavBar';
import PlanetEarth from '../PlanetEarth/PlanetEarth';
import MapView from '../Map';
import { useCommon } from '../../hooks/redux';

const renderComponent = (viewType: string) => {
  switch (viewType) {
    case 'planet':
      return <PlanetEarth />;
    case 'map':
      return <MapView />
    default:
      return null;
  }
}

const Layout: FC = () => {
  const { viewType } = useCommon();

  return (
    <div className="main-layout">
      <div className="main-layout__header">
        <img src={logo} alt="Infralytiks" />
      </div>
      <div className="main-layout__content">
        <NavBar />
        <div className="main-layout__content--wrapper">
          <div className="main-layout__content--modals">
            <Outlet />
          </div>
          <div className="main-layout__content--view">
            {/*{viewType === 'planet' && <PlanetEarth />}*/}
            <PlanetEarth />
            <MapView />
            <div id='object-portal' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
