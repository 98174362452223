import axios from '../utils/axios';

export interface ITelescope {
  _id?: string;
  name: string;
  lat: number;
  lng: number;
  elevation: number;
  cameraType: string;
  information: string;
}

const TelescopeService = {
  async create(body: ITelescope) {
    const response = await axios.post('/api/v1/telescope', body);
    return response.data;
  },

  async initialization() {
    const response = await axios.get('/api/v1/telescope');
    return response.data;
  },
};

export default TelescopeService;
