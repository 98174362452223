import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Draggable from 'react-draggable'
import helpers from '../../../../../helpers/map';
import './style.scss';

const buildingStatus = [
  {
    label: 'Destroyed',
    value: 'destroyed'
  },
  {
    label: 'Damaged',
    value: 'damaged'
  },
  {
    label: 'Undamaged',
    value: 'undamaged'
  },
  {
    label: 'Others',
    value: 'others'
  }
]

export interface ICreatePolygonFormProps {
  layer: any;
  pre_image_id?: string;
  post_image_id?: string;
  before_date?: string;
  after_date?: string;
  onCancel: () => void;
  onSave: (data: object) => void;
  type_polygon?: string;
}

const formatDate = (date: string) => {
  if(!date) return date;
  // date like '20231117'
  const defaultDate = new Date(parseInt(date.substring(0, 4), 10), parseInt(date.substring(4, 6), 10) - 1, parseInt(date.substring(6, 8), 10) + 1);
  if (!Number.isNaN(defaultDate.getTime())) {
    return defaultDate.toISOString().split('T')[0];
  }
  return date;
}

const CreatePolygonForm = ({ pre_image_id, post_image_id, before_date, after_date, layer, onCancel, onSave, type_polygon }: ICreatePolygonFormProps) => {
  const [disableDrag, setDisableDrag] = useState(false);

  const schema = yup.object().shape({
    assessment_source: yup.string().required('Assessment source is required!'),
    building_status_before: yup.string().required('Building status before is required!'),
  });

  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      after_date: after_date ? formatDate(after_date) : after_date,
      assessment_source: "Human-Derived",
      before_date: before_date ? formatDate(before_date) : before_date,
      building_id: null,
      building_status_after: null,
      building_status_before: null,
      post_image_id,
      pre_image_id,
      type_polygon
    }
  });

  return (
    <Draggable disabled={disableDrag}>
      <form onSubmit={handleSubmit(onSave)}>
        <div className='tile-info-wrapper'>
          <div className='tile-info-table'>
            <div className='tile-info-row'>
              <div className="tile-info-col">
                <span>Assessment source</span>
              </div>
              <div className="tile-info-col tile-info-propterty">
                <input
                  {...register("assessment_source", { required: true, })}
                  placeholder="Assessment source"
                  onBlur={(e) => {
                    setDisableDrag(false)
                  }}
                  onFocus={(e) => {
                    setDisableDrag(true)
                  }}
                  onMouseDown={(e) => {
                    setDisableDrag(true)
                  }}
                />
                <p>{errors.assessment_source?.message?.toString()}</p>
              </div>
            </div>
            <div className='tile-info-row'>
              <div className="tile-info-col">
                <span>Before date</span>
              </div>
              <div className="tile-info-col tile-info-propterty">
                <input
                  {...register("before_date", { required: true })}
                  placeholder="Before date"
                  onBlur={(e) => {
                    setDisableDrag(false)
                  }}
                  onFocus={(e) => {
                    setDisableDrag(true)
                  }}
                  onMouseDown={(e) => {
                    setDisableDrag(true)
                  }}
                  type='date'
                />
                <p>{errors.before_date?.message?.toString()}</p>
              </div>
            </div>
            <div className='tile-info-row'>
              <div className="tile-info-col">
                <span>After date</span>
              </div>
              <div className="tile-info-col tile-info-propterty">
                <input
                  {...register("after_date", { required: true })}
                  placeholder="After date"
                  onBlur={(e) => {
                    setDisableDrag(false)
                  }}
                  onFocus={(e) => {
                    setDisableDrag(true)
                  }}
                  onMouseDown={(e) => {
                    setDisableDrag(true)
                  }}
                  type='date'
                />
                <p>{errors.after_date?.message?.toString()}</p>
              </div>
            </div>
            <div className='tile-info-row'>
              <div className="tile-info-col">
                <span>Building status before</span>
              </div>
              <div className="tile-info-col tile-info-propterty">
                <select {...register("building_status_before")} className='tile-info-select'>
                  {
                    buildingStatus.map(status => <option value={status.value}>{status.label}</option>)
                  }
                </select>
                <p>{errors.building_status_before?.message?.toString()}</p>
              </div>
            </div>

            <div className='tile-info-row'>
              <div className="tile-info-col">
                <span>Building status after</span>
              </div>
              <div className="tile-info-col tile-info-propterty">
                <select {...register("building_status_after")} className='tile-info-select'>
                  {
                    buildingStatus.map(status => <option value={status.value}>{status.label}</option>)
                  }
                </select>
                <p>{errors.building_status_after?.message?.toString()}</p>
              </div>
            </div>

            <div className='tile-info-row'>
              <div className="tile-info-col">
                <span>Footprint area</span>
              </div>
              <div className="tile-info-col tile-info-propterty">
                { helpers.calculateArea(layer.toGeoJSON().geometry.coordinates) }
              </div>
            </div>

            <div className='tile-info-row'>
              <div className="tile-info-col">
                <span>Post image ID</span>
              </div>
              <div className="tile-info-col tile-info-propterty">
                <input
                  {...register("post_image_id", { required: true })}
                  placeholder="Post image ID"
                  onBlur={(e) => {
                    setDisableDrag(false)
                  }}
                  onFocus={(e) => {
                    setDisableDrag(true)
                  }}
                  onMouseDown={(e) => {
                    setDisableDrag(true)
                  }}
                />
                <p>{errors.post_image_id?.message?.toString()}</p>
              </div>
            </div>

            <div className='tile-info-row'>
              <div className="tile-info-col">
                <span>Pre image ID</span>
              </div>
              <div className="tile-info-col tile-info-propterty">
                <input
                  {...register("pre_image_id", { required: true })}
                  placeholder="Pre image ID"
                  onBlur={(e) => {
                    setDisableDrag(false)
                  }}
                  onFocus={(e) => {
                    setDisableDrag(true)
                  }}
                  onMouseDown={(e) => {
                    setDisableDrag(true)
                  }}
                />
                <p>{errors.pre_image_id?.message?.toString()}</p>
              </div>
            </div>
          </div>

          <div className="tile-info-footer">
            <button type='button' onClick={onCancel}>Cancel</button>
            <button type='submit' className='submit'>Save</button>
          </div>
        </div>
      </form>
    </Draggable>
  )
}

export default CreatePolygonForm