import axios from '../utils/axios';

export interface ISatellite {
  _id?: string;
  name: string;
  a: number;
  e: number;
  i: number;
  omega: number;
  RA: number;
  Me: number;
}

const SatelliteService = {
  async create(body: ISatellite) {
    const response = await axios.post('/api/v1/satellite', body);

    return response.data;
  },

  async initialization() {
    const response = await axios.get('/api/v1/satellite');
    return response.data;
  },
};

export default SatelliteService;
