import { createAsyncThunk } from '@reduxjs/toolkit';
import NotificationService from '../../../services/notification.service';

export const removeNotification = createAsyncThunk(
  'notification/removeNotification',
  async (params: string, thunkAPI) => {
    try {
      return await NotificationService.delete(params);
    } catch (error: any) {
      if (error.statusCode === 400) {
        return thunkAPI.rejectWithValue('Incorrect data!');
      }

      return thunkAPI.rejectWithValue(error.message || 'Server Error!');
    }
  },
);

export const initNotification = createAsyncThunk(
  'notification/initNotification',
  async (_, thunkAPI) => {
    try {
      return await NotificationService.initialization();
    } catch (error: any) {
      if (error.statusCode === 400) {
        return thunkAPI.rejectWithValue('Incorrect data!');
      }

      return thunkAPI.rejectWithValue(error.message || 'Server Error!');
    }
  },
);

export const changeStatus = createAsyncThunk(
  'notification/changeStatus',
  async (params: { _id: string; status: string }, thunkAPI) => {
    try {
      return await NotificationService.changeStatus(params._id, params.status);
    } catch (error: any) {
      if (error.statusCode === 400) {
        return thunkAPI.rejectWithValue('Incorrect data!');
      }

      return thunkAPI.rejectWithValue(error.message || 'Server Error!');
    }
  },
);
