/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react'
import './style.scss';
import { GeoJSONGeometry } from 'wellknown';
import { polygon as turfPolygon, area as turfArea } from '@turf/turf';
import { toast } from 'react-toastify';
import Draggable from 'react-draggable';
import config from '../../../../../../config';

import Icon from '../../../../ui/Icon';
import DropDownSelect from './DropDownSelect';
import axiosInstance from '../../../../../utils/axios';
import { getUpdateGeoQuery, update } from '../../../../../helpers/vectorTiles';
import wktConvert from '../../../../../helpers/wktConvert';
import { ITileProperties } from '../../../../../services/tiles.service';

const columns = [
  "ogc_fid",
  "srid",
  "assessment source",
  "before date",
  "building id",
  "building status before",
  "footprint_area",
  "pre_image_id",
  "wkb_geometry",
  "after date",
  "building status after",
  "post_image_id",
  "type_polygon"
]

export type TileInfoProperties = Partial<ITileProperties>
const buildingStatus = [
  {
    label: 'Destroyed',
    value: 'destroyed'
  },
  {
    label: 'Damaged',
    value: 'damaged'
  },
  {
    label: 'Undamaged',
    value: 'undamaged'
  }
]

export interface ITileInfoProps {
  canEdit?: boolean;
  table?: string;
  properties: TileInfoProperties;
  onCancel?: any;
  onSubmit?: any;
  onDelete?: any;
  onRedraw?: any;
  currentEditShape?: L.Polygon | null;
}
const excludes = ['ogc_fid', 'srid'];

const formatDate = (date: string) => {
  if(!date) return date;
  // date like '20231117'
  const defaultDate = new Date(parseInt(date.substring(0, 4), 10), parseInt(date.substring(4, 6), 10) - 1, parseInt(date.substring(6, 8), 10) + 1);
  if (!Number.isNaN(defaultDate.getTime())) {
    return defaultDate.toISOString().split('T')[0];
  }
  return date;
}

const TileInfo = ({
  canEdit = false,
  table,
  currentEditShape,
  properties,
  onCancel,
  onDelete,
  onSubmit,
  onRedraw,
}: ITileInfoProps) => {
  const [edit, setEdit] = useState<string | undefined>();
  const [data, setData] = useState<any>({});
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (properties) {
      setData(properties);
    }
  }, [properties]);

  const handleSubmit = async () => {
    try {
      const latlngs: any = currentEditShape?.getLatLngs()?.[0]
      if (latlngs) {
        const lnglats = latlngs.map((el: L.LatLng) => [el.lng, el.lat]);
        const poly = turfPolygon([lnglats.concat([lnglats[0]])]);
        const footprint = parseFloat(turfArea(poly).toFixed(1));
        data.wkb_geometry = getUpdateGeoQuery(wktConvert(poly.geometry as GeoJSONGeometry));
        data.footprint_area = footprint;
      }

      const newObj = Object.fromEntries(
        Object.entries(data).map(([key, value]) => {
          if (columns.find((c) => c.replace(/_/g, ' ') === key.replace(/_/g, ' '))) {
            const newKey = columns.find((c) => c.replace(/_/g, ' ') === key.replace(/_/g, ' '));
            return [newKey, value];
          }
          return [key.replace(/_/g, ' '), value];
        }),
      );

      let query = Object.keys(newObj).map((key: string) => {
        if (excludes.includes(key)) return '';
        if (key === 'wkb_geometry') {
          return `${key}=${newObj[key as keyof typeof newObj]}`;
        }
        return `"${key}"='${newObj[key as keyof typeof newObj]}'`;
      });

      query = query.filter((el: string) => el !== '');

      await axiosInstance.put(update(), {
        table,
        query: query.join(','),
        ogc_fid: data.ogc_fid,
      });
      onSubmit();

      toast.success('Update building information successfully');
    } catch (error) {
      toast.error(JSON.stringify(error));
    }
  };

  const handleDelete = async () => {
    try {
      if (!currentEditShape || !table) return;
      await axiosInstance.delete(
        `${config.tileServer}/v1/delete_row/${table}?ogc_fid=${data.ogc_fid}`,
      );

      onSubmit();
      onDelete();

      toast.success('Delete building successfully');
    } catch (error) {
      toast.error(JSON.stringify(error));
    }
  };

  return (
    <Draggable disabled={disable}>
      <div className="tile-info-wrapper" id='tile-info-modal'>
        <div className="tile-info-table">
          <div className="tile-info-row">
            <div className="tile-info-col">
              <span>Assessment source</span>
            </div>
            <div className="tile-info-col">
              <span>{data.assessment_source}</span>
            </div>
          </div>
          <div className="tile-info-row">
            <div className="tile-info-col">
              <span>Building ID</span>
            </div>
            <div className="tile-info-col tile-info-propterty">
              <span>{data.building_id}</span>
            </div>
          </div>
          <div className="tile-info-row">
            <div className="tile-info-col">
              <span>Before date</span>
            </div>
            <div className="tile-info-col tile-info-propterty">
              <span>{formatDate(data.before_date)}</span>
            </div>
          </div>
          <div className="tile-info-row">
            <div className="tile-info-col">
              <span>After date</span>
            </div>
            <div className="tile-info-col tile-info-propterty">
              <span>{formatDate(data.after_date)}</span>
            </div>
          </div>
          <div className="tile-info-row">
            <div className="tile-info-col">
              <span>Building status before</span>
            </div>
            {canEdit ? (
              <div className="tile-info-col tile-info-propterty">
                {edit === 'building_status_before' ? (
                  <>
                    <DropDownSelect
                      value={data.building_status_before}
                      setValue={(value) => {
                        setData({
                          ...data,
                          building_status_before: value,
                        });
                      }}
                      options={buildingStatus}
                    />
                    <div className="tile-info-action" onClick={() => setEdit(undefined)}>
                      <Icon name="save" color="black" />
                    </div>
                  </>
                ) : (
                  <>
                    <span>{data.building_status_before}</span>
                    <div
                      className="tile-info-action"
                      onClick={() => setEdit('building_status_before')}
                    >
                      <Icon name="edit" color="black" />
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className="tile-info-col tile-info-propterty">
                <span>{data.building_status_before}</span>
              </div>
            )}
          </div>

          <div className="tile-info-row">
            <div className="tile-info-col">
              <span>Building status after</span>
            </div>
            {canEdit ? (
              <div className="tile-info-col tile-info-propterty">
                {edit === 'building_status_after' ? (
                  <>
                    <DropDownSelect
                      value={data.building_status_after}
                      setValue={(value) => {
                        setData({
                          ...data,
                          building_status_after: value,
                        });
                      }}
                      options={buildingStatus}
                    />
                    <div className="tile-info-action" onClick={() => setEdit(undefined)}>
                      <Icon name="save" color="black" />
                    </div>
                  </>
                ) : (
                  <>
                    <span>{data.building_status_after}</span>
                    <div
                      className="tile-info-action"
                      onClick={() => setEdit('building_status_after')}
                    >
                      <Icon name="edit" color="black" />
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className="tile-info-col tile-info-proptert">
                <span>{data.building_status_after}</span>
              </div>
            )}
          </div>

          <div className="tile-info-row">
            <div className="tile-info-col">
              <span>Footprint area</span>
            </div>
            <div className="tile-info-col tile-info-propterty">
              <span>{data.footprint_area}</span>
            </div>
          </div>

          <div className="tile-info-row">
            <div className="tile-info-col">
              <span>Pre image ID</span>
            </div>
            <div className="tile-info-col tile-info-propterty">
              <span>{data.pre_image_id}</span>
            </div>
          </div>

          <div className="tile-info-row">
            <div className="tile-info-col">
              <span>Post image ID</span>
            </div>
            <div className="tile-info-col tile-info-propterty">
              <span>{data.post_image_id}</span>
            </div>
          </div>
        </div>

        {canEdit && (
          <>
            <div className="tile-info-footer">
              <div>
                {
                  onRedraw && (
                    <button type="button" onClick={onRedraw} className="submit">
                      Redraw
                    </button>
                  )
                }
              </div>
              <div>
                <button type="button" onClick={onCancel}>
                  Cancel
                </button>
                {
                  onDelete && (
                    <button type="button" className="delete" onClick={handleDelete}>
                      Delete
                    </button>
                  )
                }
                <button type="button" className="submit" onClick={handleSubmit}>
                  Save
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </Draggable>
  );
};

export default TileInfo