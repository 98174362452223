import '../libs/LeafletRestoreView';

import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import cn from 'classnames';
import L, { LatLngBounds } from 'leaflet';

import { useParams } from 'react-router-dom';
import {
  setDrawingMode,
  updateActiveModal,
  setCompareStep,
  updateViewType,
  setMLDrawingMode,
  setAreaSelecting,
  setPolygonSelecting,
  setTypeDrawingMode,
} from '../../store/modules/Common';
import { deleteProjectShape, setCurrent, setCurrentVer } from '../../store/modules/Project';
import { useCommon, useAppDispatch, useProject } from '../../hooks/redux';
import CompareMap from '../CompareMap';
import { Loader } from "../Loading"
import 'leaflet/dist/leaflet.css';

import '../Map/style.scss';
import config from '../../../config';
import { url, bbox } from '../../helpers/vectorTiles';
import { IVersion } from '../../types/IProject';
import { Units } from '../../views/Home/components/ProjectInfo/constants';
import { IOption } from './components/Select/List';
import { DrawingMode, ECompareStep } from '../../types/ICommon';
import MapStyle from './components/MapStyle';
import ComparisonModal from './components/ComparisonModal';
import SideBySideMap from './components/SideBySideMap';
import MainMap from './components/MainMap';
import MapTools from './components/MapTools';
import { ITileProperties } from '../../services/tiles.service';

const defaultProps = {
  // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
  center: new L.LatLng(37.00292241387211, 36.78036237840166),
  zoom: 18,
}

export const getMapStyleUrl = (style: string) => {
  switch (style) {
    case 'satellite':
      return 'https://tile.openstreetmap.org/{z}/{x}/{y}.png';
    case 'hybrid':
      return 'https://tile.openstreetmap.org/{z}/{x}/{y}.png';
    case 'roadmap':
      return 'https://tile.openstreetmap.org/{z}/{x}/{y}.png';
    default:
      return 'https://tile.openstreetmap.org/{z}/{x}/{y}.png';
  }
}

const ProjectDataView = () => {
  const { versionId } = useParams();
  const { viewType, activeModal, compareStep, mlDrawingMode, areaSelecting, polygonSelecting, typeDrawingMode } = useCommon();
  const { projectsList, current, currentVer } = useProject();
  const dispatch = useAppDispatch();
  const leftMap = useRef(null);
  const rightMap = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const [mainMap, setMainMap] = useState<L.Map | null>(null);
  const [drawing, setDrawing] = useState(false);
  const [leftVersion, setLeftVersion] = useState<IOption | undefined>();
  const [rightVersion, setRightVersion] = useState<IOption | undefined>();
  const [mode, setMode] = useState<string | undefined>();
  const [leftTile, setLeftTile] = useState<any>();
  const [rightTile, setRightTile] = useState<any>();
  const [leftPos, setLeftPos] = useState<L.LatLng | undefined>();
  const [rightPos, setRightPos] = useState<L.LatLng | undefined>();
  const [ruler, toggleRuler] = useState(false);
  const [area, toggleArea] = useState(false);
  const [enableRaster, setEnableRaster] = useState(true);
  const [enableGrid, setEnableGrid] = useState(true);
  const [unit, setUnit] = useState(Units.FEET);
  const [style, setStyle] = useState('satellite');
  const [selectedPolys, setSelectedPolys] = useState<ITileProperties[] | null>(null);

  useEffect(() => {
    const listener = (ev: KeyboardEvent) => {
      const inLeafletMap = document.activeElement && document.activeElement?.classList.contains('leaflet-container');
      if (!inLeafletMap) return;
      if(ev.code === 'KeyS') {
        if (mlDrawingMode === 'polygon') {
          dispatch(setMLDrawingMode(null));
        } else {
          dispatch(setMLDrawingMode('polygon'));
        }
      }

      if(ev.code === 'KeyD') {
        if (mlDrawingMode === 'rectangle') {
          dispatch(setMLDrawingMode(null));
        } else {
          dispatch(setMLDrawingMode('rectangle'));
        }
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [dispatch, mlDrawingMode, typeDrawingMode]);

  useEffect(() => {
    try {
      setLoading(true);
      if (!versionId) return;
      dispatch(setCurrent(versionId))
    } catch (error) {
      throw new Error(JSON.stringify(error));
    } finally {
      setLoading(false);
    };
  }, [dispatch, versionId])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        dispatch(updateViewType('map'));
        if(!current) return;
        const currentProj = projectsList.find((item) => item._id === current.project);
        if (!currentProj) return;

        const version = currentProj.versions.find((el: IVersion) => el._id === versionId);
        dispatch(setCurrentVer(version));
      } catch (error) {
        throw new Error(JSON.stringify(error));
      } finally {
        setLoading(false);
      }
    }

    fetchData();

    return () => {
      setCurrentVer(null);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, projectsList, versionId])


  const isVisible = viewType === 'map';

  useEffect(() => {
    dispatch(setDrawingMode(null));
    dispatch(deleteProjectShape());
  }, [activeModal, dispatch]);

  const clearState = () => {
    setLeftVersion(undefined);
    setRightVersion(undefined);
    setMode(undefined);
    setLeftPos(undefined);
    setRightPos(undefined);
    setLeftTile(undefined);
    setRightTile(undefined);
    setDrawing(false);
    toggleArea(false);
    toggleRuler(false);
  }

  const handleToggleAreaSelect = useCallback((status: boolean) => {
    dispatch(setAreaSelecting(status));
    setSelectedPolys(null);
  }, [dispatch]);

  const handleTogglePolygonSelect = useCallback((status: boolean) => {
    dispatch(setPolygonSelecting(status));
    setSelectedPolys(null);
  }, [dispatch])

  const handleToggleCompare = useCallback(() => {
    if (compareStep === ECompareStep.chooseVersion || compareStep === ECompareStep.openCompareTool) {
      dispatch(setCompareStep(ECompareStep.close));
      clearState();
    } else {
      dispatch(setCompareStep(ECompareStep.chooseVersion));
      dispatch(updateActiveModal(null));
    }
  }, [compareStep, dispatch])

  const currentProj = current && projectsList.find((item) => item._id === current.project);
  const isActiveCompare = useMemo(() => {
    return current && (compareStep === ECompareStep.chooseVersion || compareStep === ECompareStep.openCompareTool);
  }, [compareStep, current])

  const handleZoomToDefault = () => {
    if (!mainMap || !currentProj) return;

    if (mode === 'sideBySide' && compareStep === ECompareStep.openCompareTool && leftMap.current && rightMap.current) {
      (leftMap.current as unknown as L.Map).setView([currentProj.center.lat, currentProj.center.lng]);
      (rightMap.current as unknown as L.Map).setView([currentProj.center.lat, currentProj.center.lng]);

      return;
    }

    if (mode === 'slider' && compareStep === ECompareStep.openCompareTool) {
      mainMap.setView([currentProj.center.lat, currentProj.center.lng], 16);
      return;
    }
    mainMap.setView([currentProj.center.lat, currentProj.center.lng], 16);
  }
  if (isLoading) return <Loader />;

  const handleToggleDraw = (type: DrawingMode) => {
    dispatch(setTypeDrawingMode(type));
    dispatch(setMLDrawingMode(type));
  }

  return (
    <div className={cn('map', { hidden: !isVisible })}>
      {isVisible && current && (
        <MapTools
          drawing={drawing}
          drawPolygon={{
            active: mlDrawingMode === 'polygon',
            toggle: handleToggleDraw,
            disable: compareStep === ECompareStep.openCompareTool || areaSelecting,
          }}
          drawRectangle={{
            active: mlDrawingMode === 'rectangle',
            toggle: handleToggleDraw,
            disable: compareStep === ECompareStep.openCompareTool || areaSelecting,
          }}
          areaSelect={{
            active: areaSelecting,
            toggle: handleToggleAreaSelect,
            disable: compareStep === ECompareStep.openCompareTool || mlDrawingMode === 'polygon' || mlDrawingMode === 'rectangle',
          }}
          polygonSelect={{
            active: polygonSelecting,
            toggle: handleTogglePolygonSelect,
            disable: compareStep === ECompareStep.openCompareTool || mlDrawingMode === 'polygon' || mlDrawingMode === 'rectangle',
          }}
          compare={{ active: isActiveCompare, toggle: handleToggleCompare, disable: !currentProj }}
          raster={{
            active: enableRaster,
            toggle: setEnableRaster,
            disable: compareStep === ECompareStep.openCompareTool,
          }}
          grid={{
            active: enableGrid,
            toggle: setEnableGrid,
            disable: compareStep === ECompareStep.openCompareTool
          }}
          ruler={{
            active: ruler,
            toggle: toggleRuler,
            disable: compareStep === ECompareStep.openCompareTool,
          }}
          area={{
            active: area,
            toggle: toggleArea,
            disable: compareStep === ECompareStep.openCompareTool,
          }}
          unit={{ active: unit, toggle: setUnit }}
          zoomToDefault={handleZoomToDefault}
        />
      )}

      {isVisible && <MapStyle style={style} setStyle={setStyle} />}

      {compareStep === ECompareStep.chooseVersion && currentProj && (
        <ComparisonModal
          project={currentProj}
          leftVersion={{ get: leftVersion, set: setLeftVersion }}
          rightVersion={{ get: rightVersion, set: setRightVersion }}
          mode={{ get: mode, set: setMode }}
        />
      )}

      {/* Side by side compare */}
      {mode === 'sideBySide' && compareStep === ECompareStep.openCompareTool && (
        <SideBySideMap
          styleUrl={getMapStyleUrl(style)}
          defaultProp={defaultProps}
          leftPos={leftPos}
          rightPos={rightPos}
          leftTile={leftTile}
          rightTile={rightTile}
          rightMap={rightMap}
          leftMap={leftMap}
          leftVersion={currentProj?.versions?.find((el: IVersion) => el._id === leftVersion?.value)}
          rightVersion={currentProj?.versions?.find(
            (el: IVersion) => el._id === rightVersion?.value,
          )}
        />
      )}

      {/* Handle show map */}
      {compareStep === ECompareStep.openCompareTool && mode ? (
        <CompareMap
          styleUrl={getMapStyleUrl(style)}
          mode={mode}
          setLeftPos={setLeftPos}
          setRightPos={setRightPos}
          setLeftTile={setLeftTile}
          setRightTile={setRightTile}
          leftVersion={currentProj?.versions?.find((el: IVersion) => el._id === leftVersion?.value)}
          rightVersion={currentProj?.versions?.find(
            (el: IVersion) => el._id === rightVersion?.value,
          )}
          leftMap={leftMap}
          rightMap={rightMap}
          setCompareMap={setMainMap}
          showRaster={enableRaster}
        />
      ) : (
        <MainMap
          styleUrl={getMapStyleUrl(style)}
          unit={unit}
          ruler={ruler}
          area={area}
          token={config.mapBoxToken}
          currentProj={currentProj}
          defaultProps={defaultProps}
          isVisible={isVisible}
          drawing={drawing}
          areaSelect={{
            active: areaSelecting,
            toggle: handleToggleAreaSelect,
          }}
          selectedPolys={selectedPolys}
          setSelectedPolys={setSelectedPolys}
          setDrawing={setDrawing}
          url={url}
          setMainMap={setMainMap}
          currentVer={currentVer}
          showRaster={enableRaster}
          showGrid={enableGrid}
        />
      )}
    </div>
  );
};

export default ProjectDataView;
