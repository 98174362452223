import { createAsyncThunk } from '@reduxjs/toolkit';
import SatelliteService, { ISatellite } from '../../../services/satellite.service';

export const createSatellite = createAsyncThunk(
  'satellite/createSatellite',
  async (params: ISatellite, thunkAPI) => {
    try {
      return await SatelliteService.create(params);
    } catch (error: any) {
      if (error.statusCode === 400) {
        return thunkAPI.rejectWithValue('Incorrect data!');
      }

      return thunkAPI.rejectWithValue(error.message || 'Server Error!');
    }
  },
);

export const initSatellite = createAsyncThunk('satellite/initSatellite', async (_, thunkAPI) => {
  try {
    return await SatelliteService.initialization();
  } catch (error: any) {
    if (error.statusCode === 400) {
      return thunkAPI.rejectWithValue('Incorrect data!');
    }

    return thunkAPI.rejectWithValue(error.message || 'Server Error!');
  }
});
