import React, { useCallback, useEffect, useState } from 'react'
import { MapContainer, Pane, TileLayer, useMap } from 'react-leaflet'
import { useParams } from 'react-router';
import L from "leaflet";
import cn from 'classnames';
import moment from 'moment';
import { useCommon, useProject } from '../../hooks/redux';
import './style.scss';
import CompareSlider from './CompareSlider';
import CompareSideBySide from './CompareSideBySide';
import { url, bbox } from '../../helpers/vectorTiles';
import { IProject, IVersion } from '../../types/IProject';

const token =
  'pk.eyJ1IjoicXVhbmhvYW5nYmh0ZWNoIiwiYSI6ImNrNTYzenlpazBkbDMza3M3cWhjMHd4bmsifQ.4lOUzhqkzYcm8B2zrRjQZA';

export interface ICompareMapProps {
  styleUrl: string;
  mode?: string;
  setLeftTile: any;
  setLeftPos: any;
  setRightTile: any;
  setRightPos: any;
  leftVersion: IVersion;
  rightVersion: IVersion;
  leftMap: any;
  rightMap: any;
  showRaster: boolean;
  setCompareMap: React.Dispatch<React.SetStateAction<L.Map | null>>;
}

export interface IMapHandlerProps {
  setCompareMap: React.Dispatch<React.SetStateAction<L.Map | null>>;
}

const MapHandler = ({ setCompareMap }: IMapHandlerProps) => {
  const map = useMap();
  const { versionId } = useParams();
  const { projectsList, current } = useProject();

  useEffect(() => {
    if (!versionId) return;
    if (!(map as any).restoreView(versionId)) {
      if (current) {
        const center = projectsList.find((item) => item._id === current.project)?.center;
        if (center) {
          map?.setView(center, 18);
        }
      } else {
        map.setView([37.17624705522682, 36.437428248184304], 20);
      }
    }
  }, [current, map, projectsList, versionId])

  return null;
}

const CompareMap = ({
  showRaster,
  styleUrl,
  leftMap,
  rightMap,
  mode,
  setLeftTile,
  setRightTile,
  setLeftPos,
  setRightPos,
  leftVersion,
  rightVersion,
  setCompareMap
}: ICompareMapProps) => {
  const { viewType } = useCommon();
  const [tile, setTile] = useState<any>();
  const [pos, setPos] = useState<L.LatLng>();
  const { projectsList, current } = useProject();

  const currentProj = current && projectsList.find((item) => item._id === current.project);
  const defaultCenter = currentProj ? currentProj.center : [37.17624705522682, 36.437428248184304]

  const isVisible = viewType === 'map';

  const renderCompareTool = useCallback(
    (compareMode?: string) => {
      switch (compareMode) {
        case 'slider':
          return (
            <CompareSlider
              styleUrl={styleUrl}
              tile={tile}
              pos={pos}
              setTile={setTile}
              setPos={setPos}
              leftLayer={{
                url_tif: leftVersion?.url_tif || '',
                url: url(leftVersion?.geojson_table),
                bbox: bbox(leftVersion?.geojson_table),
                name: leftVersion?.geojson_table,
              }}
              rightLayer={{
                url_tif: rightVersion?.url_tif || '',
                url: url(rightVersion?.geojson_table),
                bbox: bbox(rightVersion?.geojson_table),
                name: rightVersion?.geojson_table,
              }}
            />
          );
        case 'sideBySide':
          return (
            <CompareSideBySide
              styleUrl={styleUrl}
              setLeftPos={setLeftPos}
              setRightPos={setRightPos}
              setLeftTile={setLeftTile}
              setRightTile={setRightTile}
              leftLayer={{
                url_tif: leftVersion?.url_tif,
                url: url(leftVersion?.geojson_table),
                bbox: bbox(leftVersion?.geojson_table),
                name: leftVersion?.geojson_table,
              }}
              rightLayer={{
                url_tif: rightVersion?.url_tif,
                url: url(rightVersion?.geojson_table),
                bbox: bbox(rightVersion?.geojson_table),
                name: rightVersion?.geojson_table,
              }}
              leftMap={leftMap}
              rightMap={rightMap}
            />
          );

        default:
          return null;
      }
    },
    [
      tile,
      pos,
      leftMap,
      leftVersion?.geojson_table,
      leftVersion?.url_tif,
      rightMap,
      rightVersion?.geojson_table,
      rightVersion?.url_tif,
      setLeftPos,
      setLeftTile,
      setRightPos,
      setRightTile,
      styleUrl,
    ],
  );

  return (
    <div className={cn('compare-map', { hidden: !isVisible })}>
      <MapContainer center={defaultCenter} zoom={20} style={{ width: '100%', height: '100%' }}>
        <MapHandler setCompareMap={setCompareMap} />

        <TileLayer
          attribution='© <a href="https://apps.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          url={styleUrl}
        />
        <Pane name="vector-compare-pane">
          {renderCompareTool(mode)}
        </Pane>

        {
          leftVersion && currentProj && (
            <span className='version-label left'>
              V{currentProj.versions.findIndex((v: IVersion) => v._id === leftVersion._id) + 1}
              {' - '}
              {moment(leftVersion.createdAt).format('DD/MM/YYYY')}
            </span>
          )
        }

        {
          rightVersion && currentProj && (
            <span className='version-label right'>
              V{currentProj.versions.findIndex((v: IVersion) => v._id === rightVersion._id) + 1}
              {' - '}
              {moment(rightVersion.createdAt).format('DD/MM/YYYY')}
            </span>
          )
        }

      </MapContainer>
    </div>
  )
}

export default CompareMap;