// @ts-nocheck

import { Mesh, SphereGeometry, MeshStandardMaterial, LinearFilter, Group } from 'three';

export default class EarthModel {
  public mainContainer: Group;

  private material: MeshStandardMaterial;

  private mesh: Mesh;

  private cloudMesh: Mesh;

  private cloudMaterial: MeshStandardMaterial;

  private daySeconds: number = 24 * 60 * 60;

  private rotateSpeed: number = (Math.PI * 2) / this.daySeconds;

  constructor() {
    this.init();
  }

  private init() {
    this.mainContainer = new Group();
    this.mainContainer.name = 'Earh Container';

    this.material = new MeshStandardMaterial({ color: '#aaaaaa', side: 0 });
    this.mesh = new Mesh(new SphereGeometry(10, 64, 64), this.material);

    this.mainContainer.add(this.mesh);

    this.cloudMaterial = new MeshStandardMaterial({
      color: '#aaaaaa',
      side: 2,
      transparent: true,
      opacity: 0.2,
      depthWrite: false,
    });
    this.cloudMesh = new Mesh(new SphereGeometry(10.1, 64, 64), this.cloudMaterial);

    this.mainContainer.add(this.cloudMesh);
  }

  public setNewMap(map) {
    map.minFilter = LinearFilter;
    this.material.map = map;
    this.material.needsUpdate = true;
  }

  public setNormal(normal) {
    this.material.normalMap = normal;
    this.material.normalScale.set(-2, -2);
    this.material.needsUpdate = true;
  }

  public setCloudMap(map) {
    map.minFilter = LinearFilter;
    this.cloudMaterial.map = map;
    this.cloudMaterial.emissiveIntensity = 0.8;
    this.cloudMaterial.emissive.setHex(0x5555ff);
    this.cloudMaterial.needsUpdate = true;
  }

  public rotate(time) {
    this.mainContainer.rotation.y = time * this.rotateSpeed;
  }
}
