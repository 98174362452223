
import { polygon as turfPolygon, area as turfArea } from '@turf/turf';

const getFootprintArea = (latlngs: L.LatLng[]) => {
  const lnglats = latlngs.map((el: L.LatLng) => [el.lng, el.lat]);
  const poly = turfPolygon([lnglats.concat([lnglats[0]])]);
  const footprint = parseFloat(turfArea(poly).toFixed(1));

  return footprint;
}

export default getFootprintArea;