import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import { object, string } from 'yup';
import InputText from '../../../../../components/ui/Inputs/InputText';
import Button from '../../../../../components/ui/Button';
import ProjectService from '../../../../../services/project.service';
import { Loading } from '../../../../../components/Loading';
import Select from '../../../../../components/ui/Inputs/Select';

import './style.scss';

export enum EImageType {
  Pre_Image_ID = 'pre_image_id',
  Post_Image_ID = 'post_image_id',
};
export interface FolderName {
  bucket: string;
  key: string;
  image_type: EImageType;
}

interface Props {
  versionId: string;
  onSubmit: (values: any) => void;
  onClose: () => void;
}

const imageTypeOptions = Object.entries(EImageType).map(([label, key]) => ({
  value: key,
  label: label.replaceAll('_', ' ').toUpperCase(),
}));

const validationSchema = object().shape({
  bucket: string().required('Bucket is required'),
  key: string().required('Key is required'),
  image_type: string().required('Image type is required'),
});

const RasterFolder = ({ versionId, onSubmit, onClose }: Props) => {
  const [defaultValues, setDefaultValues] = useState<FolderName>({
    bucket: '',
    key: '',
    image_type: EImageType.Pre_Image_ID,
  });
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  const image_type = watch('image_type');

  const changeImageType = React.useCallback(async (value?: string) => {
    reset({ bucket: '', key: '', image_type: value });
    try {
      const res = await ProjectService.getOriginalTifFolder(versionId, value as EImageType);
      const { bucket, key } = res;

        setDefaultValues({
          bucket: bucket || undefined,
          key: key || undefined,
          image_type: value as EImageType,
        });
    } catch (error) {
      throw new Error(error instanceof Error ? error.message : 'Uncaught exception');
    }
  }, [reset, versionId]);

  useEffect(() => {
    const getTifFolder = async () => {
      try {
        setLoading(true);
        if (!versionId || !image_type) return;
        const res = await ProjectService.getOriginalTifFolder(versionId, image_type);
        if (res) {
          const { bucket, key } = res;

          setDefaultValues({
            bucket: bucket || undefined,
            key: key || undefined,
            image_type,
          });
        }
      } catch (error: any) {
        setLoading(false);
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    getTifFolder();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versionId, reset]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='raster-folder'>
      <Loading shown={loading} />

      <Controller
        control={control}
        name="image_type"
        render={({ field }) => (
          <Select
            options={imageTypeOptions}
            value={field.value}
            onChange={(value) => {
              field.onChange(value);
              changeImageType(value);
            }}
            label="Image Type"
            error={errors.image_type && errors.image_type.message?.toString()}
          />
        )}
      />

      <Controller
        control={control}
        name="bucket"
        render={({ field }) => (
          <InputText placeholder="Bucket" value={field.value} onChange={field.onChange} label='Bucket'/>
        )}
      />
      <span className="input-folder-err-msg">{errors.bucket && errors.bucket.message?.toString()}</span>

      <Controller
        control={control}
        name="key"
        render={({ field }) => (
          <InputText placeholder="Key" value={field.value} onChange={field.onChange} label='Key'/>
        )}
      />
      <span className="input-folder-err-msg">{errors.key && errors.key.message?.toString()}</span>

      <div className="project-folder-input__footer">
        <Button
          icon="cancel"
          className="project-folder-input__footer-btn cancel"
          color="blue"
          iconRight={false}
          onClick={onClose}
        >
          Discard
        </Button>
        <Button
          type="submit"
          icon="save"
          className="project-folder-input__footer-btn save"
          color="black"
          iconRight={false}
        >
          Save changes
        </Button>
      </div>
    </form>
  );
};

export default RasterFolder;
