import React, { FC, DetailedHTMLProps, InputHTMLAttributes, forwardRef } from 'react';
import './style.scss';
import cn from 'classnames';
import Icon from '../../Icon';

interface IProps
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  error?: string;
  label?: string;
  tooltip?: string;
}

const InputText = forwardRef<HTMLInputElement, IProps>(({ label, error, ...props }, ref) => {
  const className = cn('input-text', {
    error,
  });

  return (
    <label className={className}>
      {label ? <p>{label}</p> : null}
      <input {...props} ref={ref} />
      {error && <span className="input-text__error">{error}</span>}
    </label>
  );
});

export const SatelliteInputText = forwardRef<HTMLInputElement, IProps>(
  ({ label, error, tooltip, ...props }, ref) => {
    const className = cn('input-text satellite-input', {
      error,
    });

    return (
      <label className={className}>
        {label ? <p>{label}</p> : null}
        <input {...props} ref={ref} />
        {/* {error && <span className="input-text__error">{error}</span>} */}
        {tooltip ? (
          <div className="satellite-input__icon">
            <Icon name="question" color="red" />
            <span className="tooltiptext">{tooltip}</span>
          </div>
        ) : null}
      </label>
    );
  },
);

export default InputText;
