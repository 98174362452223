import React from 'react'
import { Units } from '../../../../../views/Home/components/ProjectInfo/constants'
import './style.scss';

const UnitSelect = ({ setUnit }: { setUnit: React.Dispatch<React.SetStateAction<string>> }) => {
  return (
    <ul className='unit-select-list'>
      {
        Object.keys(Units).map(el => (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <li
            className='unit-select-item'
            key={Units[el as keyof typeof Units]}
            onClick={() => setUnit(Units[el as keyof typeof Units])}
          >
            {Units[el as keyof typeof Units]}
          </li>
        ))
      }
    </ul>
  )
}

export default UnitSelect