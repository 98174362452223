import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw';

import React, { useEffect, useCallback, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { FeatureGroup, useMap } from 'react-leaflet';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { polygon as turfPolygon, area as turfArea } from '@turf/turf';
import { EditControl } from 'react-leaflet-draw';
import { GeoJSONGeometry } from 'wellknown';
import { DrawEvents, Polygon } from 'leaflet';
import { setAreaSelecting, setMLDrawingMode } from '../../../../store/modules/Common';
import { drawPluginOptions } from './drawPluginOptions';
import { useCommon, useProject } from '../../../../hooks/redux';
import CreatePolygonForm from './CreatePolygonForm';
import wktConvert from '../../../../helpers/wktConvert';
import { getUpdateGeoQuery } from '../../../../helpers/vectorTiles';
import axiosInstance from '../../../../utils/axios';
import config from '../../../../../config';
import { IVersion, ProjectType } from '../../../../types/IProject';
import TileInfo from '../ModelInfo/TileInfo';
import getFootprintArea from '../../../../helpers/getFootprintArea';
import ProjectService from '../../../../services/project.service';
import TilesService, { ITileProperties } from '../../../../services/tiles.service';
import VehicleInfo from '../ModelInfo/VehicleInfo';
import { EAlgorithmType, IVehicleProperties } from '../../../../types/IVehicle';
import { EImageType } from '../../../../views/Home/components/ProjectInfo/RasterFolder';
import './style.scss';

export interface ILeafletDrawProps {
  currentEditShape: any;
  editableFGRef: React.MutableRefObject<any>;
  currentVer: IVersion | null;
  drawnLayer: Polygon<any> | null;
  setCurrentEditShape: React.Dispatch<any>;
  handleClearDrawnLayer: () => void;
  setSelectedPolys: React.Dispatch<React.SetStateAction<ITileProperties[] | null>>
  handleStartDrawing: () => void;
  onCancelSelectArea: () => void;
  setDrawnlayer: React.Dispatch<React.SetStateAction<L.Polygon<any> | null>>;
  onAreaSelected?: (layer: any) => void;
  setTile: React.Dispatch<any>
  setLoading: React.Dispatch<boolean>;
}

const columnsInDB = [
  "ogc_fid",
  "srid",
  "assessment source",
  "before date",
  "building id",
  "building status before",
  "footprint_area",
  "pre_image_id",
  "wkb_geometry",
  "after date",
  "building status after",
  "post_image_id",
  "type_polygon"
]

const drawingToolClick = (type: string) => {
  const element = document.querySelector(`.leaflet-draw-draw-${type}`);

  if (element) {
    (element as HTMLElement).click();
  }
};

const excludes = ['ogc_fid', 'srid'];

const LeafletDraw = ({
  currentEditShape,
  editableFGRef,
  currentVer,
  drawnLayer,
  setDrawnlayer,
  handleClearDrawnLayer,
  handleStartDrawing,
  onCancelSelectArea,
  onAreaSelected,
  setCurrentEditShape,
  setSelectedPolys,
  setTile,
  setLoading
}: ILeafletDrawProps) => {
  const { current, projectsList } = useProject();
  const { mlDrawingMode, areaSelecting, typeDrawingMode } = useCommon();
  const map = useMap();
  const dispatch = useDispatch();
  const [documentReady, setDocumentReady] = useState(document.readyState === 'complete');

  const currentProj = current && projectsList.find((item) => item._id === current.project);

  useEffect(() => {
    const actions = document.querySelectorAll('.leaflet-draw-actions');
    if (mlDrawingMode || areaSelecting) {
      drawingToolClick(mlDrawingMode || 'polygon');
    } else {
      const activeActions = Array.from(actions).find((el) => el.querySelector('li'));
      if (activeActions) {
        const cancelBtn = activeActions.querySelector('li > a[title="Cancel drawing"]');
        if (cancelBtn) {
          (cancelBtn as HTMLElement).click();
        }
      }
    }
  }, [mlDrawingMode, map, editableFGRef, areaSelecting]);

  useEffect(() => {
    document.addEventListener('readystatechange', (event) => {
      if (document.readyState === 'complete') {
        setDocumentReady(true);
      }
    });
  }, []);

  useEffect(() => {
    if (documentReady && map) {
      const handler = (event: any) => {
        if (event.key === 'Escape') {
          if (mlDrawingMode) {
            dispatch(setMLDrawingMode(null));
          }
        }

        if(areaSelecting) {
          dispatch(setAreaSelecting(false));
          setSelectedPolys(null);
        }
      };

      document.addEventListener('keyup', handler, false);

      return () => {
        document.removeEventListener('keyup', handler);
      };
    }
  }, [areaSelecting, dispatch, documentReady, map, mlDrawingMode, setSelectedPolys]);

  const clearDrawnLayerOnMap = React.useCallback(() => {
    const drawnItems = (editableFGRef as any)?.current?.getLayers() || [];
    drawnItems.forEach((item: L.Layer) => {
      if ((item as any)?.editing) {
        map.removeLayer(item);
      }
    });
  }, [editableFGRef, map]);

  const onCancel = React.useCallback((objectPortal: ReactDOM.Root) => {
    objectPortal.unmount();
    clearDrawnLayerOnMap();
    handleClearDrawnLayer();

    setTimeout(() => {
      handleStartDrawing();
    }, 100);
  }, [clearDrawnLayerOnMap, handleClearDrawnLayer, handleStartDrawing])

  const createPolygon = useCallback(async () => {
    if (!drawnLayer) return;
    try {
      setLoading(true);
      const payload = {
        coordinates: (drawnLayer as any)?.getLatLngs()?.[0],
      }

      const preImageId = await ProjectService.findImageId(currentVer?._id || '', {
        ...payload,
        image_type: EImageType.Pre_Image_ID,
      });

      const postImageId = await ProjectService.findImageId(currentVer?._id || '', {
        ...payload,
        image_type: EImageType.Post_Image_ID,
      });
      let building = null;
      if (preImageId?.fileName) {
        building = await TilesService.findBuildingByImageId(currentVer?.geojson_table || '', preImageId.fileName)
      } else {
        building = await TilesService.findBuildingByImageId(currentVer?.geojson_table || '')
      }

      const objectPortal = ReactDOM.createRoot(
        document.getElementById('project-object-portal') as HTMLElement,
      );

      const save = async (data: any) => {
        const poly = turfPolygon(drawnLayer.toGeoJSON().geometry.coordinates as any);
        const wkbGeo = getUpdateGeoQuery(wktConvert(poly.geometry as GeoJSONGeometry));
        data.before_date = data.before_date.replaceAll('-', '');
        if (data.after_date) {
          data.after_date = data.after_date.replaceAll('-', '');
        }
        data.footprint_area = parseFloat(turfArea(poly).toFixed(1));
        data.wkb_geometry = wkbGeo;
        if (data.building_id === null) {
          delete data.building_id;
        }

        const columns = Object.entries(data).map(([key, value]) => {
          if (columnsInDB.find((c) => c.replace(/_/g, ' ') === key.replace(/_/g, ' '))) {
            const newKey = columnsInDB.find((c) => c.replace(/_/g, ' ') === key.replace(/_/g, ' '));
            return newKey;
          }
          return key.replace(/_/g, ' ');
        })
        const values = Object.keys(data).map((key) => data[key]);

        try {
          await axiosInstance.post(
            `${config.tileServer}/v1/insert_row/${currentVer?.geojson_table}`,
            {
              columns,
              values,
            },
          );

          clearDrawnLayerOnMap();
          handleClearDrawnLayer();

          setTimeout(() => {
            handleStartDrawing();
          }, 100);

          objectPortal.unmount();
          toast.success('Add building successfully');
        } catch (error) {
          toast.error(JSON.stringify(error));
        }
      };

      objectPortal.render(
        <CreatePolygonForm
          pre_image_id={preImageId.fileName}
          post_image_id={postImageId.fileName}
          layer={drawnLayer}
          onCancel={() => onCancel(objectPortal)}
          onSave={save}
          type_polygon={typeDrawingMode || ''}
          before_date={building?.[0]?.['before date']}
          after_date={building?.[0]?.['after date']}
        />,
      );
    } catch (error) {
      throw new Error(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVer, dispatch, editableFGRef, drawnLayer, map]);

  const handleCreateVehicle = useCallback(async () => {
    if (!drawnLayer) return;
    try {
      const payload = {
        coordinates: (drawnLayer as any)?.getLatLngs()?.[0],
      }
      const preImageId = await ProjectService.findImageId(currentVer?._id || '', {
        ...payload,
        image_type: EImageType.Pre_Image_ID,
      });
      const objectPortal = ReactDOM.createRoot(
        document.getElementById('project-object-portal') as HTMLElement,
      );
      const fileName = preImageId?.fileName || '';
      const clonedData:any = {}
      clonedData.images_filename = fileName
      if (fileName) {
        const existVehicle = await TilesService.findBuildingByImageId(currentVer?.geojson_table || '', fileName, ProjectType.vehicle);
        const clonedColumns = [
          'information_year',
          'information_version',
          'information_description',
          'information_contributor',
          'information_date_created',
          'information_classification',
          'information_owner_producer',
          'information_id',
          'information_url',
          'information_name',
          'information_decription',
          'images_id',
          'images_width',
          'images_height',
          'images_extent',
          'images_filename',
          'images_classification',
          'images_date_captured',
          'images_platform',
          'images_image_type',
          'images_projection',
          'images_sun_azimuth_angle',
          'images_sun_elevation_angle',
          'images_percentage_cloud_cover',
          'aoi_id',
          'aoi_location_id',
          'aoi_country_code',
          'aoi_order_id',
          'aoi_aoi_poly',
          'annotator_id',
          'annotator_name',
          'annotator_version',
          'annotator_notes',
          'annotations_image_id',
          'annotations_aoi_id',
          'annotations_global_id',
          'annotations_classification']
        if(existVehicle.length > 0){
          // eslint-disable-next-line no-restricted-syntax
          for (const key in existVehicle[0]) {
            if (clonedColumns.includes(key)) {
              clonedData[key] = existVehicle[0][key]
            }
          }

        }
        // eslint-disable-next-line no-restricted-syntax

      }

      const onSave = async (values: any) => {
        delete values?.ogc_fid;


        values.type_polygon = typeDrawingMode || '';
        const poly = turfPolygon(drawnLayer.toGeoJSON().geometry.coordinates as any);
        const wkbGeo = getUpdateGeoQuery(wktConvert(poly.geometry as GeoJSONGeometry));

        values.annotations_bbox_geo =`(ARRAY[${drawnLayer.getBounds().toBBoxString()}])`;

        values.annotations_width_geo = `${(drawnLayer.getBounds().getEast() - drawnLayer.getBounds().getWest()) * 6378137 * Math.PI / 180}`;
        values.annotations_len_geo = `${(drawnLayer.getBounds().getNorth() - drawnLayer.getBounds().getSouth())* 6378137 * Math.PI / 180}`;
        values.annotations_object_center_geo =`(ARRAY[${drawnLayer.getCenter().lng},${drawnLayer.getCenter().lat}])`;

        values.annotations_area_geo = `${parseFloat(turfArea(poly).toFixed(1))}`;

        const mergedValue = { ...clonedData, ...values }
        mergedValue.wkb_geometry = wkbGeo;
        const columns = Object.entries(mergedValue).map(([key, value]) => key);
        const columnValues = Object.keys(mergedValue).map((key) => mergedValue[key]);

        await TilesService.insertRow(currentVer?.geojson_table || '', {
          columns,
          values: columnValues,
          type: ProjectType.vehicle
        });

        clearDrawnLayerOnMap();
        handleClearDrawnLayer();

        setTimeout(() => {
          handleStartDrawing();
        }, 100);

        objectPortal.unmount();
        toast.success('Add vehicle successfully');
      };

      objectPortal.render(
        <VehicleInfo canEdit onCancel={() => onCancel(objectPortal)} onSubmit={onSave} properties={{
          images_filename: preImageId.fileName || '',
        }} />,
      );
    } catch (error) {
      throw new Error(error instanceof Error ? error.message : 'Uncaught Error!');
    }
  }, [clearDrawnLayerOnMap, currentVer, drawnLayer, handleClearDrawnLayer, handleStartDrawing, onCancel, typeDrawingMode])

  const redrawPolygon = useCallback(
    (layer: L.Polygon) => {
      const objectPortal = document.getElementById('tile-info-modal') as HTMLElement;
      if (objectPortal) {
        objectPortal.remove();
      }

      const newObjectPortal = ReactDOM.createRoot(
        document.getElementById('project-object-portal') as HTMLElement,
      );

      const handleClearDrawingBoard = () => {
        (map as any).options.redrawing = false;
        (map as any).options.shapeRedrawProperties = null;

        newObjectPortal.unmount();
        layer.removeFrom(map);

        setTile(null);
        setCurrentEditShape(null);
        setDrawnlayer(null);

        dispatch(setMLDrawingMode(null));
      };

      const handleSaveVehicle = async (values: IVehicleProperties) => {
        try {
          values.type_polygon = typeDrawingMode || '';
          const latlngs = (drawnLayer as any)?.getLatLngs()?.[0];
          const lnglats = latlngs.map((el: L.LatLng) => [el.lng, el.lat]);
          const poly = turfPolygon([lnglats.concat([lnglats[0]])]);
          values.wkb_geometry = getUpdateGeoQuery(wktConvert(poly.geometry as GeoJSONGeometry));
          const newBBox: L.LatLngBounds = currentEditShape.getBounds();
          values.annotations_bbox_geo = JSON.stringify([
            [
              newBBox.getSouthWest().lng,
              newBBox.getSouthWest().lat,
            ],
            [
              newBBox.getNorthEast().lng,
              newBBox.getNorthEast().lat,
            ]
          ]);

          let query = Object.keys(values).map((key: string) => {
            if (excludes.includes(key)) return '';
            if (key === 'wkb_geometry') {
              return `${key}=${values[key as keyof typeof values]}`;
            }
            return `"${key}"='${values[key as keyof typeof values]}'`;
          });

          query = query.filter((el: string) => el !== '');

          await TilesService.updateRow({
            table: currentVer?.geojson_table || '',
            query: query.join(','),
            ogc_fid: values.ogc_fid,
          });

          handleClearDrawingBoard();
          toast.success('Update building information successfully');
        } catch (error) {
          toast.error('Update building information failed');
          throw new Error(error instanceof Error ? error.message : 'Uncaught Error');
        }
      };

      const handleDelete = async (ogc_fid: string) => {
        try {
          if (!currentEditShape || !currentVer?.geojson_table) return;
          await TilesService.deleteRow(currentVer.geojson_table, ogc_fid);

          toast.success('Delete building successfully');
        } catch (error) {
          toast.error(JSON.stringify(error));
        }
      };

      const footprint = getFootprintArea((layer as any)?.getLatLngs()?.[0]);

      const properties = (map as any).options.shapeRedrawProperties;
      if (properties) {
        switch (currentProj?.type) {
          case ProjectType.vehicle:
            newObjectPortal.render(
              <VehicleInfo
                canEdit
                properties={properties}
                onCancel={handleClearDrawingBoard}
                onSubmit={handleSaveVehicle}
                // onRedraw={handleRedraw}
                onDelete={(ogc_fid: string) => handleDelete(ogc_fid)}
              />,
            );
            break;
          case ProjectType.building:
            newObjectPortal.render(
              <TileInfo
                canEdit
                table={currentVer?.geojson_table || ''}
                currentEditShape={layer}
                // Default Human-Derived for manual drawing
                properties={{
                  ...properties,
                  footprint_area: footprint,
                }}
                onCancel={handleClearDrawingBoard}
                onSubmit={handleClearDrawingBoard}
              />,
            );
            break;
          default:
            newObjectPortal.render(
              <TileInfo
                canEdit
                table={currentVer?.geojson_table || ''}
                currentEditShape={layer}
                // Default Human-Derived for manual drawing
                properties={{
                  ...properties,
                  footprint_area: footprint,
                }}
                onCancel={handleClearDrawingBoard}
                onSubmit={handleClearDrawingBoard}
              />,
            );
            break;
        }
      }
    },
    [
      map,
      setTile,
      setCurrentEditShape,
      setDrawnlayer,
      dispatch,
      currentVer,
      currentProj,
      currentEditShape,
      drawnLayer,
      typeDrawingMode
    ],
  );

  // Trigger function after draw completed
  useEffect(() => {
    if (drawnLayer) {
      if ((map.options as any).redrawing) {
        redrawPolygon(drawnLayer);
        return;
      }
      if (areaSelecting && onAreaSelected) {
        onAreaSelected(drawnLayer);
        return;
      }
      if (!areaSelecting && !mlDrawingMode) {
        onCancelSelectArea();
        clearDrawnLayerOnMap();
      };

      if (mlDrawingMode) {
        switch (currentProj.type) {
          case ProjectType.building:
            createPolygon();
            break;
          case ProjectType.vehicle:
            handleCreateVehicle();
            break;
          default:
            createPolygon();
            break;
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaSelecting, createPolygon, drawnLayer]);

  const onCreated = (v: DrawEvents.Created) => {
    const { layer } = v;
    setDrawnlayer(layer as any);
  };

  return (
    <FeatureGroup ref={editableFGRef}>
      <EditControl position="topright" draw={drawPluginOptions.draw} onCreated={onCreated} />
    </FeatureGroup>
  );
};

export default LeafletDraw;
