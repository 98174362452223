import React, { FC, ReactNode } from 'react';
import './style.scss';
import cn from 'classnames';
import Icon from '../ui/Icon';
import Button from '../ui/Button';

interface IProps {
  className?: string;
  close?: () => void;
  title?: string;
  children: ReactNode;
  small?: boolean;
}

const ModalWrapper: FC<IProps> = ({ close, title, children, className, small }) => {
  return (
    <div className={cn('main-modal-wrapper', className, { small })}>
      {close && (
        <Button className="main-modal-wrapper__close" onClick={close}>
          <Icon name="close" color="black" size={3} />
        </Button>
      )}
      {title ? <div className="main-modal-wrapper__title">{title}</div> : null}
      <div className="main-modal-wrapper__content">{children}</div>
    </div>
  );
};

export default ModalWrapper;
