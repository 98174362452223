/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { renderToStaticMarkup }  from 'react-dom/server';
import React, { useState, FC } from 'react';
import cn from 'classnames';
import { Marker, Popup, useMap } from "react-leaflet";
import { divIcon } from "leaflet";
import './style.scss';

const ProjectMarker: FC<any> = ({ title, onSelect, lat, lng }) => {
  const map = useMap();

  const iconMarkup = renderToStaticMarkup(
    <span className='projectMarkerIcon'/>
  );

  const customMarkerIcon = divIcon({
    html: iconMarkup
  });

  return (
    <Marker
      position={[lat, lng]}
      icon={customMarkerIcon}
      eventHandlers={{
        click: () => map && map.setView([lat, lng]),
        mouseover: (event) => event.target.openPopup(),
        mouseout: (event) => event.target.closePopup(),
      }}
    >
      <Popup>
        <p
          className='tooltip'
        >
          {title}
        </p>
      </Popup>
    </Marker>
  );
};

export default ProjectMarker;
