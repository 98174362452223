import React from 'react'
import { useProject, useAppDispatch } from '../../../../hooks/redux';
import { setCurrent } from '../../../../store/modules/Project';
import { updateActiveModal } from '../../../../store/modules/Common';
import ProjectMarker from './ProjectMarker';

const Projects = () => {
  const { projectsList } = useProject();
  const dispatch = useAppDispatch();

  return (
    <div>
      {projectsList?.map((project) => (
          <ProjectMarker
            key={project._id}
            lat={project.center?.lat}
            lng={project.center?.lng}
            title={project.name}
            onSelect={() => {
              dispatch(setCurrent(project.versions?.[0]._id));
              dispatch(updateActiveModal('project'));
            }}
          />
        ))}
    </div>
  )
}

export default Projects