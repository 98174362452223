import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { EBuildingStatus } from '../../../../../services/tiles.service';
import Select from '../../../../ui/Inputs/Select';

import './style.scss';
import Button from '../../../../ui/Button';
import Icon from '../../../../ui/Icon';

export interface StatusForm {
  building_status_after: EBuildingStatus;
  building_status_before: EBuildingStatus;
}

const options = Object.entries(EBuildingStatus).map(([label, key]) => ({
  label,
  value: key,
}));

interface IProps {
  onSubmit: (values: any) => void;
  onClose: () => void;
}

const ChangeStatusForm = ({ onSubmit, onClose }: IProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      building_status_after: undefined,
      building_status_before: undefined,
    }
  });

  useEffect(() => {
    reset({ building_status_after: undefined, building_status_before: undefined })
  }, [reset])

  return (
    <form className="change-status-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="change-status-form-header">
        <p className="change-status-form-label">Change building status</p>
      </div>

      <div className="change-status-form-body">
        <Controller
          control={control}
          name="building_status_before"
          render={({ field }) => (
            <Select
              label="Building Status Before"
              options={options}
              onChange={(e) => {
                field.onChange(e);
              }}
              error={errors.building_status_before?.message?.toString()}
              value={field.value}
            />
          )}
        />

        <Controller
          control={control}
          name="building_status_after"
          render={({ field }) => (
            <Select
              label="Building Status After"
              options={options}
              onChange={(e) => {
                field.onChange(e);
              }}
              error={errors.building_status_after?.message?.toString()}
              value={field.value}
            />
          )}
        />
      </div>

      <div className="change-status-form-footer">
        <Button className="clean-main-btn btn btn-cancel" onClick={onClose}>
          <Icon name="cancel" color="white" />
          <span>Cancel</span>
        </Button>
        <Button className="btn main-btn" type='submit'>
          <Icon name="save" />
          Save
        </Button>
      </div>
    </form>
  );
};

export default ChangeStatusForm;
