import {
  EAirCraftCategory,
  EAirCraftSubCategory,
  EGroundCategory,
  EGroundSubCategory,
  EShipCategory,
  EShipSubCategory,
  EVehicleSuperCategory,
} from '../types/IVehicle';

const FixedWingColors = {
  [EAirCraftSubCategory.Fighter]: '#301934',
  [EAirCraftSubCategory.Bomber]: '#CBC3E3',
  [EAirCraftSubCategory.Medium_Lift]: '#964B00',
  [EAirCraftSubCategory.Heavy_Lift]: '#964B00',
  [EAirCraftSubCategory.Civilian]: '#006400',
  [EAirCraftSubCategory.null]: '',
};

const RotaryWingColors = {
  [EAirCraftSubCategory.Rotary_Wing]: '#90EE90',
};

const CombatantShipColors = {
  [EShipSubCategory.Destroyer]: '#FF0000',
  [EShipSubCategory.Frigates_Corvettes]: '#FF0000',
  [EShipSubCategory.Aircraft_Carrier]: '#FF0000',
  [EShipSubCategory.Amphibious_Warfare_Craft]: '#FF0000',
  [EShipSubCategory.Patrol_Ships]: '#FF0000',
  [EShipSubCategory.Other_Military]: '#FF0000',
};

const NonMilitaryShipColors = {
  [EShipSubCategory.Bulk_Cargo_Ships]: '#FFC0CB',
  [EShipSubCategory.Container_Ships]: '#FFC0CB',
  [EShipSubCategory.POL_Tanker]: '#FFC0CB',
  [EShipSubCategory.Other_Civilian]: '#FFC0CB',
};

const CombatantCraftColors = {
  [EShipSubCategory.Patrol_Ships]: '#FF0000',
  [EShipSubCategory.null]: '#FF0000',
};

const MilitaryVehiclesColors = {
  [EGroundSubCategory.Tank_Self_Propelled_Artillery]: '#FF5733',
  [EGroundSubCategory.Military_Truck]: '#FF5733',
  [EGroundSubCategory.Trailer_Or_Artillery]: '#FF5733',
};

const CivilianVehiclesColors = {
  [EGroundSubCategory.null]: '#FFD580',
  [EGroundSubCategory.Truck]: '#FFD580',
};

const RailcarColors = {
  [EGroundSubCategory.Flat_Railcar]: '#00008B',
  [EGroundSubCategory.General_Railcar]: '#00008B',
};

export const vehicleColors = {
  [EVehicleSuperCategory.Aircraft]: {
    [EAirCraftCategory.Fixed_Wing]: FixedWingColors,
    [EAirCraftCategory.Rotary_Wing]: RotaryWingColors,
  },
  [EVehicleSuperCategory.Ship]: {
    [EShipCategory.Combatant_Ship]: CombatantShipColors,
    [EShipCategory.Non_Military_Ship]: NonMilitaryShipColors,
    [EShipCategory.Combatant_Craft]: CombatantCraftColors,
  },
  [EVehicleSuperCategory.Ground]: {
    [EGroundCategory.Military_Vehicles]: MilitaryVehiclesColors,
    [EGroundCategory.Civilian_Vehicles]: CivilianVehiclesColors,
    [EGroundCategory.Railcar]: RailcarColors,
  },
};

export const getBuildingColor = (type: string) => {
  switch (type) {
    case 'destroyed':
      return '#ff5252';
    case 'damaged':
      return '#e69138';
    case 'undamaged':
      return '#6aa84f';
    case 'No After Footprint Found':
      return '#c603fc';
    case 'No After Imagery to Process':
      return '#343aeb';
    case 'others':
      return '#3d85c6';
    default:
      return '#3d85c6';
  }
};