import axios from '../utils/axios';

const UserService = {
  async initialization() {
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      const response = await axios.get('/api/v1/users/profile');
      return response.data.user;
    }

    return null;
  },

  async login(body: { email: string; password: string }) {
    const response = await axios.post('/api/v1/auth/login', body);

    if (response.data?.accessToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${response.data.accessToken}`;
      localStorage.setItem('accessToken', response.data.accessToken);
    }

    return response.data.user;
  },

  logout() {
    localStorage.removeItem('accessToken');
    window.location.reload();
  },
};

export default UserService;
