import React from 'react'
import './style.scss';
import Button from '../ui/Button';

interface Iprops {
  ref: any;
  title: string;
  text: string;
  onCancel: () => void;
  onConfirm: () => void;
  warning?: boolean;
}

const ConfirmationModal = ({ ref, title, text, onCancel, onConfirm, warning = true }: Iprops) => {
  return (
    <div className='confirmation-modal-wrapper'>
      <div className="confirmation-modal" ref={ref}>
        <div className="confirmation-modal-header">
          {title}
        </div>
        <div className={`confirmation-modal-body ${warning === true ? 'warning' : ''}`}>
          {text}
        </div>
        <div className="confirmation-modal-footer">
          <Button
            icon="cancel"
            className="project-folder-input__footer-btn cancel"
            color="blue"
            iconRight={false}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            icon="check"
            className="project-folder-input__footer-btn save"
            color="black"
            iconRight={false}
            onClick={onConfirm}
          >
            Yes
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationModal