import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import cn from 'classnames';
import { MapContainer, Pane, TileLayer } from 'react-leaflet'
import L, { Polygon } from 'leaflet';
import { polygon as turfPolygon } from '@turf/turf';
import { GeoJSONGeometry } from 'wellknown';
import { useDispatch } from 'react-redux';
import Projects from '../Projects';
import MLObjectsLayer from '../MLObjectLayer';
import MapHandler from '../MapHandler';
import { IProject, IVersion } from '../../../../types/IProject';
import LeafletDraw from '../LeafletDraw';
import LeafletRuler from '../MapTools/LeafletRuler';
import LeafletArea from '../MapTools/LeafletArea';
import { Loading } from '../../../Loading';
import wktConvert from '../../../../helpers/wktConvert';
import './style.scss';
import TilesService, { ITileProperties } from '../../../../services/tiles.service';
import MultipleControlPanel from '../MultipleControlPanel';
import { useCommon } from '../../../../hooks/redux';
import { setMLDrawingMode } from '../../../../store/modules/Common';

export interface IMainMapProps {
  styleUrl: string;
  token: string;
  currentProj?: IProject;
  defaultProps: {
    center: L.LatLng,
    zoom: number,
  };
  showRaster: boolean;
  showGrid: boolean;
  isVisible: boolean;
  drawing: boolean;
  setDrawing: React.Dispatch<React.SetStateAction<boolean>>;
  url: (url: string) => string;
  unit: string;
  ruler: boolean;
  area: boolean;
  areaSelect: {
    active: boolean;
    toggle: (status: boolean) => void;
  };
  setMainMap: React.Dispatch<React.SetStateAction<L.Map | null>>;
  currentVer: IVersion | null,
  selectedPolys: ITileProperties[] | null,
  setSelectedPolys: React.Dispatch<React.SetStateAction<ITileProperties[] | null>>
}

const MainMap = ({
  styleUrl,
  currentVer,
  areaSelect,
  showRaster,
  showGrid,
  unit,
  ruler,
  area,
  token,
  currentProj,
  defaultProps,
  isVisible,
  drawing,
  setDrawing,
  url,
  setMainMap,
  selectedPolys,
  setSelectedPolys,
}: IMainMapProps) => {
  const dispatch = useDispatch();

  const { areaSelecting, mlDrawingMode, polygonSelecting, typeDrawingMode } = useCommon();
  const editableFGRef = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const [drawnLayer, setDrawnLayer] = useState<Polygon<any> | null>(null);
  const [currentEditShape, setCurrentEditShape] = useState<any>(null);
  const [tile, setTile] = useState<any>();

  const handleAreaSelected = useCallback(async (layer: any) => {
    try {
      setLoading(true);

      const poly = turfPolygon(layer.toGeoJSON().geometry.coordinates);
      const wkbGeo = wktConvert(poly.geometry as GeoJSONGeometry);
      const polyInside = await TilesService.getTilesFromArea(currentVer?.geojson_table || "", wkbGeo);
      setSelectedPolys(polyInside);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVer])

  const handleClearDrawnLayer = useCallback(() => {
    dispatch(setMLDrawingMode(null));
    setDrawnLayer(null);
  }, [dispatch])

  const handleStartDrawing = useCallback(() => {
    dispatch(setMLDrawingMode(typeDrawingMode));
    setDrawnLayer(null);
  }, [dispatch, typeDrawingMode])

  const handleCancelSelectArea = useCallback(() => {
    setDrawnLayer(null);
    setSelectedPolys(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showMultipleControl = useMemo(() => {
    if(areaSelecting) {
      return selectedPolys;
    };

    return polygonSelecting;
  }, [areaSelecting, polygonSelecting, selectedPolys])

  return (
    <div className={cn('leaflet-map', { hidden: !isVisible })}>
      {/* Main map */}
      <MapContainer
        center={[32.519875667799425, 15.540105222659697]}
        zoom={defaultProps.zoom}
        style={{ width: '100%', height: '100%' }}
      >
        <Projects />

        <LeafletDraw
          currentEditShape={currentEditShape}
          drawnLayer={drawnLayer}
          setDrawnlayer={setDrawnLayer}
          currentVer={currentVer}
          editableFGRef={editableFGRef}
          onAreaSelected={handleAreaSelected}
          onCancelSelectArea={handleCancelSelectArea}
          handleClearDrawnLayer={handleClearDrawnLayer}
          handleStartDrawing={handleStartDrawing}
          setCurrentEditShape={setCurrentEditShape}
          setSelectedPolys={setSelectedPolys}
          setTile={setTile}
          setLoading={setLoading}
        />

        <Loading shown={loading} showBG={false} />
        <LeafletRuler unit={unit} drawing={drawing} setDrawing={setDrawing} />
        <LeafletArea area={area} unit={unit} />

        <TileLayer
          attribution='© <a href="https://apps.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          url={styleUrl}
          maxZoom={24}
        />

        {showMultipleControl && (
          <MultipleControlPanel
            setLoading={setLoading}
            selectedPolys={selectedPolys || []}
            setSelectedPolys={setSelectedPolys}
            editableFGRef={editableFGRef}
          />
        )}

        {currentProj && currentVer && currentVer?.geojson_table && (
          <Pane name="vector-tile-pane">
            <MLObjectsLayer
              projectType={currentProj.type}
              currentVer={currentVer}
              canInteractive={!ruler && !area && !mlDrawingMode && !areaSelecting}
              url={url(currentVer?.geojson_table)}
              selectedPolys={selectedPolys}
              setSelectedPolys={setSelectedPolys}
              editableFGRef={editableFGRef}
              loading={loading}
              setLoading={setLoading}
              showGrid={showGrid}
              currentEditShape={currentEditShape}
              setCurrentEditShape={setCurrentEditShape}
              tile={tile}
              setTile={setTile}
            />
          </Pane>
        )}

        <MapHandler
          loading={loading}
          setLoading={setLoading}
          showRaster={showRaster}
          currentVer={currentVer}
          setMainMap={setMainMap}
          currentProj={currentProj}
          defaultProps={defaultProps}
        />
      </MapContainer>
    </div>
  );
}

export default memo(MainMap)