// @ts-nocheck

import { Mesh, MeshBasicMaterial, Vector3, Group, CylinderGeometry, TextureLoader } from 'three';
import Label from '../Label/Label';

import { ITelescope } from '../../../types/ITelescope';

export default class Satellite {
  public mainContainer: Group;

  private material: MeshBasicMaterial;

  private mesh: Mesh;

  private model: Mesh;

  public hovered: boolean;

  public selected: boolean;

  private latitude: number;

  private longitude: number;

  private label: Label;

  constructor(public telescope: ITelescope, private telmodel: any) {
    const tlat = (-this.telescope.lat * Math.PI) / 180;
    const tlong = (-this.telescope.lng * Math.PI) / 180;

    this.latitude = tlat + Math.PI / 2;
    this.longitude = tlong;

    this.init();
  }

  public setModel(model) {
    if (!this.model) {
      this.model = model.clone();
      this.model.position.z = -1;

      this.model.traverse((item) => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        item.raycast = () => {};
      });

      this.mesh.add(this.model);
    }
  }

  init() {
    this.mainContainer = new Group();
    this.mainContainer.name = 'Telescope';

    this.material = new MeshBasicMaterial({
      color: '#ddddff',
      transparent: true,
      side: 2,
      opacity: 0.5,
      depthWrite: false,
    });
    // this.mesh = new Mesh(new CylinderGeometry(0.08, 0.03, 2, 16, 1, true).rotateX(-Math.PI / 2), this.material);
    this.mesh = new Mesh(
      new CylinderGeometry(0.3, 0.15, 3, 16, 1, true).rotateX(-Math.PI / 2),
      this.material,
    );

    this.mesh.userData = {
      parent: this,
    };

    this.mainContainer.add(this.mesh);

    const pos = this.setPosition();
    this.mesh.lookAt(pos);
    this.mesh.position.copy(pos);

    // eslint-disable-next-line no-unused-expressions
    this.telmodel && this.setModel(this.telmodel);

    this.setTexture();
    this.setLabel();
  }

  setTexture() {
    new TextureLoader().load('../assets/grad.png', (map) => {
      this.material.map = map;
      this.material.needsUpdate = true;
    });
  }

  hover() {
    if (!this.selected) {
      this.material.color.set('#44ff44');
      this.label.mainContainer.visible = true;
      this.hovered = true;
    }
  }

  reset() {
    if (!this.selected) {
      this.material.color.set('#ddddff');
      this.label.mainContainer.visible = false;
    }
    this.hovered = false;
  }

  select() {
    this.material.color.set('#4444ff');
    this.label.mainContainer.visible = true;
    this.selected = true;
  }

  deselect() {
    this.material.color.set('#ddddff');
    this.label.mainContainer.visible = false;
    this.selected = false;
  }

  setPosition() {
    const r = 11;

    const x = r * Math.cos(this.longitude) * Math.sin(this.latitude);
    const z = r * Math.sin(this.longitude) * Math.sin(this.latitude);
    const y = r * Math.cos(this.latitude);

    return new Vector3(x, y, z);
  }

  setLabel() {
    this.label = new Label(this.telescope.name);
    this.mesh.add(this.label.mainContainer);
    this.label.mainContainer.visible = false;
  }
}
