/* eslint-disable object-shorthand */
/* eslint-disable func-names */
import L from 'leaflet';

async function fetchImage(url, callback, headers, abort) {
  try {
    const _headers = {};
    if (headers) {
      headers.forEach(h => {
        if (h?.header) {
          _headers[h.header] = h.value;
        }
      });
    }
    const controller = new AbortController();
    const { signal } = controller;
    if (abort) {
      abort.subscribe(() => {
        controller.abort();
      });
    }

    let fetchOptions = {};

    if (Object.keys(_headers).length > 0) {
      fetchOptions = {
        method: "GET",
        headers: _headers,
        mode: "cors",
        signal: signal
      }
    } else {
      fetchOptions = {
        method: "GET",
        signal: signal
      }
    }

    const f = await fetch(url || "", fetchOptions);
    const tifUrl = await f.json();
    callback(f, tifUrl.url);
  } catch (error) {
    console.log(error);
  }
}

L.TileLayerWithHeaders = L.TileLayer.extend({
  initialize: function (url, options) {
    L.TileLayer.prototype.initialize.call(this, url || "", options);
  },
  createTile(coords, done) {
    const url = this.getTileUrl(coords);
    const img = document.createElement("img");
    img.setAttribute("role", "presentation");
    if(coords && coords.z > 18) return img

    fetchImage(
      url,
      (resp, tifUrl) => {
        if(resp.size === 0){
          img.src = L.Util.emptyImageUrl;
          done(null, img);
        }
        else if(tifUrl) {
          img.src = tifUrl;
          img.onerror = function(err, image){
            img.src = L.Util.emptyImageUrl;
          }
          done(null, img);
        } else {
          img.src = L.Util.emptyImageUrl;
          done(null, img);
        }
      },
      this.headers,
      this.abort
    );
    return img;
  }
});

L.tileLayerWithHeader = function (url, options, headers, abort) {
  return new L.TileLayerWithHeaders(url, options, headers, abort);
};