import { Units } from "../views/Home/components/ProjectInfo/constants";

export const kmConversion = {
  [Units.METERS]: 1000,
  [Units.FEET]: 3280.84,
  [Units.KILOMETERS]: 1,
  [Units.MILES]: 0.621371,
}

const getDistance = (distance: number, unit: string) => {
  //km to other unit
  switch(unit) {
    case Units.METERS:
      return distance * kmConversion[Units.METERS];
    case Units.KILOMETERS:
      return distance * kmConversion[Units.KILOMETERS];
    case Units.FEET:
      return distance * kmConversion[Units.FEET];
    case Units.MILES:
      return distance * kmConversion[Units.MILES];
    default:
      return distance;
  }
}

export default getDistance;