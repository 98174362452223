/* eslint-disable @typescript-eslint/no-explicit-any */
import 'leaflet.vectorgrid';

import ReactDOM from 'react-dom/client';

import React, { useEffect, useState, memo } from 'react';
import L, { LeafletMouseEvent } from 'leaflet';
import { useMap } from 'react-leaflet';
import squareGrid from '@turf/square-grid';
import { featureEach, polygon as turfPolygon, area as turfArea } from '@turf/turf';
import { toast } from 'react-toastify';
import { GeoJSONGeometry } from 'wellknown';
import wktConvert from '../../../../helpers/wktConvert';

import trimObjectKey from '../../../../helpers/trimObjectKey';
import TileInfo from '../ModelInfo/TileInfo';
import { useAppDispatch, useCommon, useProject } from '../../../../hooks/redux';
import { bbox, geojson, getUpdateGeoQuery, update } from '../../../../helpers/vectorTiles';
import axiosInstance from '../../../../utils/axios';
import TilesService, { ITileProperties } from '../../../../services/tiles.service';

import './style.scss';
import { IVersion, ProjectType } from '../../../../types/IProject';
import { setMLDrawingMode } from '../../../../store/modules/Common';
import { buildingOptions, vehicleOptions } from './options';
import VehicleInfo from '../ModelInfo/VehicleInfo';
import { IVehicleProperties } from '../../../../types/IVehicle';

export interface IMLObjectsLayer {
  projectType: ProjectType;
  url: string;
  canInteractive: boolean;
  editableFGRef: React.MutableRefObject<any>;
  selectedPolys: ITileProperties[] | null;
  setSelectedPolys: React.Dispatch<React.SetStateAction<ITileProperties[] | null>>;
  currentVer: IVersion;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  currentEditShape: any;
  setCurrentEditShape: React.Dispatch<any>;
  showGrid: boolean;
  tile: any;
  setTile: React.Dispatch<any>;
}
// Required by leaflet.vectorgrid
(L.DomEvent as any)._fakeStop = () => {
  return true;
};

let clicked = 0;

const excludes = ['ogc_fid', 'srid'];

const MLObjectsLayer = ({
  projectType,
  loading,
  setLoading,
  showGrid,
  currentVer,
  canInteractive,
  url,
  editableFGRef,
  selectedPolys,
  setSelectedPolys,
  currentEditShape,
  setCurrentEditShape,
  tile,
  setTile,
}: IMLObjectsLayer) => {
  const dispatch = useAppDispatch();
  const map = useMap();
  const [vectorTileLayer, setVectorTileLayer] = useState<any>();
  const [pos, setPos] = useState<L.LatLng>();
  const [zoomedToCenter, setZoomedToCenter] = useState<string | null>(null);
  const [gridLayer, setGridLayer] = useState<any>(null);

  const { activeShapes } = useProject();
  const { mlDrawingMode, polygonSelecting, typeDrawingMode } = useCommon();

  const handleOnclickVectorTile = async (e: LeafletMouseEvent) => {
    try {
      if(polygonSelecting) {
        const existPoly = selectedPolys?.find(poly => poly.ogc_fid === e.layer.properties.ogc_fid);
        if(existPoly) {
          setSelectedPolys(prev => {
            if(prev) {
              return prev.filter((el) => el.ogc_fid !== e.layer.properties.ogc_fid);
            }
            return null;
          })
        } else {
          setSelectedPolys(prev => {
            if(prev) {
              return prev.concat([e.layer.properties]);
            }
            return [e.layer.properties];
          })
        }
        return;
      };
      clicked = 1;
      // This hack to avoid duplicate polygon when double click
      if (loading) return;
      setLoading(true);
      if (currentEditShape) {
        map.removeLayer(currentEditShape);
        setCurrentEditShape(null);
        setTile(null);
      }
      if (clicked === 0) return;
      const response = await axiosInstance.get(geojson(currentVer?.geojson_table || "", e.layer.properties.ogc_fid));
      let poly = null;
      if (response.data.features?.[0]?.properties?.type_polygon === 'rectangle') {
        poly = L.rectangle(
          response.data.features[0].geometry.coordinates[0].map((el: any) => [el[1], el[0]])
        ).addTo(map);
      } else {
        poly = L.polygon(
          response.data.features[0].geometry.coordinates[0].map((el: any) => [el[1], el[0]]),
          ).addTo(map);
      }
      (poly as any).editing.enable();
      setTile(e.layer);
      setPos(e.latlng);
      setCurrentEditShape(poly);
    } catch (error) {
      throw new Error(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };

  const handleAddLayer = async (currentShape: any) => {
    try {
      setLoading(true);
      if (vectorTileLayer) {
        map.removeLayer(vectorTileLayer);
        setVectorTileLayer(null);
      }
      let layer = null;

      switch(projectType) {
        case ProjectType.building:
          layer = (L as any).vectorGrid.protobuf(
            `${url}&time=${new Date().getTime()}`,
            buildingOptions({
              layerName: currentVer?.geojson_table || '',
              currentShape,
              activeShapes,
              selectedPolys,
              tile,
              canInteractive,
            }),
          );
        break;
        case ProjectType.vehicle:
          layer = (L as any).vectorGrid.protobuf(
            `${url}&time=${new Date().getTime()}`,
            vehicleOptions({
              layerName: currentVer?.geojson_table || '',
              currentShape,
              activeShapes,
              selectedPolys,
              tile,
              canInteractive,
            }),
          );
        break;
        default:
          layer = (L as any).vectorGrid.protobuf(
            `${url}&time=${new Date().getTime()}`,
            buildingOptions({
              layerName: currentVer?.geojson_table || '',
              currentShape,
              activeShapes,
              selectedPolys,
              tile,
              canInteractive,
            }),
          );
      }

      layer.addTo(map);
      layer.bringToFront();

      layer.on('dblclick', () => {
        if (currentEditShape) {
          (currentEditShape as any).editing.disable();
          map.removeLayer(currentEditShape);
          setCurrentEditShape(null);
          setTile(null);
        }
        clicked = 0;
      });
      setVectorTileLayer(layer);
      layer.on('click', handleOnclickVectorTile);
      if (editableFGRef.current) {
        editableFGRef.current.addLayer(layer);
      }

      if (currentVer?.geojson_table && zoomedToCenter !== currentVer._id) {
        const tableBBox = await axiosInstance.get(bbox(currentVer?.geojson_table));
        const bboxCoordinates = tableBBox.data[0].bbox.match(/\d+\.\d+/g).map(parseFloat);
        const bounds = [
          [bboxCoordinates[1], bboxCoordinates[0]],
          [bboxCoordinates[3], bboxCoordinates[2]],
        ];

        const llBounds = L.latLngBounds(bounds as any);


        setZoomedToCenter(currentVer._id);

        (map.options as any).geoJsonBBox = llBounds;
        map.setView(llBounds.getCenter());
      }
    } catch (error) {
      throw new Error(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  }

  const handleCleanAfterSubmit = (objectPortal: ReactDOM.Root) => {
    setTile(null);
    objectPortal.unmount();
    (currentEditShape as any).editing.disable();
    map.removeLayer(currentEditShape);
    handleAddLayer(currentEditShape);
    setCurrentEditShape(null);
    setLoading(false);
    clicked = 0;
  }

  const handleCleanAfterDelete = (objectPortal: ReactDOM.Root) => {
    setTile(null);
    objectPortal.unmount();
    (currentEditShape as any).editing.disable();
    map.removeLayer(currentEditShape);
    handleAddLayer(currentEditShape);
    setCurrentEditShape(null);
    setLoading(false);
    clicked = 0;

    (map.options as any).redrawing = false;
    (map.options as any).shapeRedrawProperties = null;

    dispatch(setMLDrawingMode(null));
  }

  const handleOnCancelEdit = (objectPortal: ReactDOM.Root) => {
    setTile(null);
    objectPortal.unmount();
    (currentEditShape as any).editing.disable();
    map.removeLayer(currentEditShape);
    handleAddLayer(null);
    setCurrentEditShape(null);
    setLoading(false);
    clicked = 0;
  }

  const handleSaveVehicle = async (values: IVehicleProperties, objectPortal: ReactDOM.Root) => {
    try {
      const latlngs = (currentEditShape as any)?.getLatLngs()?.[0];
      const lnglats = latlngs.map((el: L.LatLng) => [el.lng, el.lat]);
      const poly = turfPolygon([lnglats.concat([lnglats[0]])]);
      values.wkb_geometry = getUpdateGeoQuery(wktConvert(poly.geometry as GeoJSONGeometry));
      const newBBox: L.LatLngBounds = currentEditShape.getBounds();
      values.annotations_bbox_geo = JSON.stringify([
        [
          newBBox.getSouthWest().lng,
          newBBox.getSouthWest().lat,
        ],
        [
          newBBox.getNorthEast().lng,
          newBBox.getNorthEast().lat,
        ]
      ]);

      let query = Object.keys(values).map((key: string) => {
        if (excludes.includes(key)) return '';
        if (key === 'wkb_geometry') {
          return `${key}=${values[key as keyof typeof values]}`;
        }
        return `"${key}"='${values[key as keyof typeof values]}'`;
      });

      query = query.filter((el: string) => el !== '');

      await TilesService.updateRow({
        table: currentVer?.geojson_table || "",
        query: query.join(','),
        ogc_fid: values.ogc_fid,
      });

      handleCleanAfterSubmit(objectPortal);

      toast.success('Update vehicle information successfully');
    } catch (error) {
      toast.error('Update vehicle information failed');
      throw new Error(error instanceof Error ? error.message : 'Uncaught Error');
    }
  };

  const handleRedraw = () => {
    (map.options as any).redrawing = true;
    (map.options as any).shapeRedrawProperties = trimObjectKey(tile.properties);
    if (currentEditShape) {
      currentEditShape.editing.disable();
      currentEditShape.removeFrom(map);
    }
    dispatch(setMLDrawingMode(tile.properties.type_polygon || 'polygon'));
  };

  const handleDelete = async(ogc_fid: string, objectPortal: ReactDOM.Root) => {
    try {
      if (!currentEditShape || !currentVer?.geojson_table) return;
      await TilesService.deleteRow(currentVer.geojson_table, ogc_fid);

      handleCleanAfterDelete(objectPortal);
      toast.success('Delete building successfully');
    } catch (error) {
      toast.error(JSON.stringify(error));
    }
  }

  useEffect(() => {
    let markers: L.Marker<any>[] = [];

    const removeLayer = () => {
      markers.forEach(el => {
        el.removeFrom(map);
        map.removeLayer(el);
      })

      markers = [];

      if(gridLayer) {
        gridLayer.removeFrom(map);
        setGridLayer(null);
      }
      map.eachLayer(el => {
        if((el as any).gridMarker) {
          el.removeFrom(map);
          map.removeLayer(el);
        }
        if((el as any).gridLayer) {
          el.removeFrom(map);
          map.removeLayer(el);
        }
      })
    }

    removeLayer();

    const handleGenerateGrid = async () => {
      try {
        setLoading(true);
        const cellSide = 3.4;
        const tableBBox = await axiosInstance.get(bbox(currentVer?.geojson_table));
        const bboxCoordinates = tableBBox.data[0].bbox.match(/\d+\.\d+/g).map(parseFloat);
        const bounds = [
          [bboxCoordinates[1], bboxCoordinates[0]],
          [bboxCoordinates[3], bboxCoordinates[2]],
        ];

        const llBounds = L.latLngBounds(bounds as any);

        if (!llBounds) return;


        // Square grid does not fully cover the bounding box,
        // sometime it's missing polygon so we need to extend it to have the last rectangle
        const extended = llBounds.pad(0.2);
        const turfBbox = [
          extended.getSouthWest().lng,
          extended.getSouthWest().lat,
          extended.getNorthEast().lng,
          extended.getNorthEast().lat,
        ];

        const turfOptions = { units: 'kilometers' };

        const grid = squareGrid(turfBbox as any, cellSide, turfOptions as any);
        const layer = L.geoJSON(grid, {
          style: { color: '#3ac1f0', weight: 1, fillColor: '#cccccc', fill: false },
        });
        layer.addTo(map);
        (layer as any).gridLayer = true;
        setGridLayer(layer);

        const handleZoomend = (e: any) => {
          if (map.getZoom() > 10 && showGrid) {
            if (gridLayer) {
              map.eachLayer((el) => {
                if ((el as any).gridLayer) {
                  el.removeFrom(map);
                  map.removeLayer(el);
                }
              });
              gridLayer.addTo(map);
              featureEach(grid, (cell, idx) => {
                const geoJSON = L.geoJSON(cell);
                const gridIdx = L.divIcon({
                  className: 'custom-icon',
                  html: `<div class="marker-label" style="padding: ${
                    idx < 9 ? '0 10px' : '0 5px'
                  }">${idx + 1}</div>`,
                  iconSize: [53, 42],
                });
                const marker = L.marker(geoJSON.getBounds().getNorthWest(), {
                  icon: gridIdx,
                } as any);
                (marker as any).gridMarker = true;
                marker.addTo(map);
                markers.push(marker);
              });
            }
          } else {
            removeLayer();
          }
        };

        featureEach(grid, (cell, idx) => {
          const geoJSON = L.geoJSON(cell);
          const gridIdx = L.divIcon({
            className: 'custom-icon',
            html: `<div class="marker-label" style="padding: ${idx < 9 ? '0 10px' : '0 5px'}">${
              idx + 1
            }</div>`,
            iconSize: [53, 42],
          });
          const marker = L.marker(geoJSON.getBounds().getNorthWest(), { icon: gridIdx });
          (marker as any).gridMarker = true;
          marker.addTo(map);
          markers.push(marker);
        });
        map.on('zoomend', handleZoomend);
      } catch (error) {
        throw new Error(JSON.stringify(error));
      } finally {
        setLoading(false);
      }
    };
    if (showGrid && zoomedToCenter) {
      handleGenerateGrid();
    } else {
      if(gridLayer) {
        gridLayer.removeFrom(map);
        setGridLayer(null);
      }
      map.eachLayer(el => {
        if((el as any).gridMarker) {
          el.removeFrom(map);
          map.removeLayer(el);
        }
        if((el as any).gridLayer) {
          el.removeFrom(map);
          map.removeLayer(el);
        }
      })
    }

    return () => {
      if(gridLayer) {
        gridLayer.removeFrom(map);
        setGridLayer(null);
      }
      map.eachLayer(el => {
        if((el as any).gridMarker) {
          el.removeFrom(map);
          map.removeLayer(el);
        }
        if((el as any).gridLayer) {
          el.removeFrom(map);
          map.removeLayer(el);
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, showGrid, zoomedToCenter, currentVer])

  // Handle edit building
  useEffect(() => {
    const objectPortal = ReactDOM.createRoot(
      document.getElementById('project-object-portal') as HTMLElement,
    );
    const renderModalInfo = () => {
      switch (projectType) {
        case ProjectType.building:
          objectPortal.render(
            <TileInfo
              canEdit
              table={currentVer?.geojson_table || ''}
              currentEditShape={currentEditShape}
              properties={trimObjectKey(tile.properties)}
              onCancel={() => handleOnCancelEdit(objectPortal)}
              onSubmit={() => {
                setTile(null);
                objectPortal.unmount();
                (currentEditShape as any).editing.disable();
                map.removeLayer(currentEditShape);
                handleAddLayer(currentEditShape);
                setCurrentEditShape(null);
                setLoading(false);
                clicked = 0;
              }}
              onRedraw={handleRedraw}
              onDelete={() => {
                (map.options as any).redrawing = false;
                (map.options as any).shapeRedrawProperties = null;

                dispatch(setMLDrawingMode(null));
              }}
            />,
          );
          break;
        case ProjectType.vehicle:
          objectPortal.render(
            <VehicleInfo
              canEdit
              properties={tile.properties}
              onCancel={() => handleOnCancelEdit(objectPortal)}
              onSubmit={(values: IVehicleProperties) => handleSaveVehicle(values, objectPortal)}
              onRedraw={handleRedraw}
              onDelete={(ogc_fid: string) => handleDelete(ogc_fid, objectPortal)}
            />,
          );
          break;
        default:
          objectPortal.render(
            <TileInfo
              canEdit
              table={currentVer?.geojson_table || ''}
              currentEditShape={currentEditShape}
              properties={trimObjectKey(tile.properties)}
              onCancel={() => {
                setTile(null);
                objectPortal.unmount();
                (currentEditShape as any).editing.disable();
                map.removeLayer(currentEditShape);
                handleAddLayer(null);
                setCurrentEditShape(null);
                setLoading(false);
                clicked = 0;
              }}
              onSubmit={() => {
                setTile(null);
                objectPortal.unmount();
                (currentEditShape as any).editing.disable();
                map.removeLayer(currentEditShape);
                handleAddLayer(currentEditShape);
                setCurrentEditShape(null);
                setLoading(false);
                clicked = 0;
              }}
              onRedraw={() => {
                (map.options as any).redrawing = true;
                (map.options as any).shapeRedrawProperties = trimObjectKey(tile.properties);
                if (currentEditShape) {
                  currentEditShape.editing.disable();
                  currentEditShape.removeFrom(map);
                }
                dispatch(setMLDrawingMode(tile.properties.type_polygon || 'polygon'));
              }}
              onDelete={() => {
                (map.options as any).redrawing = false;
                (map.options as any).shapeRedrawProperties = null;

                dispatch(setMLDrawingMode(null));
              }}
            />,
          );
          break;
      }
    }
    if (currentEditShape && tile) {
      renderModalInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, currentEditShape, currentVer, tile]);

  useEffect(() => {
    if (!map) return;
    const vectorPane = map.getPanes()[`vector-tile-pane`];

    vectorPane.style.zIndex = '400';
    handleAddLayer(currentEditShape);
    return () => {
      if (vectorTileLayer) {
        map.removeLayer(vectorTileLayer);
      }
      map.eachLayer((e) => {
        if ((e.options as any).isVectorGrid) {
          map.removeLayer(e);
        }
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    map,
    activeShapes,
    mlDrawingMode,
    polygonSelecting,
    url,
    tile,
    currentVer,
    selectedPolys,
    canInteractive,
    currentEditShape,
  ]);

  useEffect(() => {
    const zoom = map.getZoom();
    if (vectorTileLayer) {
      if (zoom < 16) {
        map.removeLayer(vectorTileLayer);
      } else {
        vectorTileLayer.addTo(map);
      }
    }
  }, [map, vectorTileLayer]);

  useEffect(() => {
    const handleMinZoom = () => {
      const zoom = map.getZoom();
      if (vectorTileLayer) {
        if (zoom < 16) {

          map.removeLayer(vectorTileLayer);
        } else {
          vectorTileLayer.addTo(map);
        }
      }
    };
    map.on('zoom', handleMinZoom);

    return () => {
      map.off('zoom', handleMinZoom);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, vectorTileLayer]);

  return <div />;
};

export default memo(MLObjectsLayer);
