import React from 'react'

import './style.scss';
import Button from '../../../../ui/Button';
import Icon from '../../../../ui/Icon';

interface IProps {
  onClose: () => void;
  onDelete: () => void;
}

const ConfirmationForm = ({ onClose, onDelete }: IProps) => {
  return (
    <div className='confirmation-form'>
      <div className='confirmation-form-title'>
        <p>Delete buildings</p>
      </div>
      <div className="confirmation-form-body">
        <p>Are you sure to remove selected buildings ?</p>
      </div>
      <div className="confirmation-form-footer">
        <Button className="app-btn btn clean-main-btn" onClick={onClose}>
          <span>Cancel</span>
        </Button>
        <Button className="btn btn-delete main-btn" type='submit' onClick={onDelete}>
          Delete
        </Button>
      </div>
    </div>
  )
}

export default ConfirmationForm;