import { Units } from "../views/Home/components/ProjectInfo/constants";

export default function getUnitText(unit: string) {
  switch(unit) {
    case Units.FEET:
      return 'feet';
    case Units.KILOMETERS:
      return 'km';
    case Units.METERS:
      return 'meter(s)';
    case Units.MILES:
      return 'mile(s)';
    default:
      return '';
  }
}