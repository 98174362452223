import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Router from './router';
import ScrollToTop from './components/ScrollTopRoute';
import PlanetEarth from './components/PlanetEarth/PlanetEarth';
import { setupStore } from './store';

import "leaflet/dist/leaflet.css";

const store = setupStore();

function App() {
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop />
          <Router />
        </BrowserRouter>
        <ToastContainer
          className="react-notification"
          position="bottom-center"
          autoClose={5000}
          newestOnTop={false}
          rtl={false}
          hideProgressBar
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </Provider>
    </>
  );
}

export default App;
