import React from 'react';
import Draggable from 'react-draggable';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  EAirCraftCategory,
  EAirCraftSubCategory,
  EAlgorithmType,
  EGroundCategory,
  EGroundSubCategory,
  EShipCategory,
  EShipSubCategory,
  EVehicleSuperCategory,
  IVehicleProperties,
  vehicleTree,
} from '../../../../../types/IVehicle';
import Button from '../../../../ui/Button';

import './style.scss';
import validationSchema from './form.validator';
import TextField from '../../../../TextField';
import Select from '../../../../ui/Inputs/Select';

export interface IVehicleInfoProps {
  canEdit?: boolean;
  properties?: Partial<IVehicleProperties>;
  onCancel?: any;
  onSubmit?: any;
  onDelete?: (ogc_fid: string) => void;
  onRedraw?: any;
}

const superCategoryOptions = Object.entries(EVehicleSuperCategory).map(([label, key]) => ({
  value: key,
  label: key,
}));

const algorithmTypeOptions = Object.entries(EAlgorithmType).map(([label, key]) => ({
  value: key,
  label: label.replaceAll('_', ' '),
}));

const VehicleInfo = ({
  canEdit,
  properties,
  onCancel,
  onSubmit,
  onDelete,
  onRedraw,
}: IVehicleInfoProps) => {
  const [disableDrag, setDiableDrag] = React.useState(false);
  const { handleSubmit, control, reset, formState, setValue, watch } = useForm<IVehicleProperties>({
    resolver: yupResolver(validationSchema),
    defaultValues: properties || {},
  });
  const { errors } = formState;

  const superCat = watch('categories_supercategory');
  const category = watch('categories_category');

  const categoryOptions = React.useMemo(() => {
    let options: any[] = [];
    switch (superCat) {
      case EVehicleSuperCategory.Aircraft:
        options = Object.entries(EAirCraftCategory).map(([label, key]) => ({
          value: key,
          label,
        }));
        break;
      case EVehicleSuperCategory.Ship:
        options = Object.entries(EShipCategory).map(([label, key]) => ({
          value: key,
          label,
        }));
        break;
      case EVehicleSuperCategory.Ground:
        options = Object.entries(EGroundCategory).map(([label, key]) => ({
          value: key,
          label,
        }));
        break;
      default:
        options = [];
        break;
    }

    return options.map(el => ({
      ...el,
      label: el.label.replace(/[-_]/g, " ")
    }));
  }, [superCat]);

  const subCategoryOptions = React.useMemo(() => {
    let options: any = [];
    if(superCat && category) {
      //@ts-expect-error
      options = Object.entries(vehicleTree[superCat][category]).map(([label, key]) => ({
        value: key,
        label,
      }));
    } else {
      options = [];
    }

    return options.map((el: any) => ({
      ...el,
      label: el.label.replace(/[-_]/g, " ")
    }));
  }, [category, superCat]);

  const handleDelete = () => {
    if(properties?.ogc_fid && onDelete) {
      onDelete(properties?.ogc_fid);
    };
  };

  const handleChangeShip = (value?: string) => {
    switch (value) {
      case EVehicleSuperCategory.Aircraft:
        setValue('categories_category', EAirCraftCategory.Fixed_Wing);
        setValue('categories_subcategory', EAirCraftSubCategory.Fighter);
        break;
      case EVehicleSuperCategory.Ship:
        setValue('categories_category', EShipCategory.Combatant_Craft);
        setValue('categories_subcategory', EShipSubCategory.Patrol_Ships_Medium);
        break;
      case EVehicleSuperCategory.Ground:
        setValue('categories_category', EGroundCategory.Civilian_Vehicles);
        setValue('categories_subcategory', EGroundSubCategory.null);
        break;
      default:
        setValue('categories_category', EGroundCategory.Civilian_Vehicles);
        setValue('categories_subcategory', EGroundSubCategory.null);
        break;
    }
  };

  const handleChangeCat = (value?: string) => {
    let options = [];
    if(superCat && value) {
      //@ts-expect-error
      options = Object.entries(vehicleTree[superCat][value]).map(([label, key]) => ({
        value: key,
        label,
      }));
    } else {
      options = Object.entries(vehicleTree[EVehicleSuperCategory.Aircraft][EAirCraftCategory.Fixed_Wing]).map(([label, key]) => ({
        value: key,
        label,
      }));
    };

    setValue('categories_subcategory', options[0].value as unknown as any);
  };

  return (
    <Draggable disabled={disableDrag}>
      <div className="vehicle-info-wrapper" id="tile-info-modal">
        <div className="vehicle-info-header">Vehicle Information</div>
        <div className="vehicle-info-body">
          {properties?.ogc_fid && (
            <div className="vehicle-info-field">
              <Controller
                control={control}
                name="ogc_fid"
                render={({ field }) => (
                  <TextField
                    isDisabled
                    label="ID"
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.ogc_fid}
                    placeholder="ID"
                  />
                )}
              />
            </div>
          )}

          <div className="vehicle-info-field">
            <Controller
              control={control}
              name="categories_supercategory"
              render={({ field }) => (
                <Select
                  isDisabled={!canEdit}
                  options={superCategoryOptions}
                  value={field.value}
                  onChange={(value) => {
                    handleChangeShip(value);
                    field.onChange(value);
                  }}
                  label="Super Category"
                  error={errors.categories_supercategory?.message}
                />
              )}
            />
          </div>

          <div className="vehicle-info-field">
            <Controller
              control={control}
              name="categories_category"
              render={({ field }) => (
                <Select
                  isDisabled={!canEdit}
                  options={categoryOptions}
                  value={field.value}
                  onChange={(value) => {
                    handleChangeCat(value);
                    field.onChange(value);
                  }}
                  label="Category"
                  error={errors.categories_category?.message}
                />
              )}
            />
          </div>

          <div className="vehicle-info-field">
            <Controller
              control={control}
              name="categories_subcategory"
              render={({ field }) => (
                <Select
                  isDisabled={!canEdit}
                  options={subCategoryOptions as any}
                  value={field.value}
                  onChange={field.onChange}
                  label="Subcategory"
                  error={errors.categories_subcategory?.message}
                />
              )}
            />
          </div>

          <div className="vehicle-info-field">
            <Controller
              control={control}
              name="images_filename"
              render={({ field }) => (
                <TextField
                  isDisabled={!canEdit}
                  label="Image Filename"
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.images_filename}
                  placeholder="Image filename"
                />
              )}
            />
          </div>

          {properties?.ogc_fid ? (
            <div className="vehicle-info-field">
              <Controller
                control={control}
                name="annotator_algorithm_type"
                render={({ field }) => (
                  <Select
                    isDisabled={!canEdit || properties?.annotator_algorithm_type === EAlgorithmType.Human_Annotator || properties?.annotator_algorithm_type === EAlgorithmType.Algorithmic_Annotator}
                    options={algorithmTypeOptions}
                    value={field.value}
                    onChange={field.onChange}
                    label="Annotator Algorithm Type"
                    error={errors.annotator_algorithm_type?.message}
                  />
                )}
              />
            </div>
          ) : (
            <div className="vehicle-info-field">
              <Controller
                control={control}
                defaultValue={EAlgorithmType.Human_Annotator}
                name="annotator_algorithm_type"
                render={({ field }) => (
                  <Select
                    isDisabled
                    options={algorithmTypeOptions}
                    value={field.value}
                    onChange={field.onChange}
                    label="Annotator Algorithm Type"
                    error={errors.annotator_algorithm_type?.message}
                  />
                )}
              />
            </div>
          )}
        </div>
        <div className="vehicle-info-footer">
          {onRedraw && (
            <Button className="app-btn main-btn" onClick={onRedraw}>
              Redraw
            </Button>
          )}
          <div className="btn-group">
            <Button className="app-btn clean-main-btn" onClick={onCancel}>
              Cancel
            </Button>
            {canEdit && (
              <>
                <Button className="app-btn btn-delete clean-main-btn" onClick={handleDelete}>
                  Delete
                </Button>
                <Button className="app-btn main-btn" onClick={handleSubmit(onSubmit)}>
                  Save
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </Draggable>
  );
};

export default VehicleInfo;
