export type ViewType = 'planet' | 'map';
export type ActiveModal =
  | 'list'
  | 'search'
  | 'project'
  | 'create-project'
  | 'create-satellite'
  | null;

export enum ECompareStep {
  close = 'close',
  chooseVersion = 'chooseVersion',
  openCompareTool = 'openCompareTool',
}

export type DrawingMode =
  | 'polygon'
  | null
  | 'rectangle';

export interface ICommonStore {
  viewType: ViewType;
  compareStep: ECompareStep;
  typeDrawingMode: DrawingMode;
  mlDrawingMode: DrawingMode;
  activeModal: ActiveModal;
  showNotification: boolean;
  polygonSelecting: boolean;
  drawingMode: null | 'polygon' | 'rectangle';
  areaSelecting: boolean;
  isLoading: boolean;
  error: string;
}
