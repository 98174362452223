// @ts-nocheck

import { Group, CanvasTexture, SpriteMaterial, Sprite } from 'three';

export default class Label {
  private canvas: HTMLCanvasElement;

  public mainContainer: Group;

  private mesh: Sprite;

  private material: SpriteMaterial;

  constructor(private text: string) {
    this.init();
  }

  init() {
    this.mainContainer = new Group();
    this.prepareMesh();
    this.generateText();
  }

  prepareMesh() {
    this.material = new SpriteMaterial({ transparent: true });
    this.mesh = new Sprite(this.material);
    this.mesh.position.set(0, 0.9, 0);
    this.mesh.scale.set(1.5, 1.5, 1.5);
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.mesh.raycast = () => {};
    this.mainContainer.add(this.mesh);
  }

  generateText() {
    this.canvas = document.createElement('canvas');
    this.canvas.width = 400;
    this.canvas.height = 400;

    const ctx = this.canvas.getContext('2d');

    if (ctx) {
      ctx.fillStyle = '#ffffff';
      ctx.font = '122px serif';
      ctx.fillText(this.text.toUpperCase(), 20, 200);

      const texture = new CanvasTexture(this.canvas);
      this.material.map = texture;
      this.material.needsUpdate = true;
    }
  }
}
