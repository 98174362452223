import React from 'react';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import Button from '../ui/Button';
import NavBarSwitch from './Switch';
import { useAppDispatch, useCommon, useNotification, useProject } from '../../hooks/redux';
import { setShowNotification, updateActiveModal } from '../../store/modules/Common';
import { ActiveModal } from '../../types/ICommon';
import UserService from '../../services/user.service';

const NavBar = () => {
  const dispatch = useAppDispatch();
  const { showNotification, activeModal, viewType } = useCommon();
  const { current } = useProject();
  const { notifications } = useNotification();

  const activeNotify =
    viewType === 'planet'
      ? notifications.filter((notification: any) => notification.viewType === 'planet')
      : notifications.filter((notification: any) => notification.viewType === 'map');

  const handleSetModal = (name: ActiveModal) => () => {
    if (name === activeModal) {
      dispatch(updateActiveModal(null));
    } else {
      dispatch(updateActiveModal(name));
    }
  };

  const handleShowNotification = () => dispatch(setShowNotification(!showNotification));

  const logout = () => UserService.logout();

  return (
    <div className="nav-bar">
      <div className="nav-bar__main">
        <div className="nav-bar__main--top">
          <Button
            icon="list"
            className={cn('nav-btn', { active: activeModal === 'list' })}
            onClick={handleSetModal('list')}
          />
          {viewType === 'map' && current && (
            <Button
              icon="doc"
              className={cn('nav-btn', { active: activeModal === 'project' })}
              onClick={handleSetModal('project')}
            />
          )}
          <div className="nav-bar__main--notification">
            <Button
              icon="notification"
              className={cn('nav-btn', { active: showNotification })}
              onClick={handleShowNotification}
            />
            <span className="nav-bar__main--notification__value">{activeNotify.length}</span>
          </div>
        </div>
        <div className="nav-bar__main--bottom">
          <NavBarSwitch />
        </div>
      </div>
      <div className="nav-bar__footer">
        <Button icon="exit" onClick={logout} />
      </div>
    </div>
  );
};

export default NavBar;
