import 'leaflet.vectorgrid';
import "./leaflet.sideBySide";
import '../../TileLayerWithHeader'
import './style.scss';

import React, { useCallback, useEffect, useState } from 'react'
import { Popup, useMap } from 'react-leaflet';
import L, { LeafletMouseEvent } from 'leaflet';

import TileInfo from '../../ProjectDataView/components/ModelInfo/TileInfo';
import trimObjectKey from '../../../helpers/trimObjectKey';
import { useProject } from '../../../hooks/redux';
import { getShapeColor } from '../CompareSideBySide';
import axiosInstance from '../../../utils/axios';
import { bbox } from '../../../helpers/vectorTiles';
import { ProjectType } from '../../../types/IProject';
import { buildingOptions, vehicleOptions } from '../../ProjectDataView/components/MLObjectLayer/options';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(L.DomEvent as any).fakeStop = () => {
  return true;
}

export interface ILayer {
  url_tif?: string;
  url: string;
  bbox: string;
  name?: string;
}

export interface ICompareSliderProps {
  styleUrl: string;
  tile: any,
  setTile: any,
  pos: any,
  setPos: any,
  leftLayer: ILayer;
  rightLayer: ILayer;
}


const CompareSlider = ({
  styleUrl,
  tile,
  setTile,
  pos,
  setPos,
  leftLayer,
  rightLayer,
}: ICompareSliderProps) => {
  const map = useMap();
  const { activeShapes, currentVer, current, projectsList } = useProject();
  const [zoomedToCenter, setZoomedToCenter] = useState<string | null>(null);
  const [compareLayer, setCompareLayer] = useState(null);
  const [compareRaster, setCompareRaster] = useState(null);
  const [leftTileLayer, setLeftTileLayer] = useState<any>(null);
  const [rightTileLayer, setRightTileLayer] = useState<any>(null);
  const [rightRaster, setRightRaster] = useState<any>(null);
  const [leftRaster, setLeftRaster] = useState<any>(null);

  const currentProj = current && projectsList.find((item) => item._id === current.project);

  const onClose = () => {
    setPos(null);
    setTile(null);
  };

  const handleOnclickVectorTile = (e: LeafletMouseEvent) => {
    setTile(e.layer);
    setPos(e.latlng);
  }

  const getOptions = useCallback((type: ProjectType, layerName: string) => {
    switch(type) {
      case ProjectType.building:
        return buildingOptions({
          layerName,
          currentShape: null,
          activeShapes,
          selectedPolys: [],
          tile,
          canInteractive: true,
        });
      case ProjectType.vehicle:
        return vehicleOptions({
          layerName,
          currentShape: null,
          activeShapes,
          selectedPolys: null,
          tile,
          canInteractive: true,
        })
      default:
        return buildingOptions({
          layerName,
          currentShape: null,
          activeShapes,
          selectedPolys: null,
          tile,
          canInteractive: true,
        });
    }
  }, [activeShapes, tile]);

  useEffect(() => {
    if (!map) return;

    const handleAddLayer = async () => {
      try {
        map.eachLayer((e: any) => {
          if (e.options.isVectorGrid) {
            map.removeLayer(e);
          }
        })
        if (compareLayer) {
          (compareLayer as any).remove();
          setCompareLayer(null);
        }
        if (compareRaster) {
          (compareRaster as any).remove();
          setCompareRaster(null);
        }

        const leftR = (L as any).tileLayerWithHeader(leftLayer?.url_tif, {
          tms: true,
          maxZoom: 24,
          noRedraw: false
        }).addTo(map);
        setLeftRaster(leftR);

        const rightR = (L as any).tileLayerWithHeader(rightLayer?.url_tif, {
          tms: true,
          maxZoom: 24,
          noRedraw: false
        }).addTo(map);
        setRightRaster(rightR);
        
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const left = (L as any).vectorGrid.protobuf(leftLayer.url, getOptions(currentProj.type, leftLayer.name || ''));
        left.addTo(map);
        left.on('click', handleOnclickVectorTile);
        setLeftTileLayer(left);

        const right = (L as any).vectorGrid.protobuf(rightLayer.url, getOptions(currentProj.type, leftLayer.name || ''));
        right.addTo(map);
        right.on('click', handleOnclickVectorTile);
        setRightTileLayer(right);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const compare = (L.control as any).sideBySide([left], [right]).addTo(map);
        const compare1 = (L.control as any).sideBySide([leftR], [rightR]).addTo(map);
        setCompareLayer(compare);
        setCompareRaster(compare1);

        // This hack to sync 2 control
        // Need this bcs leaflet side-by-side not work properly with leaflet.vectorgrid's layer. It's show on two sides
        compare1._range.classList.add('hidden');

        compare1._divider.hidden = true;
        compare1._range.hidden = true;
        compare._range.addEventListener('input', (e: any) => {
          compare1._range.value = e.target.value;
          compare1._updateClip();
        });

        if (currentVer?.geojson_table && zoomedToCenter !== currentVer._id) {
          const tableBBox = await axiosInstance.get(bbox(currentVer?.geojson_table));
          const bboxCoordinates = tableBBox.data[0].bbox.match(/\d+\.\d+/g).map(parseFloat);
          const bounds = [
            [bboxCoordinates[1], bboxCoordinates[0]],
            [bboxCoordinates[3], bboxCoordinates[2]],
          ];

          const llBounds = L.latLngBounds(bounds as any);

          setZoomedToCenter(currentVer._id);
          map.setView(llBounds.getCenter());
          map.setZoom(16)
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }

    };

    const vectorPane = map.getPanes()[
      `vector-compare-pane`
    ];
    if (vectorPane) {
      vectorPane.style.zIndex = '400';
    }

    handleAddLayer();

    return () => {
      map.eachLayer((e: any) => {
        if (e.isVectorGrid) {
          map.removeLayer(e);
        }
      })

      if (rightRaster) {
        map.removeLayer(rightRaster);
      }
      if (leftRaster) {
        map.removeLayer(leftRaster);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, activeShapes, currentProj]);

  const handleMinZoom = useCallback(() => {
    const zoom = map.getZoom();
    if (leftTileLayer) {
      if (zoom < 16) {
        map.removeLayer(leftTileLayer)
      } else {
        leftTileLayer.addTo(map);
      }
    }

    if (rightTileLayer) {
      if (zoom < 16) {
        map.removeLayer(rightTileLayer)
      } else {
        rightTileLayer.addTo(map);
      }
    }
  }, [leftTileLayer, map, rightTileLayer])

  useEffect(() => {
    handleMinZoom();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Limit zoom to prevent crash bcs of large vector tiles
  useEffect(() => {
    map.on('zoom', handleMinZoom)

    return () => {
      map.off('zoom', handleMinZoom)
    }
  }, [handleMinZoom, map])

  return (
    <div>
      {pos && (
        <Popup position={pos} closeOnClick closeOnEscapeKey>
          {tile && tile.properties && (
            <TileInfo
              properties={trimObjectKey(tile.properties)}
            />
          )}
        </Popup>
      )}
    </div>
  );
}

export default CompareSlider