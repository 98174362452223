import React, { memo, useMemo, useState } from 'react'
import moment from 'moment';

import { IVersion } from '../../../../types/IProject';
import Icon from '../../../ui/Icon';
import Value from './Value';
import './style.scss';
import List, { IOption } from './List';

export interface ISelectVersionProps {
  title: string;
  versions: IVersion[];
  value?: IOption;
  setValue: React.Dispatch<React.SetStateAction<undefined | IOption>>;
}

const Select = ({ title, versions, value, setValue }: ISelectVersionProps) => {
  const [select, setSelect] = useState(false);

  const handleOpenSelect = () => {
    setSelect(prev => !prev);
  }

  //TODO: correct label should contain V-version index
  const options = useMemo(() => {
    return versions.map((el, idx) => {
      return {
        value: el._id,
        label: moment(el.createdAt).format('DD/MM/YYYY'),
        versionIdx: (idx + 1).toFixed(1)
      }
    })
  }, [versions])

  return (
    <div className='select-version-wrapper'>
      <span className='select-version__title'>{title}</span>
      <div className='select-version__value-wrapper' onClick={handleOpenSelect}>
        <div className="select-version__value-input">
          {
            value ? (
              <>
                <Value icon='version' value={value.versionIdx} />
                <Value icon='calendar' value={value.label} />
              </>
            ) : (
              <>
                <Value icon='version' value='--' />
                <Value icon='calendar' value='--/--/--' />
              </>
            )
          }

        </div>

        <div className='select-version__state'>
          <Icon name={`${select ? 'arrow-up' : 'arrow-down'}`} color='black' size={3} />
        </div>

        { select && <List options={options} setValue={setValue} />}
      </div>
    </div>
  )
}

export default memo(Select);