import React, { FC } from 'react';
import cn from 'classnames';
import './style.scss';
import { IconProps } from './types';

const Icon: FC<any> = ({ name, color = 'white', size = 1, className }) => {
  return <i className={cn(`icon-${name}`, `color-${color}`, `size-${size}`, className)} />;
};

export default Icon;
