import config from "../../config"

export const url = (table?: string) =>
  `${config.tileServer}/v1/mvt/${table}/{z}/{x}/{y}?geom_column=wkb_geometry&columns=*`

export const bbox = (table?: string, geom_column = 'wkb_geometry') =>
  `${config.tileServer}/v1/bbox/${table}?geom_column=${geom_column}&srid=4326`

export const geojson = (table: string, ogc_fid: string) => `${config.tileServer}/v1/geojson/${table}?geom_column=wkb_geometry&filter=ogc_fid%3D${ogc_fid}&precision=9&time=${new Date().getTime()}`

export const getUpdateGeoQuery = (wktGeo: any) => `ST_AsBinary(ST_GeomFromText('${wktGeo}'))`

export const update = () => {
  return `${config.tileServer}/v1/update_table`
}