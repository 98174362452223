import React, { useEffect } from 'react';
import './style.scss';
import ReactSlider from 'react-slider';
import styles from '../PlanetEarth.module.scss';

// @ts-ignore
const Slider = ({ setSpeed, getSpeedIncrease }) => {
  return (
    <ReactSlider
      className="time-slider"
      min={-200}
      max={200}
      step={1}
      onChange={(e) => {
        setSpeed(e);
      }}
      // value={selectedSatellite?.speedIncrease || earth.speedIncrease}
      value={getSpeedIncrease}
    />
  );
};

export default Slider;
