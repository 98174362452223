import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { setDrawingMode } from '../../store/modules/Common';
import { deleteProjectShape } from '../../store/modules/Project';
import { useCommon, useAppDispatch } from '../../hooks/redux';
import 'leaflet/dist/leaflet.css';

import './style.scss';
import MainMap from './components/MainMap';
import MapStyle from './components/MapStyle';


const MapView = () => {
  const { viewType, activeModal } = useCommon();
  const dispatch = useAppDispatch();
  const [style, setStyle] = useState('hybrid');

  const isVisible = viewType === 'map';

  useEffect(() => {
    dispatch(setDrawingMode(null));
    dispatch(deleteProjectShape());
  }, [activeModal, dispatch]);

  return (
    <div className={cn('map', { hidden: !isVisible })}>
      {
        isVisible && <MapStyle
          style={style}
          setStyle={setStyle}
        />
      }

      {/* Handle show map */}
      <MainMap style={style} />
    </div>
  );
};

export default MapView;
