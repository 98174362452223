import { useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import "./libs/leaflet-ruler.css";
import leafletRuler from "./libs/leafletRuler";
import getDistance from "../../../../../helpers/getDistance";
import getUnitText from "../../../../../helpers/getUnitText";

export interface ILeafletRulerProps {
  unit: string;
  drawing: boolean;
  setDrawing: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function LeafletRuler({ unit, drawing, setDrawing }: ILeafletRulerProps) {
  const map = useMap();
  const [layers, setLayers] = useState([]);

  useEffect(() => {
    if (!map) return;

    const switchMetrics = () => {
      layers.forEach((item: any) => {
        if (item.userData.type === 'ruler') {
          item.unbindTooltip();

          const text = `
            <b> Heading: </b>&nbsp; ${item.userData.bearing.toFixed(
            2,
          )} &deg; <br>
            <b> Distance: </b>&nbsp; ${getDistance(item.userData.distance, unit).toFixed(2)} &nbsp;${getUnitText(unit)}
        `;
          item
            .bindTooltip(text, { permanent: true, className: 'result-tooltip' })
            .openTooltip();
        }
      });
    }

    switchMetrics();
    leafletRuler(L, { unit, drawing, setDrawing, layers, setLayers });
    (L.control as any).ruler().addTo(map);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawing, map, unit]);

  return null;
}
