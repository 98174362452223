import React, {useEffect, memo} from 'react'
import L from 'leaflet';
import './leaflet.tilelayerWithHeader';
import { useMap } from 'react-leaflet';

export interface ITileLayerWithHeaderProps {
  url: string;
}

const TileLayerWithHeader = ({ url }: ITileLayerWithHeaderProps) => {
  const map = useMap();

  useEffect(() => {
    (L as any).tileLayerWithHeader(url, {
      tms: true,
      maxZoom: 24,
      noRedraw: false
    }).addTo(map);

  }, [map, url])
  return (
    <div />
  )
}

export default memo(TileLayerWithHeader);