import * as L from 'leaflet';

export const drawPluginOptions = {
  position: 'bottomright',
  draw: {
    polyline: false,
    polygon: {
      allowIntersection: true, // Restricts shapes to simple polygons
      drawError: {
        color: 'var(--blueColor)', // Color the shape will turn when intersects
        message: '<strong>Oh snap!<strong> you can\'t draw that!' // Message that will show when intersect
      },
      shapeOptions: {
        color: 'var(--blueColor)'
      }
    },
    rectangle: {
      allowIntersection: true, // Restricts shapes to simple polygons
      drawError: {
        color: 'var(--blueColor)', // Color the shape will turn when intersects
        message: '<strong>Oh snap!<strong> you can\'t draw that!' // Message that will show when intersect
      },
      shapeOptions: {
        color: 'var(--blueColor)'
      }
    },
    marker: {
      icon: L.divIcon({
        className: 'marker-icon',
        iconSize: [40, 40],
        iconAnchor: [20, 20],
      }),
    },
    circle: true, // Turns off this drawing tool
  },
  edit: false,
  // edit: {
  //   featureGroup: editableLayers, //REQUIRED!!
  //   remove: false
  // }
};

export const rulersPluginOptions = {
  position: 'bottomleft',
  draw: {
    polygon: {
      allowIntersection: false,
      drawError: {
        color: '#e1e100',
        message: "<strong>Oh snap!<strong> you can't draw that!",
      },
      shapeOptions: {
        color: '#00ADBB',
      },
      color: 'brow',
    },
    // disable toolbar item by setting it to false
    polyline: true,
    circle: false,
    rectangle: false,
    marker: false,
    circlemarker: false,
  },
  edit: false,
  // edit: {
  //   featureGroup: editableLayers, //REQUIRED!!
  //   remove: false
  // }
};
