import React, { forwardRef } from 'react';
import './style.scss';
import { BeatLoader } from 'react-spinners';
import cn from 'classnames';
import { ButtonProps } from './types';
import Icon from '../Icon';

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { spinner = false, icon, iconRight = true, children, type = 'button', color = 'white', className, ...props },
    ref,
  ) => {
    return (
      // eslint-disable-next-line react/button-has-type
      <button ref={ref} className={cn('app-btn', className)} type={type} {...props}>
        {
          spinner ? <BeatLoader color='#00CEFF' size={5} /> : (
            <>
              {icon && !iconRight ? <Icon name={icon} color={color} /> : null}
                <span>{children}</span>
              {icon && iconRight ? <Icon name={icon} color={color} /> : null}
            </>
          )
        }

      </button>
    );
  },
);

export default Button;
