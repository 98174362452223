import { useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import leafletArea from "./libs/leafletArea";
import getArea from "../../../../../helpers/getArea";
import getDistance from "../../../../../helpers/getDistance";
import getUnitText from "../../../../../helpers/getUnitText";

export interface ILeafletAreaProps {
  unit: string;
  area: boolean;
}

export default function LeafletArea({ unit, area }: ILeafletAreaProps) {
  const map = useMap();
  const [layers, setLayers] = useState([]);

  useEffect(() => {
    if (!map) return;

    if(!area) {
      leafletArea(L, { unit, layers, setLayers });

      (L.control as any).area().addTo(map);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, unit, area]);

  useEffect(() => {
    const switchMetrics = () => {
      layers.forEach((item: any) => {
        if (item.userData.type === 'area') {
          item.unbindTooltip();
          item
            .bindTooltip(
              `
          <div><b>Area : </b>${getArea(item.userData.area, unit).toFixed(2)
              } ${getUnitText(unit)}<sup>2</sup></div>
          <div><b>Perimeter : </b>${getDistance(item.userData.distance, unit).toFixed(2)} ${getUnitText(unit)}</div>
          `,
              { permanent: true, className: 'area-tooltip' },
            )
            .openTooltip();
        }
      });
    }

    switchMetrics();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unit])

  return null;
}
