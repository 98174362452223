import React from 'react';
import '../../NavBar/style.scss';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { useAppDispatch, useCommon, useNotification, useProject } from '../../../hooks/redux';
import { ActiveModal } from '../../../types/ICommon';
import { setShowNotification, updateActiveModal } from '../../../store/modules/Common';
import UserService from '../../../services/user.service';
import Button from '../../ui/Button';

interface IProps {
  confirm?: boolean;
  setConfirm?: React.Dispatch<boolean>;
}

const NavBar = ({ confirm, setConfirm }: IProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showNotification, activeModal, viewType } = useCommon();
  const { current } = useProject();
  const { notifications } = useNotification();

  const activeNotify =
    viewType === 'planet'
      ? notifications.filter((notification: any) => notification.viewType === 'planet')
      : notifications.filter((notification: any) => notification.viewType === 'map');

  const handleSetModal = (name: ActiveModal) => () => {
    if (name === activeModal) {
      dispatch(updateActiveModal(null));
    } else {
      dispatch(updateActiveModal(name));
    }
  };

  const handleShowNotification = () => dispatch(setShowNotification(!showNotification));

  const logout = () => UserService.logout();

  const handleConfirmModal = () => setConfirm && setConfirm(true);

  return (
    <div className="nav-bar">
      <div className="nav-bar__main">
        <div className="nav-bar__main--top">
          <Button
            icon="list"
            className={cn('nav-btn', { active: activeModal === 'list' })}
            onClick={handleConfirmModal}
          />
          {viewType === 'map' && current && (
            <Button
              icon="doc"
              className={cn('nav-btn', { active: activeModal === 'project' })}
              onClick={handleSetModal('project')}
            />
          )}
          {viewType === 'planet' && (
            <Button
              icon="add-satellite"
              className={cn('nav-btn', { active: activeModal === 'create-satellite' })}
              onClick={handleSetModal('create-satellite')}
            />
          )}
          <div className="nav-bar__main--notification">
            <Button
              icon="notification"
              className={cn('nav-btn', { active: showNotification })}
              onClick={handleShowNotification}
            />
            <span className="nav-bar__main--notification__value">{activeNotify.length}</span>
          </div>
        </div>
      </div>
      <div className="nav-bar__footer">
        <Button icon="exit" onClick={logout} />
      </div>
    </div>
  );
};

export default NavBar;
