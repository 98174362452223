export interface IOption {
  value: string;
  label: string;
  icon?: string;
}

export interface IOptionsMap {
  [key: string]: IOption;
}

export const CONSTANTS = {
  LOCAL_STORAGE_TOKEN_NAME: btoa('nEra-tok'),
};

export const TOOLS_MAP = {
  Polygon: 1,
  PolygonShape: 11,
  Circle: 2,
  Move: 3,
  Delete: 4,
  DeleteShape: 41,
  PolygonCrop: 5,
};

export const DEFAULT_DATE_PATTERN = 'DD.MM.yyyy';
export const DEFAULT_DATE_PATTERN_WITH_TIME = 'DD.MM.YYYY, h:mm a';

export const ObjectTypeOptions = [
  {
    value: 'receptors',
    label: 'Receptors',
  },
  {
    value: 'roads',
    label: 'Roads',
  },
  {
    value: 'Aircraft',
    label: 'Aircraft',
  },
  {
    value: 'Ship',
    label: 'Ship',
  },
  {
    value: 'Ground Vehicle',
    label: 'Ground Vehicle',
  },
  {
    value: 'Extent',
    label: 'Extent',
  },
  {
    value: 'Obscuration',
    label: 'Obscuration',
  },
];

export const RoadTypeOptions = [
  {
    value: 'asphalt',
    label: 'Asphalt',
  },
  {
    value: 'midLine_asphalt',
    label: 'Asphalt Middle Line',
  },
  {
    value: 'concrete',
    label: 'Concrete',
  },
  {
    value: 'midLine_concrete',
    label: 'Concrete Middle Line',
  },
  {
    value: 'chipSeal',
    label: 'Chip-seal',
  },
  {
    value: 'midLine_chipSeal',
    label: 'Chip-seal Middle Line',
  },
  {
    value: 'gravel',
    label: 'Gravel',
  },
  {
    value: 'midLine_gravel',
    label: 'Gravel Middle Line',
  },
  {
    value: 'dirt',
    label: 'Dirt',
  },
  {
    value: 'midLine_dirt',
    label: 'Dirt Middle Line',
  },
  {
    value: 'road',
    label: 'Road',
  },
  {
    value: 'midLine_road',
    label: 'Road Middle Line',
  },
];

export const AircraftTypeOptions = [
  {
    value: 'Fighter',
    label: 'Fighter',
  },
  {
    value: 'Bomber',
    label: 'Bomber',
  },
  {
    value: 'Medium-Lift Transport',
    label: 'Medium-Lift Transport',
  },
  {
    value: 'Heavy-Lift Transport',
    label: 'Heavy-Lift Transport',
  },
  {
    value: 'Civilian',
    label: 'Civilian',
  },
  {
    value: 'Rotary Wing',
    label: 'Rotary Wing',
  },
  {
    value: '<null>',
    label: '<null>',
  },
];

export const GroundTypeOptions = [
  {
    value: 'Tank',
    label: 'Tank',
  },
  {
    value: 'Truck',
    label: 'Truck',
  },
  {
    value: 'Military Truck',
    label: 'Military Truck',
  },
  {
    value: 'Trailer / Artillery',
    label: 'Trailer / Artillery',
  },
  {
    label: 'Heavy-Lift Transport (>42m wingspan)',
    value: 'Heavy-Lift Transport (>42m wingspan)',
  },
  {
    value: 'Civilian',
    label: 'Civilian',
  },
  {
    value: '<null>',
    label: '<null>',
  },
  {
    value: 'Flat Railcar',
    label: 'Flat Railcar',
  },
  {
    value: 'General Railcar',
    label: 'General Railcar',
  },
  {
    value: 'Other',
    label: 'Other',
  },
  {
    value: 'Tower Artillery Trailer',
    label: 'Tower Artillery Trailer'
  },
  {
    value: 'Tank Self Propelled Artillery',
    label: 'Tank Self Propelled Artillery',
  }
];

export const ExtentTypeOptions = [
  {
    value: 'Area of Interest',
    label: 'Area of Interest',
  },
];

export const ObscurationsTypeOptions = [
  {
    value: 'Cloud',
    label: 'Cloud',
  },
  {
    value: 'Smoke/Haze',
    label: 'Smoke/Haze',
  },
];

export const ShipTypeOptions = [
  {
    value: 'Destroyer',
    label: 'Destroyer',
  },
  {
    value: 'Frigates Corvettes',
    label: 'Frigates Corvettes',
  },
  {
    value: 'Aircraft Carrier',
    label: 'Aircraft Carrier',
  },
  {
    value: 'Amphibious Warfare Craft',
    label: 'Amphibious Warfare Craft',
  },
  {
    value: 'Patrol Ships',
    label: 'Patrol Ships',
  },
  {
    value: 'Bulk Cargo Ships',
    label: 'Bulk Cargo Ships',
  },
  {
    value: 'Container Ships',
    label: 'Container Ships',
  },
  {
    value: 'POL Tanker',
    label: 'POL Tanker',
  },
  {
    value: 'Patrol Ships',
    label: 'Patrol Ships',
  },
  {
    value: 'Other',
    label: 'Other',
  },
  {
    value: '<null>',
    label: '<null>',
  }
];

export const RoadTypeOptionsMap = {
  Asphalt: RoadTypeOptions[0],
  AsphaltMiddleLine: RoadTypeOptions[1],
  Concrete: RoadTypeOptions[2],
  ConcreteMiddleLine: RoadTypeOptions[3],
  ChipSeal: RoadTypeOptions[4],
  ChipSealMiddleLine: RoadTypeOptions[5],
  Gravel: RoadTypeOptions[6],
  GravelMiddleLine: RoadTypeOptions[7],
  Dirt: RoadTypeOptions[8],
  DirtMiddleLine: RoadTypeOptions[9],
  Road: RoadTypeOptions[10],
  RoadMiddleLine: RoadTypeOptions[11],
};

export const ReceptorsTypeOptions = [
  {
    value: 'residences',
    label: 'Residences',
  },
  {
    value: 'barns',
    label: 'Barns',
  },
  {
    value: 'tree',
    label: 'Tree',
  },
  {
    value: 'ponds',
    label: 'Ponds',
  },
  {
    value: 'rivers',
    label: 'Rivers',
  },
  {
    value: 'undamaged',
    label: 'Undamaged',
  },
  {
    value: 'damaged',
    label: 'Damaged',
  },
  {
    value: 'destroyed',
    label: 'Destroyed',
  },
  {
    value: 'others',
    label: 'Others',
  },
  {
    value: 'No After Imagery to Process',
    label: 'No After Imagery to Process',
  },
  {
    value: 'No After Footprint Found',
    label: 'No After Footprint Found',
  },
];

export const RoadQualities = {
  Poor: 1,
  Medium: 2,
  Good: 3,
};

export const GroundTypeOptionsMap = {
  Tank: GroundTypeOptions[0],
  Truck: GroundTypeOptions[1],
  Truck_Military: GroundTypeOptions[2],
  TrailerOrArtillery: GroundTypeOptions[3],
  HeavyLiftTransport: GroundTypeOptions[4],
  Civilian: GroundTypeOptions[5],
  Null: GroundTypeOptions[6],
  FlatRailcar: GroundTypeOptions[7],
  GeneralRailcar: GroundTypeOptions[8],
  TowerArtilleryTrailer: GroundTypeOptions[10],
  TankSelfPropelledArtillery: GroundTypeOptions[11],
};

export const ShipTypeOptionsMap = {
  Destroyer: ShipTypeOptions[0],
  FrigatesCorvettes: ShipTypeOptions[1],
  AircraftCarrier: ShipTypeOptions[2],
  AmphibiousWarfareCraft: ShipTypeOptions[3],
  PatrolShips: ShipTypeOptions[4],
  BulkCargoShips: ShipTypeOptions[5],
  ContainerShips: ShipTypeOptions[6],
  POLTanker: ShipTypeOptions[7],
  PatrolShipsMedium: ShipTypeOptions[8],
  OtherCivilian: ShipTypeOptions[9],
  Null: ShipTypeOptions[10],
};

export const AircraftTypeOptionsMap = {
  Fighter: AircraftTypeOptions[0],
  Bomber: AircraftTypeOptions[1],
  MediumLift: AircraftTypeOptions[2],
  HeavyLift: AircraftTypeOptions[3],
  Civilian: AircraftTypeOptions[4],
  RotaryWing: AircraftTypeOptions[5],
  Null: AircraftTypeOptions[6],
};

export const ReceptorsTypeOptionsMap = {
  Residences: ReceptorsTypeOptions[0],
  Barns: ReceptorsTypeOptions[1],
  Trees: ReceptorsTypeOptions[2],
  Ponds: ReceptorsTypeOptions[3],
  Rivers: ReceptorsTypeOptions[4],
  Undamaged: ReceptorsTypeOptions[5],
  Damaged: ReceptorsTypeOptions[6],
  Destroyed: ReceptorsTypeOptions[7],
  Others: ReceptorsTypeOptions[8],
  NoImageToProcess: ReceptorsTypeOptions[9],
};

export const ObjectTypeOptionsMap = {
  Receptors: ObjectTypeOptions[0],
  Roads: ObjectTypeOptions[1],
  Aircraft: ObjectTypeOptions[2],
  Ship: ObjectTypeOptions[3],
  Ground: ObjectTypeOptions[4],
  Extent: ObjectTypeOptions[5],
  Obscuration: ObjectTypeOptions[6],
};

export const SearchContextsMap = {
  PROJECT: 'projectName',
  USERNAME: 'username',
  LOCATION: 'location',
};

export const DEFAULT_SEARCH_CONTEXT = SearchContextsMap.PROJECT;

export const SearchContexts: IOption[] = [
  {
    label: 'Search by project name',
    value: SearchContextsMap.PROJECT,
  },
  {
    label: 'Search by user name',
    value: SearchContextsMap.USERNAME,
  },
  {
    label: 'Search by location',
    value: SearchContextsMap.LOCATION,
  },
];

export const FiltersContextsList: IOption[] = [
  {
    label: 'Project',
    value: SearchContextsMap.PROJECT,
  },
  {
    label: 'Users',
    value: SearchContextsMap.USERNAME,
  },
  {
    label: 'Location',
    value: SearchContextsMap.LOCATION,
  },
];

export const DefaultFilterContext = FiltersContextsList[0];

export const Units = {
  MILES: 'mi',
  KILOMETERS: 'km',
  METERS: 'm',
  FEET: 'ft',
};

export const Measurements = {
  POLYLINE: 'polyline',
  LINE: 'line',
};

export const NotificationStatuses = {
  SUCCESS: 'success',
  WARN: 'warn',
  FAILED: 'failed',
};

export const ObjectCategoriesMap = {
  BUILDINGS: 'buildings',
  FIXED_WING: 'Fixed Wing',
  ROTARY_WING: 'Rotary Wing',
  COMBATANT_SHIP: 'Combatant Ship',
  NON_MILITARY_SHIP: 'Non Military Ship',
  COMBATANT_CRAFT: 'Combatant Craft',
  MILITARY_VEHICLES: 'Military Vehicles',
  CIVILIAN_VEHICLES: 'Civilian Vehicles',
  MISCELLANEOUS_OTHER_VEHICLES: 'Miscellaneous and Other Vehicles',
  RAIL_CAR: 'Railcar',
  AREA_OFInterest: 'Area of Interest',
  CLOUD: 'Cloud',
  SMOKE_HAZE: 'Smoke/Haze',
};

export const ObjectCategoriesNamesMap = {
  [ObjectCategoriesMap.BUILDINGS]: 'Buildings',
  [ObjectCategoriesMap.FIXED_WING]: 'Fixed Wing',
  [ObjectCategoriesMap.ROTARY_WING]: 'Rotary Wing',
  [ObjectCategoriesMap.COMBATANT_SHIP]: ' Combatant Ship',
  [ObjectCategoriesMap.NON_MILITARY_SHIP]: 'Non Military Ship',
  [ObjectCategoriesMap.COMBATANT_CRAFT]: 'Combatant Craft',
  [ObjectCategoriesMap.MILITARY_VEHICLES]: 'Military Vehicles',
  [ObjectCategoriesMap.CIVILIAN_VEHICLES]: 'Civilian Vehicles',
  [ObjectCategoriesMap.MISCELLANEOUS_OTHER_VEHICLES]: 'Miscellaneous and Other Vehicles',
  [ObjectCategoriesMap.RAIL_CAR]: 'Railcar',
  [ObjectCategoriesMap.SMOKE_HAZE]: 'Smoke/Haze',
};

export const ProcessingStatusesMap = {
  PENDING: 'pending',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
  PAUSED: 'paused',
  STOPPED: 'stopped',
  FAILED: 'failed',
};

export const DownloadOptionsList = ['kml', 'kmz', 'shp'];

export const QualitiesMap = {
  OPTIMIZED: 'optimized',
  HIGH: 'high',
};