import React, { useEffect, useState } from 'react'
import { Pane, useMap } from 'react-leaflet';
import * as turf from '@turf/turf';
import { useParams } from 'react-router-dom';
import L from 'leaflet';
import { useAppDispatch, useCommon, useProject } from '../../../../hooks/redux';
import { changeProjectShape } from '../../../../store/modules/Project';
import { setDrawingMode } from '../../../../store/modules/Common';
import { IProject, IVersion } from '../../../../types/IProject';

const DrawingManagerOptions = {
  markerOptions: {
    editable: false,
  },
  circleOptions: {
    editable: true,
    fillColor: '#5b5858',
    fillOpacity: 0.2,
    strokeColor: '#000',
    strokeWeight: 2,
    clickable: true,
  },
  polygonOptions: {
    editable: true,
    fillColor: '#5b5858',
    fillOpacity: 0.2,
    strokeColor: '#000',
    strokeWeight: 2,
    clickable: true,
  },
  polylineOptions: {
    editable: true,
    fillColor: '#5b5858',
    fillOpacity: 0.2,
    strokeColor: '#000',
    strokeWeight: 2,
    clickable: true,
  },
  drawingControl: false,
};

let drawingManager: any;

export interface IMapHandlerProps {
  currentVer: IVersion | null;
  setMainMap: React.Dispatch<React.SetStateAction<L.Map | null>>;
  currentProj?: IProject;
  defaultProps: any;
  loading: boolean;
  setLoading: any;
  showRaster: boolean;
}

const MapHandler = ({ showRaster, loading, setLoading, currentVer, setMainMap, currentProj, defaultProps }: IMapHandlerProps) => {
  const map = useMap();
  const { versionId } = useParams();
  const { projectsList, current } = useProject();
  const { drawingMode } = useCommon();
  const dispatch = useAppDispatch();
  const [rasterLayer, setRasterLayer] = useState<any>(null);

  useEffect(() => {
    if (!map || !versionId) return;
    setMainMap(map);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, projectsList, map, versionId]);

  useEffect(() => {
    if (!map) return;
    if (!currentVer || !currentVer?.url_tif) return;

    const removeRaster = () => {
      map.eachLayer(l => {
        if((l.options as any).isRaster) {
          l.removeFrom(map);
        }
      })
      setRasterLayer(null);
    }

    if(showRaster) {
      const raster = (L as any).tileLayerWithHeader(currentVer.url_tif, {
        tms: true,
        maxZoom: 24,
        noRedraw: false,
        isRaster: true,
      }).addTo(map);
      setRasterLayer(raster);
    } else {
      removeRaster();
    }

    return () => removeRaster();
  }, [map, currentVer, showRaster])

  const genID = (prefix = 'i'): string => `${prefix}${(+new Date()).toString(16)}`;

  const getShapeCenter = (overlay: any, maps: any) => {
    const features = turf.points(
      overlay
        .getPath()
        .getArray()
        .map((point: any) => [point.lng(), point.lat()]),
    );

    const { geometry } = turf.centroid(features);
    const center = {
      lat: geometry?.coordinates[1] || 0,
      lng: geometry?.coordinates[0] || 1,
    };

    const Geocoder = new maps.Geocoder();

    return new Promise((resolve) => {
      Geocoder.geocode(
        {
          location: center,
        },
        (results: any) => {
          const addresses = results?.[(results?.length || 2) - 2];
          const address = addresses?.formatted_address?.split(',')[0];
          resolve({ center, address });
        },
      );
    });
  };

  const addOverlayListeners = (maps: any): void => {
    maps.event.addListener(drawingManager, 'overlaycomplete', ({ type, overlay }: any) => {
      overlay.id = genID();
      overlay.type = type;
      const overlayPoints = overlay
        .getPath()
        .getArray()
        .map((point: any) => ({
          lat: point.lat(),
          lng: point.lng(),
        }));

      if (overlayPoints.length < 3) {
        overlay.setMap(null);
        return;
      }
      overlay.getDimensions = () => {
        const area = maps.geometry.spherical.computeArea(overlay.getPath());
        const acres = area * 0.00024711;

        let perimeter = 0;
        const points = overlay
          .getPath()
          .getArray()
          .map((point: any) => ({
            lat: point.lat(),
            lng: point.lng(),
          }));

        for (let i = 0; i < points.length; i++) {
          const point1 = points[i];
          const nextPoint = points[i + 1];
          const point2 = nextPoint || points[0];

          const distance = maps.geometry.spherical.computeDistanceBetween(point1, point2);
          perimeter += distance;
        }

        return { area, acres, perimeter };
      };

      overlay.getCenter = () => {
        return getShapeCenter(overlay, maps);
      };

      overlay.getPath().addListener('insert_at', () => {
        dispatch(changeProjectShape(overlay));
      });
      overlay.getPath().addListener('set_at', () => {
        dispatch(changeProjectShape(overlay));
      });
      dispatch(setDrawingMode(null));
      dispatch(changeProjectShape(overlay));
    });
  };

  useEffect(() => {
    drawingManager?.setOptions({
      drawingMode,
    });
  }, [drawingMode]);

  return (
    <div />
  )
}

export default MapHandler